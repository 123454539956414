import { Role as RestRole } from "../rest-model/Role";
import { Role } from "../domain/Role";

export class RoleAdapter {
    public adaptToDomain(restRole: RestRole): Role {
        const {
            id,
            name,
            created,
            description,
            productId,
            roleType,
            targetIds,
            attributes,
            modified,
            isActive,
            properties
        } = restRole;

        const systemGeneratedRole = properties?.find((p) => p.name === "SystemGenerated")?.value;

        return new Role({
            id,
            name,
            description,
            productId,
            roleType,
            targetIds,
            isActive,
            attributes: (attributes || []).map((attribute) => {
                return {
                    id: attribute.id,
                    key: attribute.key,
                    values: attribute.values,
                    path: attribute.path,
                    scopes: attribute.scopes || [],
                    description: attribute.description
                };
            }),
            modified: new Date(modified),
            created: new Date(created),
            isSystemGeneratedRole: systemGeneratedRole ? /true/i.test(systemGeneratedRole) : false
        });
    }
}
