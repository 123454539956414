/* eslint-disable react/style-prop-object */
import { useState } from "react";
import { Button } from "@optimizely/axiom";

import styles from "./Roles.module.scss";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useRoles } from "../../../hooks/useRoles/useRoles";
import { IRoleFilters, RoleFilters } from "../../components/RoleFilters/RoleFilters";
import { RolesTable } from "../../components/RolesTable/RolesTable";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { IRoleFormValues, RoleForm } from "../../components/RoleForm/RoleForm";
import { Role } from "../../../domain/Role";
import { useUserContext } from "../../../providers/UserProvider";

import { ROLE_MAPPINGS, ALL_PAGES_SIZE, ATTRIBUTE_ROLES } from "../../../constants";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { datadogRum } from "@datadog/browser-rum";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { getEntitledProductRoles } from "../../../lib/access-helpers";
import { emitToast } from "../../../lib/toaster-utils";

export const Roles = () => {
    const { organizationId } = useUserContext();
    const { products = [], instances = [], isLoading: productsLoading } = useProducts({ organizationId });
    const [showRoleForm, setShowRoleForm] = useState(false);
    const [roleToBeDeleted, setRoleToBeDeleted] = useState<Role | null>(null);
    const [formRole, setFormRole] = useState<Role | undefined>(undefined);
    const [roleDeletionError, setRoleDeletionError] = useState("");
    const [roleDeletionInProgress, setRoleDeletionInProgress] = useState(false);
    const [filters, setFilters] = useState<IRoleFilters>({ product: null, instance: null });
    const { enabled: filterGroupsPerEntitlements } = useFeatureFlag(Flags.FILTER_GROUPS_AND_ROLES_PER_ENTITLEMENTS);

    const { roles: allRoles } = useRoles({
        organizationId,
        productId: filters?.product?.id,
        instanceId: filters?.instance?.id,
        size: ALL_PAGES_SIZE
    });
    const {
        createRole,
        currentPage,
        deleteRole,
        error,
        isLoading,
        pageSize,
        revalidate,
        roles = [],
        setCurrentPage,
        totalCount,
        updateRole
    } = useRoles({
        organizationId,
        productId: filters?.product?.id,
        instanceId: filters?.instance?.id
    });

    let adjustedAllRoles = allRoles;
    let adjustedRoles = roles;
    if (filterGroupsPerEntitlements) {
        adjustedAllRoles = getEntitledProductRoles({ products, instances, roles: allRoles || [] });
        adjustedRoles = getEntitledProductRoles({ products, instances, roles });
    }
    const handleRoleDeletion = () => {
        setRoleDeletionInProgress(true);
        setRoleDeletionError("");
        deleteRole({ roleId: roleToBeDeleted!.id })
            .then(() => {
                emitToast({ message: "Role successfully deleted." });
                setRoleToBeDeleted(null);
            })
            .catch((deletionError) => {
                datadogRum.addError(deletionError);
                const [apiError] = deletionError || [deletionError];
                const { message } = apiError || {};
                setRoleDeletionError(message || apiError);
            })
            .finally(() => setRoleDeletionInProgress(false));
    };

    const goToPage = ({ page = 1 }: { page: number }) => {
        setCurrentPage(page);
    };

    if (isLoading) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching roles.</div>;

    const updateFilters = (roleFilters: IRoleFilters) => {
        setFilters(roleFilters);
        setCurrentPage(1);
    };

    const resetRoleFilters = () => {
        setFilters({ instance: null, product: null });
    };

    const openRoleSidebar = () => {
        setShowRoleForm(true);
    };

    const closeRoleForm = () => {
        setShowRoleForm(false);
        setFormRole(undefined);
    };

    const handleSubmit = (roleDetails: IRoleFormValues) => {
        if (formRole) {
            return updateRole({ organizationId: organizationId!, id: formRole.id, ...roleDetails });
        }
        return createRole({ organizationId: organizationId!, ...roleDetails });
    };

    const handleRoleClick = ({ role }: { role: Role }) => {
        setFormRole(role);
    };

    const onSuccess = () => {
        revalidate();
        closeRoleForm();
    };

    const roleType = ROLE_MAPPINGS[formRole?.roleType!] || ROLE_MAPPINGS["default"];
    const formTitle = !!formRole ? (formRole.isSystemGeneratedRole ? "System Role" : `${roleType} Role`) : "Add Role";

    return (
        <div className={`${styles["roles-page"]} list-page`}>
            <div className={styles["roles-page__bar"]}>
                <RoleFilters
                    products={products}
                    filters={filters}
                    onChange={updateFilters}
                    disableInstances={!adjustedRoles?.length}
                />
                <LimitByRole action={ATTRIBUTE_ROLES.ROLES.CREATE} mode="hide">
                    <div className={styles["roles-page__add-role"]}>
                        <Button
                            style="highlight"
                            isDisabled={productsLoading || isLoading || !products.length}
                            onClick={openRoleSidebar}
                        >
                            Add Role...
                        </Button>
                    </div>
                </LimitByRole>
            </div>
            <LimitByRole mode="unauthorized" action={ATTRIBUTE_ROLES.ROLES.READ}>
                <div className={`${styles["roles-page__table"]} list-table flex flex--column`}>
                    <RolesTable
                        roles={adjustedRoles}
                        instances={instances}
                        onDeleteClick={({ role }) => setRoleToBeDeleted(role)}
                        onRoleClick={handleRoleClick}
                        onPagination={goToPage}
                        page={currentPage}
                        total={totalCount}
                        pageSize={pageSize}
                        products={products}
                    />
                    {!adjustedRoles?.length && (
                        <div>
                            Roles are not currently managed for the selected product in the Admin Center. Please
                            navigate to the product to manage roles.
                            <br />
                            You can also try&nbsp;
                            <button className="link button-as-link" type="button" onClick={resetRoleFilters}>
                                resetting your filters.
                            </button>
                        </div>
                    )}
                </div>
            </LimitByRole>
            {!!roleToBeDeleted && (
                <ConfirmationDialog
                    callToActionText="Delete"
                    error={roleDeletionError}
                    loading={roleDeletionInProgress}
                    onCancel={() => setRoleToBeDeleted(null)}
                    onConfirmation={handleRoleDeletion}
                    title="Delete Role"
                >
                    You are about to delete this role. It will remove all user access to the instances assigned to this
                    role. Are you sure you want to continue?
                </ConfirmationDialog>
            )}
            {(showRoleForm || formRole) && (
                <Sidebar heading={formTitle} isOpen onCancel={closeRoleForm}>
                    <RoleForm
                        allRoles={adjustedAllRoles}
                        onSuccess={onSuccess}
                        role={formRole}
                        products={products}
                        onClose={closeRoleForm}
                        onSubmit={handleSubmit}
                    />
                </Sidebar>
            )}
        </div>
    );
};

Roles.displayName = "RolesPage";
