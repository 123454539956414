import {
    Attention,
    Button,
    Dropdown,
    LayoutGrid,
    LayoutGridCell,
    LayoutGridContainer,
    Typography
} from "@optimizely/axiom";
import React, { useEffect, useRef, useState } from "react";
import { ADMINCENTER_PRODUCT_ID, ALL_PAGES_SIZE } from "../../../../constants";
import { InstanceRole } from "../../../../domain/InstanceRole.interface";
import { Flags } from "../../../../feature-flags/flags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { useProducts } from "../../../../hooks/useProducts/useProducts";
import { useRoles } from "../../../../hooks/useRoles/useRoles";
import { useUserContext } from "../../../../providers/UserProvider";
import { useUserGroupContext } from "../UserGroupFormContext/UserGroupContext";
import styles from "./UserGroupProducts.module.scss";
import { FilterDropdown } from "../../FilterDropdown/FilterDropdown";
import { InstanceRolesTable } from "../../InstanceRolesTable/InstanceRolesTable";

type UserGroupProductsProps = {
    editing?: boolean;
};

type InstanceSelection = {
    instanceId: string | null;
    productId: string | null;
    name: string | null;
};

const defaultInstanceSelection = {
    instanceId: null,
    productId: null,
    name: "Select a product instance..."
};

export const UserGroupProducts = ({ editing = false }: UserGroupProductsProps) => {
    const { organizationId } = useUserContext();
    const { enabled: abacEnabled } = useFeatureFlag(Flags.USE_ATTRIBUTE_PERMISSIONS);
    const { instances: availableProducts, isLoading: instancesLoading } = useProducts({ organizationId });
    const { userGroupState, updateUserGroupState } = useUserGroupContext();
    const { productInstances: products, userGroup, loading: loadingGroupProducts } = userGroupState;
    const { enabled: showRoles } = useFeatureFlag(Flags.SHOW_ROLES);
    const [instanceSelection, setInstanceSelection] = useState<InstanceSelection>(defaultInstanceSelection);
    const { roles = [], isLoading: rolesLoading } = useRoles({
        organizationId,
        productId: instanceSelection.productId || "",
        size: ALL_PAGES_SIZE
    });
    const [selectedRoleIds, setSelectedRoleIds] = useState<{ [key: string]: boolean }>({});
    const [availableRoles, setAvailableRoles] = useState<InstanceRole[]>([]);
    const currentInstanceId = useRef("");
    const groupIsAProductTypeGroup = userGroup?.groupType === "product";

    // temporary fix to filter roles on the frontend

    useEffect(() => {
        setInstanceSelection(defaultInstanceSelection);
    }, [userGroup]);

    useEffect(() => {
        if (
            roles.length &&
            instanceSelection?.instanceId &&
            instanceSelection.instanceId !== currentInstanceId?.current
        ) {
            setSelectedRoleIds({});
            const roleOptions = roles
                ?.filter((role) => {
                    const roleForInstance = role.targetIds?.indexOf(instanceSelection.instanceId!) > -1;
                    const roleForAllInstances = role.targetIds[0] === organizationId;
                    const productDefinedRole =
                        role.productId === instanceSelection?.productId && role.roleType === "ProductDefined";
                    return roleForAllInstances || roleForInstance || productDefinedRole;
                })
                ?.map((role: any) => {
                    return { id: role.id, name: role.name };
                });
            setAvailableRoles(roleOptions);
            currentInstanceId.current = instanceSelection.instanceId!;
        }
    }, [organizationId, instanceSelection, roles]);

    const handleProductInstanceRemoved = ({ instanceIndex }: { instanceIndex: number }) => {
        const updatedProducts = [...products];
        updatedProducts.splice(instanceIndex, 1);
        updateUserGroupState({ productInstances: updatedProducts });
    };

    const getSelectedRoles = () => {
        const selectedRoles = availableRoles.reduce((roles: any, role) => {
            const isSelectedRole = selectedRoleIds[role.id];
            return isSelectedRole ? [...roles, role] : roles;
        }, []);

        return selectedRoles;
    };

    const addProductInstance = ({ instanceId = instanceSelection?.instanceId || "" }: { instanceId: string }) => {
        const selectedInstance = availableProducts?.find((instance) => instance.instanceId === instanceId);

        if (selectedInstance) {
            selectedInstance.roles = getSelectedRoles();
            const updatedProducts = [...products, selectedInstance];
            updateUserGroupState({ productInstances: updatedProducts });
            setInstanceSelection(defaultInstanceSelection);
            setSelectedRoleIds({});
        }
    };

    const instanceDropdownOptions = availableProducts
        ?.filter(
            (p) =>
                !products.some((product) => product.instanceId === p.instanceId) &&
                (abacEnabled || (!abacEnabled && p.productId !== ADMINCENTER_PRODUCT_ID)) &&
                p.optiIdEnabled
        )
        .map((p) => ({
            name: p.productName,
            description: p.nickname || p.instanceName,
            id: p.instanceId,
            productId: p.productId
        }));

    const availableInstances = instanceDropdownOptions?.length;

    const updateRoleSelection = (id: string) => {
        const updatedRoles = { ...selectedRoleIds };
        if (updatedRoles[id]) {
            delete updatedRoles[id];
        } else {
            updatedRoles[id] = true;
        }
        setSelectedRoleIds(updatedRoles);
    };

    const rolesSelections = Object.keys(selectedRoleIds)
        .map((roleId) => availableRoles.find((role) => role.id === roleId)?.name)
        .filter(Boolean)
        .join(", ");

    const productInstanceHasRoles = (availableRoles || []).length > 0;
    const hasNoRolesSelected = Object.values(selectedRoleIds).filter((v) => !!v).length < 1;
    const disableAddRolesButton =
        instancesLoading ||
        rolesLoading ||
        !instanceSelection?.instanceId ||
        (productInstanceHasRoles && hasNoRolesSelected);

    return (
        <LayoutGridContainer className={styles["user-group-products"]}>
            {editing && (
                <LayoutGrid>
                    <LayoutGridCell large={10} medium={8} small={4} xlarge={12}>
                        <Typography type="header3">Add Product Instance</Typography>
                        {groupIsAProductTypeGroup && (
                            <Attention type="warning" className="push--bottom">
                                Product group instances cannot be updated.
                            </Attention>
                        )}
                    </LayoutGridCell>
                    {showRoles ? (
                        <>
                            <LayoutGridCell
                                className={styles["user-group-products__dropdown-selection"]}
                                large={10}
                                medium={8}
                                small={4}
                                xlarge={12}
                            >
                                {availableInstances ? (
                                    <FilterDropdown
                                        filterPlaceholder="Search Product Instances"
                                        disabled={groupIsAProductTypeGroup}
                                        dropdownPlaceholder="Select a product instance..."
                                        selectedText={instanceSelection?.name || undefined}
                                        onItemSelected={({ item }) => {
                                            const instance = instanceDropdownOptions.find((x) => x.id === item.key);
                                            if (instance) {
                                                setInstanceSelection({
                                                    instanceId: instance.id,
                                                    productId: instance.productId,
                                                    name: instance.name
                                                });
                                            }
                                        }}
                                        items={
                                            instanceDropdownOptions
                                                ? instanceDropdownOptions.map((i) => {
                                                      return {
                                                          key: i.id,
                                                          label: i.name,
                                                          description: i.description
                                                      };
                                                  })
                                                : []
                                        }
                                    />
                                ) : (
                                    "No product instances available"
                                )}
                            </LayoutGridCell>
                            <LayoutGridCell
                                className={styles["user-group-products__dropdown-selection"]}
                                large={12}
                                medium={8}
                                small={4}
                                xlarge={12}
                            >
                                {availableInstances !== 0 && (
                                    <Dropdown
                                        buttonContent={`${
                                            (availableRoles || []).length > 0 ? "Select roles..." : "N/A"
                                        }`}
                                        arrowIcon="down"
                                        fullWidth
                                        shouldHideChildrenOnClick={false}
                                        isDisabled={!instanceSelection.instanceId || (availableRoles || []).length < 1}
                                    >
                                        <Dropdown.Contents>
                                            {(availableRoles || []).map((role: InstanceRole) => {
                                                const { id, name } = role;
                                                return (
                                                    <Dropdown.ListItem key={id}>
                                                        <Dropdown.BlockLink
                                                            onClick={() => updateRoleSelection(id)}
                                                            isMultiSelect
                                                            isItemSelected={!!selectedRoleIds[id]}
                                                        >
                                                            {name}
                                                        </Dropdown.BlockLink>
                                                    </Dropdown.ListItem>
                                                );
                                            })}
                                        </Dropdown.Contents>
                                    </Dropdown>
                                )}
                                <div className={styles["roles-selections"]}>{rolesSelections}</div>
                            </LayoutGridCell>
                            <LayoutGridCell className={styles["user-group-products__add-product"]} xlarge={12}>
                                {availableInstances !== 0 && (
                                    <Button
                                        isDisabled={disableAddRolesButton}
                                        // eslint-disable-next-line
                                        style="outline"
                                        onClick={addProductInstance}
                                    >
                                        Add
                                    </Button>
                                )}
                            </LayoutGridCell>
                        </>
                    ) : (
                        <LayoutGridCell
                            className={styles["user-group-products__dropdown-selection"]}
                            large={12}
                            medium={8}
                            small={4}
                            xlarge={12}
                        >
                            {availableInstances ? (
                                <Dropdown buttonContent="Select a product instance..." arrowIcon="down" fullWidth>
                                    <Dropdown.Contents>
                                        {instanceDropdownOptions.map((instance) => {
                                            const { id, description, name } = instance;

                                            return (
                                                <Dropdown.ListItem key={id}>
                                                    <Dropdown.BlockLink
                                                        onClick={() => addProductInstance({ instanceId: id })}
                                                    >
                                                        <Dropdown.BlockLinkText text={name} />
                                                        <Dropdown.BlockLinkSecondaryText secondaryText={description} />
                                                    </Dropdown.BlockLink>
                                                </Dropdown.ListItem>
                                            );
                                        })}
                                    </Dropdown.Contents>
                                </Dropdown>
                            ) : (
                                "No product instances available"
                            )}
                        </LayoutGridCell>
                    )}
                </LayoutGrid>
            )}

            <LayoutGridCell large={12} medium={8} small={4}>
                <InstanceRolesTable
                    loading={loadingGroupProducts || instancesLoading || rolesLoading}
                    hideDelete={groupIsAProductTypeGroup}
                    userGroup={userGroup}
                    editing={editing}
                    instances={products}
                    onInstanceRemoval={handleProductInstanceRemoved}
                />
            </LayoutGridCell>
        </LayoutGridContainer>
    );
};

UserGroupProducts.displayName = "UserGroupProducts";
