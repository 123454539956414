import { Collection } from "../../domain/Collection";

import { Invitation } from "../../domain/Invitation";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, INVITATION_STATUS } from "../../constants";

type InvitationParameters = {
    pageSize?: number;
    organizationId?: string;
    inviteeEmail?: string;
    createdBy?: string;
};
export const useInvitations = (params: InvitationParameters) => {
    const { invitationService } = useServicesContext();
    const { organizationId, inviteeEmail, createdBy, pageSize } = params;
    let swrKey = organizationId ? `/api/invitations?organizationId=${params?.organizationId}` : null;
    if (inviteeEmail || createdBy) {
        swrKey = `/api/invitations?inviteeEmail=${inviteeEmail}&createdBy=${createdBy}`;
    }
    const { pageData, error, currentPage, setCurrentPage, mutate } = usePaginatedList<Invitation>({
        key: swrKey,
        fetcher: (): Promise<Collection<Invitation>> => {
            return invitationService!.getInvitations({
                organizationId: params?.organizationId as string,
                ...params,
                size: pageSize || DEFAULT_PAGE_SIZE,
                page: currentPage
            });
        }
    });

    const resendInvitation = async ({ invitationId }: { invitationId: string }) => {
        await invitationService!.resendInvitation({ invitationId });
        await mutate();
    };

    const revokeInvitation = async ({
        invitationId,
        requireAcceptance = true
    }: {
        invitationId: string;
        requireAcceptance?: boolean;
    }) => {
        await invitationService!.revokeInvitation({ invitationId, requireAcceptance });
        const revokedInvitation = pageData?.items?.find((invite) => invite.id === invitationId);

        const updateInvitations = () => {
            revokedInvitation && (revokedInvitation.status = INVITATION_STATUS.REVOKED);
            pageData?.items && mutate({ items: [...pageData.items], totalCount: pageData.totalCount });
        };

        pageData && updateInvitations();
    };

    const createInvitation = async (invitation: Partial<Invitation>) => {
        const newInvitation = await invitationService!.createInvitation(invitation);

        pageData &&
            (await mutate({
                items: [...(pageData.items || []), newInvitation],
                totalCount: pageData.totalCount + 1
            }));

        return newInvitation;
    };

    return {
        invitations: pageData?.items,
        createInvitation,
        isLoading: !error && !pageData,
        error,
        mutate,
        currentPage,
        setCurrentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        totalCount: pageData?.totalCount || 0,
        resendInvitation,
        revokeInvitation
    };
};
