/* eslint-disable react/style-prop-object */
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import ProjectsTable from "../../components/ProjectsTable/ProjectsTable";
import { useParams } from "react-router-dom";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import React, { useCallback, useEffect, useState } from "react";
import { IProductAttribute } from "../../../domain/ProductAttribute.interface";
import { Dropdown } from "@optimizely/axiom";
import { DEFAULT_DEBOUNCE, EXP_PROJECT_TYPES } from "../../../constants";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import styles from "../UserList/UserList.module.scss";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { ResetFilterPrompt } from "../../components/ResetFilterPrompt/ResetFilterPrompt";

export const AccessManagementProductInstanceProjects = () => {
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);
    const { instanceId } = useParams();
    const [projectsList, setProjectsList] = useState<IProductAttribute[]>([]);
    const [typeFilter, setTypeFilter] = useState<string | null>(null);
    const [activeStatusFilter, setActiveStatusFilter] = useState<boolean>(true);

    const { getExperimentationProjects } = useAttributes({ instanceId });

    const {
        debouncedValue: debouncedSearchQuery,
        value: searchQuery,
        setValue: setSearchQuery
    } = useDebounce<string>("", DEFAULT_DEBOUNCE);

    const getProjects = useCallback(async () => {
        if (instanceId) {
            const results = await getExperimentationProjects({ instanceId, projectKeys: [] });
            setProjectsList(results || []);
        }
    }, [getExperimentationProjects, instanceId]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    if (!accessManagementEnabled) return <Unauthorized />;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const filteredProjects = projectsList.filter(
        (p) =>
            (p.type === typeFilter || !typeFilter) &&
            p.isActive === activeStatusFilter &&
            p.name?.toLowerCase().includes(lowerCaseSearchQuery)
    );

    const resetFilter =
        !filteredProjects?.length &&
        projectsList.length === 0 &&
        (debouncedSearchQuery.length > 0 || !!typeFilter || !activeStatusFilter);

    return (
        <>
            <div className={styles["user-list__bar"]}>
                <div className={styles["user-list__search"]}>
                    <SearchInput
                        placeholder="Search projects by name..."
                        type="text"
                        value={searchQuery}
                        onChange={({ value }) => {
                            setSearchQuery(value);
                        }}
                    />
                </div>
            </div>
            <div className="push-double--left">
                <Dropdown
                    buttonContent={{
                        label: "Type",
                        content: typeFilter ?? "All"
                    }}
                    style="plain"
                    arrowIcon="down"
                    width={450}
                >
                    <Dropdown.Contents>
                        <Dropdown.ListItem key="All" role="option">
                            <Dropdown.BlockLink isLink={!!typeFilter} onClick={() => setTypeFilter(null)}>
                                <Dropdown.BlockLinkText text="All" />
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>

                        {Object.values(EXP_PROJECT_TYPES).map((type) => {
                            return (
                                <Dropdown.ListItem key={type} role="option">
                                    <Dropdown.BlockLink
                                        isLink={typeFilter !== type}
                                        onClick={() => setTypeFilter(type)}
                                    >
                                        <Dropdown.BlockLinkText text={type} />
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
                <Dropdown
                    buttonContent={{
                        label: "Active (status)",
                        content: activeStatusFilter ? "Active" : "Archived"
                    }}
                    style="plain"
                    arrowIcon="down"
                    width={350}
                >
                    <Dropdown.Contents>
                        {["Active", "Archived"].map((status) => {
                            return (
                                <Dropdown.ListItem key={status} role="option">
                                    <Dropdown.BlockLink
                                        isLink={
                                            (status === "Active" && !activeStatusFilter) ||
                                            (status === "Archived" && activeStatusFilter)
                                        }
                                        onClick={() => setActiveStatusFilter(status === "Active")}
                                    >
                                        <Dropdown.BlockLinkText text={status} />
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
            </div>
            {resetFilter && (
                <div className="push-double--top">
                    <ResetFilterPrompt
                        onClick={() => {
                            setSearchQuery("");
                            setTypeFilter(null);
                            setActiveStatusFilter(true);
                        }}
                        prompt="No projects match your search."
                    />
                </div>
            )}
            <ProjectsTable projectsList={filteredProjects}></ProjectsTable>
        </>
    );
};

AccessManagementProductInstanceProjects.displayName = "ProductInstanceProjectsPage";
