import React, { ChangeEvent } from "react";
import { Attention, LayoutGridContainer, Textarea, Typography } from "@optimizely/axiom";

import { useUserGroupContext } from "../UserGroupFormContext/UserGroupContext";
import { getFormattedDate } from "../../../../lib/date-formatter";
import { EVERYONE_GROUP_NAME, ADMINCENTER_GROUP_NAME, GROUP_TYPES } from "../../../../constants";
import { capitalizeText } from "../../../../lib/utils";

export const UserGroupDetails = ({ editing }: { editing?: boolean }) => {
    const { userGroupState, updateUserGroupState } = useUserGroupContext();
    const { description: contextDescription, userGroup } = userGroupState || {};
    const { created, modified } = userGroup || {};

    const updateDescription = (changeEvent: ChangeEvent) => {
        const { value } = changeEvent.target as HTMLInputElement;
        updateUserGroupState({ description: value });
    };

    const productTypeGroup = !!userGroup && userGroup.groupType === GROUP_TYPES.PRODUCT;
    const internalTypeGroup = !!userGroup && userGroup.groupType === GROUP_TYPES.INTERNAL;

    const readOnlyGroup =
        !!userGroup &&
        (productTypeGroup ||
            internalTypeGroup ||
            [EVERYONE_GROUP_NAME, ADMINCENTER_GROUP_NAME].includes(userGroup.name));

    return (
        <LayoutGridContainer>
            {editing ? (
                <>
                    {productTypeGroup && <Attention type="warning">Product group details cannot be updated.</Attention>}
                    {internalTypeGroup && (
                        <Attention type="warning">
                            {" "}
                            This is an internally generated group and details cannot be edited.
                        </Attention>
                    )}
                    {!readOnlyGroup ? (
                        <Textarea defaultValue={contextDescription} label="Description" onChange={updateDescription} />
                    ) : (
                        <>
                            <span className="oui-label push--top">Description</span>
                            <Typography type="body" className="label--disabled">
                                {contextDescription}
                            </Typography>
                        </>
                    )}
                </>
            ) : (
                <>
                    <span className="oui-label push--top">Description</span>
                    {userGroup?.description ? (
                        <Typography type="body" className="label--disabled">
                            {contextDescription}
                        </Typography>
                    ) : (
                        <Attention alignment="left" type="warning">
                            No description available for this group. Click "Edit" to add a description.
                        </Attention>
                    )}
                </>
            )}
            {userGroup?.groupType && (
                <>
                    <span className="oui-label push--top">Type</span>
                    <Typography type="body" className="label--disabled">
                        {capitalizeText(userGroup.groupType)}
                    </Typography>
                </>
            )}
            {!!(userGroup && created && modified) && (
                <>
                    <span className="oui-label push--top">Created</span>
                    <Typography type="body" className="label--disabled">
                        {getFormattedDate({ date: created! })}
                    </Typography>

                    <span className="oui-label push--top">Modified</span>
                    <Typography type="body" className="label--disabled">
                        {getFormattedDate({ date: modified! })}
                    </Typography>
                </>
            )}
        </LayoutGridContainer>
    );
};

UserGroupDetails.displayName = "UserGroupDetails";
