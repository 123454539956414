import { Collection } from "../domain/Collection";
import { User } from "../domain/User";
import { UserGroup } from "../domain/UserGroup";
import { Role } from "../domain/Role";

const update = (
    list: any[],
    eventData: any,
    matcher: (item: any, eventData: any) => boolean,
    updater: (eventData: any, dataList: any) => any
) => {
    let matchingItems = list.filter((item) => matcher(item, eventData)) || [];
    matchingItems.forEach(updater);
    return list;
};

const emailMatcher = (item: { email: string }, eventData: { user: { email: string } }) => {
    return item.email === eventData.user.email;
};

const idMatcher = (item: { id: string }, eventData: { group?: { id: string }; role?: { id: string } }) => {
    return item.id === eventData.group?.id || item.id === eventData.role?.id;
};

export const updateUser = (
    eventData: { user: Pick<User, "email" | "firstName" | "lastName"> },
    dataList: Collection<User> | Collection<User>[]
) => {
    let updatedList: Collection<User> | Collection<User>[] = dataList;
    if ((dataList as Collection<User>).items) {
        updatedList = {
            ...dataList,
            items: update((dataList as Collection<User>).items || [], eventData, emailMatcher, (item: User) => {
                item.firstName = eventData.user.firstName;
                item.lastName = eventData.user.lastName;
            })
        };
    } else if ((dataList as Collection<User>[]).length) {
        //this block handles infinite list (list of collections)
        updatedList = (dataList as Collection<User>[]).map((list: Collection<User>) => {
            return {
                ...list,
                items: update(list.items || [], eventData, emailMatcher, (item: User) => {
                    item.firstName = eventData.user.firstName;
                    item.lastName = eventData.user.lastName;
                })
            };
        });
    }

    return updatedList;
};

export const updateGroup = (
    eventData: { group: Pick<UserGroup, "id" | "name" | "description"> },
    dataList: Collection<UserGroup> | Collection<UserGroup>[]
) => {
    return {
        ...dataList,
        items: update((dataList as Collection<UserGroup>).items || [], eventData, idMatcher, (item: UserGroup) => {
            item.name = eventData.group?.name;
            item.description = eventData.group?.description;
        })
    };
};

export const updateRole = (
    eventData: { role: Pick<Role, "id" | "name" | "description"> },
    dataList: Collection<Role> | Collection<Role>[]
) => {
    return {
        ...dataList,
        items: update((dataList as Collection<Role>).items || [], eventData, idMatcher, (item: Role) => {
            item.name = eventData.role?.name;
            item.description = eventData.role?.description;
        })
    };
};
