import React, { useEffect, useRef, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Button, Typography, Attention } from "@optimizely/axiom";

import { ADMINCENTER_GROUP_NAME, EVERYONE_GROUP_NAME, ATTRIBUTE_ROLES, GROUP_TYPES } from "../../../../constants";
import { UserGroup } from "../../../../domain/UserGroup";
import { UserGroupFormTabs } from "../UserGroupFormTabs/UserGroupFormTabs";
import { User } from "../../../../domain/User";
import { useUserGroupContext } from "../UserGroupFormContext/UserGroupContext";
import { adaptApiErrors, IApiError } from "../../../../services/ErrorMessageAdapter";
import { useProducts } from "../../../../hooks/useProducts/useProducts";
import { useUsersByGroup } from "../../../../hooks/useUsersByGroup/useUsersByGroup";
import { SidebarFooter } from "../../Sidebar/SidebarFooter";
import { useUserContext } from "../../../../providers/UserProvider";
import { listIsDirty } from "../../../../lib/utils";

import classnames from "classnames";
import { datadogRum } from "@datadog/browser-rum";
import LimitByRole from "../../LimitByRole/LimitByRole";

import styles from "./UserGroupForm.module.scss";
import { emitToast } from "../../../../lib/toaster-utils";

type UserGroupFormProps = {
    onCancel: () => void;
    onSubmit: ({
        previousUserGroup,
        updatedUserGroup
    }: {
        previousUserGroup: UserGroup | null;
        updatedUserGroup: UserGroup | null;
    }) => Promise<any>;
    sidebarFooter?: boolean;
};

export interface IUserGroupFormValues {
    name: string;
    apiError: { message: string };
}

export const UserGroupForm = ({ onCancel, onSubmit }: UserGroupFormProps) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        control,
        formState: { errors }
    } = useForm<IUserGroupFormValues>({ mode: "onChange" });
    const { isSubmitting, isDirty } = useFormState({
        control
    });
    const { userGroupState, updateUserGroupState } = useUserGroupContext();
    const {
        description,
        editing: initialEditingState,
        productInstances,
        users,
        userGroup,
        initialUsers
    } = userGroupState;
    const { organizationId } = useUserContext();
    const { getUserGroupInstances } = useProducts({ organizationId });
    const { revalidate, users: groupUsers } = useUsersByGroup({ userGroupId: userGroup?.id });
    const { id: userGroupId = "" } = userGroup || {};
    const [editing, setEditing] = useState(initialEditingState);
    const [saving, setSaving] = useState(false);
    const currentGroupId = useRef<string | undefined>("");

    const formNameErrorClasses = classnames("push-quad--bottom", "soft-quad--sides", {
        "oui-form-bad-news": !!errors.name
    });

    useEffect(() => {
        if (userGroupId && (initialEditingState || !editing)) {
            getUserGroupInstances({ userGroupId }).then((instances) => {
                updateUserGroupState({ productInstances: instances?.items || [], loading: false });
            });
        }
    }, [getUserGroupInstances, userGroupId, updateUserGroupState, initialEditingState, editing]);

    useEffect(() => {
        if (userGroup) {
            setValue("name", userGroup.name);
            updateUserGroupState({ productInstances: [], users: [], loading: true });
        }
    }, [updateUserGroupState, userGroup, setValue]);

    useEffect(() => {
        // User is editing a group and decides to click on another group
        // so we should revert to view only mode
        if (userGroup?.id !== currentGroupId.current) {
            setEditing(initialEditingState);
            currentGroupId.current = userGroup?.id;
        }
    }, [initialEditingState, userGroup]);

    useEffect(() => {
        if (groupUsers?.length) {
            updateUserGroupState({ initialUsers: groupUsers });

            if (!users?.length) {
                updateUserGroupState({ users: groupUsers });
            }
        } else {
            updateUserGroupState({ initialUsers: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupUsers, updateUserGroupState]);

    const createNewGroup = () => {
        const { name } = getValues();

        const newGroup = new UserGroup({
            id: "0",
            name,
            organizationId: organizationId!,
            description: description || "",
            userCount: 3,
            groupOwner: null,
            created: new Date(),
            modified: new Date(),
            instancePermissions: productInstances.map((instance) => {
                return {
                    instanceId: instance.instanceId,
                    roleIds: instance.roles.map((role) => role.id)
                };
            }),
            users: users?.map((u: User) => `${u.email}`)
        });

        return newGroup;
    };

    const handleUpdate = (group: UserGroup) => {
        const { name } = getValues();
        const updatedGroup = new UserGroup(group);
        updatedGroup.name = name;
        updatedGroup.description = description || "";
        updatedGroup.groupOwner = null;

        updatedGroup.instancePermissions =
            productInstances?.map((pi) => {
                return {
                    instanceId: pi.instanceId,
                    roleIds: pi.roles.map((role) => role.id)
                };
            }) || [];

        updatedGroup.users = users?.map((u) => u.email) || [];

        return updatedGroup;
    };

    const handleError = (apiErrors: IApiError[]) => {
        const errors = adaptApiErrors(apiErrors);

        errors.forEach((error) => {
            if (error.field === "name") {
                setError("name", {
                    type: "individualFieldApiError",
                    message: error.message
                });
            } else {
                console.error(error.message);
                datadogRum.addError(error);
                setError("apiError", {
                    type: "formApiError",
                    message: error.message || "Unable to add group at this time. Please contact your admin."
                });
            }
        });
    };

    const handleFormSubmission = () => {
        if (userGroup) {
            if (editing) {
                const updatedUserGroup = handleUpdate(userGroup);
                setSaving(true);

                userGroup.users = initialUsers?.map((u) => u.email) || [];

                onSubmit({ previousUserGroup: userGroup, updatedUserGroup })
                    .then(async () => {
                        emitToast({ message: "Group successfully updated." });
                        await revalidate();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSaving(false);
                    });
            } else {
                setEditing(true);
            }
        } else {
            const newGroup = createNewGroup();
            setSaving(true);
            onSubmit({ previousUserGroup: null, updatedUserGroup: newGroup })
                .then(async () => {
                    emitToast({ message: "Group successfully added." });
                    updateUserGroupState({ productInstances: [], users: [] });
                    await revalidate();
                })
                .catch(handleError)
                .finally(() => {
                    setSaving(false);
                });
        }
    };

    let actionText = userGroup ? "Edit" : "Save";

    if (editing) {
        actionText = "Save";
    }

    // Manually clear form level error
    if (errors.apiError && isSubmitting) {
        clearErrors("apiError");
    }

    const showInputs = editing || !userGroup;
    const allowEditing =
        !userGroup ||
        (userGroup.name !== ADMINCENTER_GROUP_NAME &&
            userGroup.name !== EVERYONE_GROUP_NAME &&
            userGroup.groupType !== GROUP_TYPES.INTERNAL &&
            userGroup.groupType !== GROUP_TYPES.PRODUCT);

    const isGroupDirty =
        isDirty ||
        description !== userGroup?.description ||
        listIsDirty(
            initialUsers?.map((u) => u.email) || [],
            userGroupState.users.map((u) => u.email)
        ) ||
        listIsDirty(
            userGroup?.instancePermissions?.map((p) => p.instanceId) || [],
            userGroupState.productInstances.map((p) => p.instanceId)
        );

    return (
        <form className="user-group-form flex flex--column" onSubmit={handleSubmit(handleFormSubmission)}>
            {errors.apiError && (
                <div className="soft-quad--sides">
                    <Attention alignment="left" className="push--top push--bottom" type="bad-news">
                        {errors.apiError.message}
                    </Attention>
                </div>
            )}
            <div className={formNameErrorClasses}>
                {showInputs && allowEditing ? (
                    <>
                        <label className={styles["form-info-label"]} htmlFor="user-group-name">
                            Name
                            <span aria-label="(required)" className="oui-label--required" />
                        </label>
                        <input
                            aria-describedby="group-name-error"
                            className="oui-text-input"
                            id="user-group-name"
                            type="text"
                            {...register("name", {
                                required: { value: true, message: "Group name is required." }
                            })}
                        />
                        {errors.name && (
                            <span className="oui-form-note" id="group-name-error">
                                {errors.name.message}
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        <span className={`${styles["form-info-label"]} oui-label`}>Name</span>
                        <Typography type="body" className="label--disabled">
                            {userGroup?.name}
                        </Typography>
                    </>
                )}
            </div>
            <UserGroupFormTabs
                editing={(editing && !!userGroup) || !userGroup}
                isEveryoneGroup={userGroup?.name === EVERYONE_GROUP_NAME}
            />
            <LimitByRole action={ATTRIBUTE_ROLES.GROUPS.UPDATE} mode="hide">
                <SidebarFooter onCancel={onCancel}>
                    <Button
                        key="save-user-group-button"
                        isDisabled={showInputs && !isGroupDirty}
                        // eslint-disable-next-line react/style-prop-object
                        style="highlight"
                        isSubmit
                        loadingText={"Saving"}
                        isLoading={saving}
                    >
                        {actionText}
                    </Button>
                </SidebarFooter>
            </LimitByRole>
        </form>
    );
};

UserGroupForm.displayName = "UserGroupForm";
