import { Table } from "@optimizely/axiom";
import React, { useEffect, useState } from "react";
import { ADMINCENTER_PRODUCT_ID } from "../../../../constants";
import { ProductPermission } from "../../../../domain/Permission.interface";
import { AccessManagementUserFormInstanceTableRow } from "./AccessManagementUserFormInstanceTableRow";
import { useAccessFlowUserFormContext } from "./AccessManagementUserFormProvider";
import { Role } from "../../../../domain/Role";
import { UserGroup } from "../../../../domain/UserGroup";
import { ProductAttribute } from "../../../../domain/ProductAttribute";
import { IOrganizationProductInstance } from "../../../../domain/OrganizationProduct";
import { useLocation } from "react-router-dom";

export const AccessManagementUserFormInstanceTable = ({
    className = "",
    productAccess,
    productAvailability,
    onGroupsChanged
}: {
    className?: string;
    productAccess?: ProductPermission;
    productAvailability: ProductPermission | undefined;
    onGroupsChanged: (previousGroupId: string | undefined, updatedGroupId: string | undefined) => Promise<any | void>;
}) => {
    const { productId, instanceAccess: instances } = productAccess || {};
    const [groupRoleRows, setGroupRoleRows] = useState<
        {
            groupRole: { group: UserGroup; role: Role; project?: ProductAttribute };
            instance: IOrganizationProductInstance;
        }[]
    >([]);
    const { userGroupIds, setUserGroupIds } = useAccessFlowUserFormContext();
    const areAdminCenterInstances = productId === ADMINCENTER_PRODUCT_ID;
    const areExperimentationInstances = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    const { pathname } = useLocation();
    const isOnUsersPage = pathname === "/user-management/users";

    const handleGroupChanged = async (previousGroupId: string | undefined, updatedGroupId: string | undefined) => {
        let updatedRows = [...groupRoleRows];
        let updatedGroupIds = [...userGroupIds];

        updatedRows = updatedRows.filter((r) => r.groupRole.group.id !== previousGroupId);
        updatedGroupIds = updatedGroupIds.filter((id) => id !== previousGroupId);

        if (updatedGroupId) {
            const instance = productAvailability?.instanceAccess.find((ia) =>
                ia.availableRoles.find((ar) => ar.group.id === updatedGroupId)
            )?.instance;

            const groupRole = productAvailability?.instanceAccess
                .flatMap((ia) => ia.availableRoles)
                .find((ar) => ar.group.id === updatedGroupId);

            if (instance && groupRole) {
                updatedRows = [
                    ...updatedRows,
                    {
                        instance,
                        groupRole
                    }
                ];

                updatedGroupIds = [...updatedGroupIds, groupRole.group.id];
            }
        }
        const result = await onGroupsChanged(previousGroupId, updatedGroupId);

        setGroupRoleRows(updatedRows);
        setUserGroupIds(updatedGroupIds);

        return result;
    };

    useEffect(() => {
        const initialRows = instances?.flatMap(({ instance, availableRoles }) => {
            return availableRoles?.map((groupRole) => {
                return { instance, groupRole };
            });
        });
        if (initialRows) {
            setGroupRoleRows(initialRows);
        }
    }, [instances]);

    return (
        <Table
            className={className}
            // eslint-disable-next-line react/style-prop-object
            style="rule"
            shouldAddHover={false}
            tableLayoutAlgorithm="auto"
        >
            <Table.THead>
                <Table.TR>
                    <Table.TH width={areExperimentationInstances ? "25%" : "40%"}>
                        {areAdminCenterInstances ? "Organization" : "Instance "}
                    </Table.TH>
                    {areExperimentationInstances && <Table.TH width="25%">Project</Table.TH>}
                    <Table.TH width={areExperimentationInstances ? "30%" : "40%"}>Role</Table.TH>
                    <Table.TH width="20%"></Table.TH>
                </Table.TR>
            </Table.THead>
            <Table.TBody>
                {groupRoleRows?.map(({ instance, groupRole }: { instance: any; groupRole: any }, index) => {
                    return (
                        <AccessManagementUserFormInstanceTableRow
                            key={index}
                            instance={instance}
                            groupRole={groupRole}
                            groupRoleIndex={index}
                            productAvailability={productAvailability}
                            onGroupChanged={handleGroupChanged}
                            topLevelDelete={isOnUsersPage}
                        />
                    );
                })}
            </Table.TBody>
        </Table>
    );
};

AccessManagementUserFormInstanceTable.displayName = "AccessManagementUserFormInstanceTable";
