import React, { useEffect } from "react";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { useLocation } from "react-router-dom";
import { useSession } from "../../../hooks/useSession/useSession";
import { useUserContext } from "../../../providers/UserProvider";

export const AnalyticsTracking = () => {
    const { sendPageEvent, sendIdentifyEvent, sendGroupEvent, sendTrackEvent } = useAnalyticsTracking();
    const { user, oktaAuth } = useSession();
    const { organizationId, isLoading } = useUserContext();
    const location = useLocation();

    useEffect(() => {
        sendPageEvent();
    }, [location?.pathname, sendPageEvent]);

    useEffect(() => {
        if (organizationId) {
            sendGroupEvent();
        }
    }, [organizationId, sendGroupEvent]);

    useEffect(() => {
        if (user?.email) {
            sendIdentifyEvent();
        }
    }, [user?.email, sendIdentifyEvent]);

    useEffect(() => {
        oktaAuth.tokenManager.on("added", function (key: string) {
            if (key === "accessToken") {
                sendTrackEvent({
                    name: `Authentication: Token Added`,
                    component: "Session",
                    flow: "Login",
                    data: {}
                });
            }
        });
        if (!isLoading) {
            // Triggered when a token has been renewed
            oktaAuth.tokenManager.on("renewed", function (key: string) {
                if (key === "accessToken") {
                    sendTrackEvent({
                        name: `Authentication: Token Renewed`,
                        component: "Session",
                        flow: "Login",
                        data: {}
                    });
                }
            });
        }

        return () => {
            oktaAuth.tokenManager.off("added");
            oktaAuth.tokenManager.off("renewed");
        };
    }, [oktaAuth.tokenManager, isLoading, location.pathname, sendTrackEvent]);

    return <></>;
};

AnalyticsTracking.displayName = "AnalyticsTracking";
