import React from "react";
import { Navigate } from "react-router-dom";

import { useUserContext } from "../../../providers/UserProvider";
import { ROUTE_VIEW_MAPPINGS } from "../../../constants";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";
import { Flags } from "../../../feature-flags/flags";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";

const DetermineAuthorizedRoute = ({
    routesList,
    featureFlag
}: {
    routesList: any[];
    featureFlag: string | undefined;
}): React.ReactElement | null => {
    const view = routesList[0];
    const { canUserDoAction } = useUserContext();

    type flagEnumKey = keyof typeof Flags;
    const { enabled } = useFeatureFlag(featureFlag as (typeof Flags)[flagEnumKey]);
    let featureFlagEnabled = enabled;

    if (view.path === "/entitlements") featureFlagEnabled = !enabled;

    if (routesList.length < 1) {
        return <Unauthorized />;
    }

    const userHasPermissions = !view?.actions || canUserDoAction({ action: view?.actions });
    const authorizedToAccessPage =
        (featureFlagEnabled && userHasPermissions) || (!view?.featureFlag && userHasPermissions);

    if (authorizedToAccessPage) {
        return <Navigate to={view.path} replace />;
    }

    routesList.shift();
    const nextFeatureFlag = routesList[0]?.featureFlag;
    return DetermineAuthorizedRoute({ routesList, featureFlag: nextFeatureFlag });
};

export const NavigateToAuthorized = () => {
    const routesList = Object.keys(ROUTE_VIEW_MAPPINGS)
        .map((path) => {
            return { path, ...ROUTE_VIEW_MAPPINGS[path] };
        })
        .sort((a, b) => a.priorityOrder - b.priorityOrder);

    const featureFlag = routesList[0].featureFlag;

    return DetermineAuthorizedRoute({ routesList, featureFlag });
};

NavigateToAuthorized.displayName = "NavigateToAuthorized";
