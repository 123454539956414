/* eslint-disable react/style-prop-object */
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";

import styles from "./Usage.module.scss";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import { ProductUsageDashboards } from "../../components/ProductUsageDashboards/ProductUsageDashboards";
import { CustomerSelectionDropdown } from "../../components/CustomerSelectionDropdown/CustomerSelectionDropdown";
import { useRef, useState } from "react";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";
import { Customer } from "../../../rest-model/Customer";
import { Button, Icon } from "@optimizely/axiom";

type UsageProps = {
    enableCustomerSelection?: boolean;
};

export const Usage = ({ enableCustomerSelection = false }: UsageProps) => {
    const { organizationId, canUserDoAction } = useUserContext();
    const { enabled: showSupportUsageTab } = useFeatureFlag(Flags.SHOW_SUPPORT_USAGE_TAB);
    const { enabled: enableActivityLog } = useFeatureFlag(Flags.ENABLE_ACTIVITY_LOG);
    const { enabled: showUsageAndBilling } = useFeatureFlag(Flags.SHOW_USAGE_AND_BILLING);
    const currentCustomerRef = useRef<Customer>();
    const {
        error: initialProductsError,
        isLoading,
        products = [],
        getProductsForOrganization
    } = useProducts({ organizationId, includeServices: true });
    const [error, setError] = useState(initialProductsError);
    const [loading, setLoading] = useState(isLoading);
    const [selectedCustomerDetails, setSelectedCustomerDetails] = useState<{
        organizationId?: string;
        products: OrganizationProduct[];
    }>({ organizationId: undefined, products: [] });

    if (isLoading) return <LoadingIndicator height="100%" type="spinner" />;

    const canReadUsageDashboard = canUserDoAction({ action: "Read Billing Dashboard" });
    const unauthorizedSupport = enableCustomerSelection && !showSupportUsageTab;
    const unauthorizedUser = !(enableCustomerSelection || showUsageAndBilling);

    if (!canReadUsageDashboard || unauthorizedSupport || unauthorizedUser) {
        return <Unauthorized />;
    }

    const updateCustomer = async ({ customer }: { customer: Customer }) => {
        currentCustomerRef.current = customer;
        setError(undefined);
        setLoading(true);
        getProductsForOrganization({
            organizationId: customer.id
        })
            .then((res) => {
                const { products: organizationProducts } = res;
                setSelectedCustomerDetails({
                    organizationId: customer.id,
                    products: organizationProducts || []
                });
            })
            .catch((e) => {
                setError(new Error("An error occurred getting the products."));
                // So DD captures it
                console.error("error getting products ", e);
            })
            .finally(() => setLoading(false));
    };

    const handleRefresh = async () => {
        if (enableCustomerSelection) {
            await updateCustomer({ customer: currentCustomerRef.current! });
        } else {
            setLoading(true);
            await getProductsForOrganization({ organizationId: organizationId as string });
            setLoading(false);
        }
    };

    const dashboardOrg = enableCustomerSelection ? selectedCustomerDetails.organizationId : organizationId;
    const dashboardProducts = (enableCustomerSelection ? selectedCustomerDetails.products : products).filter(
        (p) => !p.isService || p.id === process.env.REACT_APP_SAAS_DEV_PORTAL_PRODUCT_ID
    );
    const showDashboard =
        !enableCustomerSelection || (enableCustomerSelection && selectedCustomerDetails.organizationId);

    return (
        <div className={styles["usage-page"]}>
            <div className="flex justify-content-end">
                <Button style="highlight" isDisabled={loading} onClick={handleRefresh}>
                    {loading ? (
                        <span>Loading...</span>
                    ) : (
                        <>
                            <Icon name="arrows-rotate" size="small" />
                            <span className="push--left">Refresh</span>
                        </>
                    )}
                </Button>
            </div>
            {enableCustomerSelection && (
                <>
                    <CustomerSelectionDropdown className="push-double--ends min-width--300" onSelect={updateCustomer} />
                    {!(error || loading || selectedCustomerDetails?.organizationId) && (
                        <div>Search and select an organization to view its usage details.</div>
                    )}
                    {loading && <LoadingIndicator type="spinner" />}
                </>
            )}
            {error && <div className="push-double--top">Error fetching product usage data.</div>}
            {showDashboard && !error && (
                <ProductUsageDashboards
                    enableActivityLog={enableActivityLog}
                    organizationId={dashboardOrg}
                    products={dashboardProducts}
                />
            )}
        </div>
    );
};

Usage.displayName = "UsagePage";
