import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { ProductInstancesTable } from "../../components/ProductInstancesTable/ProductInstancesTable";
import { PaginationControls } from "@optimizely/axiom";
import { paginateArray } from "../../../lib/utils";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { ResetFilterPrompt } from "../../components/ResetFilterPrompt/ResetFilterPrompt";
import styles from "../ProductInstances/ProductInstances.module.scss";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import { DEFAULT_PAGE_SIZE } from "../../../constants";

export const AccessManagementProductInstances = () => {
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);
    const { productId = "" } = useParams();
    const { organizationId } = useUserContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");
    const { isLoading, products } = useProducts({ organizationId });
    const productDetails = products?.find((p) => p.id === productId);
    const [instancePages, setInstancePages] = useState<{
        totalPages: number;
        total: number;
        pages: any;
    }>(paginateArray({ list: productDetails?.instances || [] }));

    const handlePagination = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (productId) {
            const newInstancePages = paginateArray({ list: productDetails?.instances || [] });
            setInstancePages(newInstancePages);
        }
    }, [productId, products, productDetails?.instances]);

    const filterInstances = ({ value }: { value: string }) => {
        let newInstancePages;
        if (!value) {
            newInstancePages = paginateArray({ list: productDetails?.instances || [] });
        } else {
            newInstancePages = paginateArray({
                list: productDetails?.instances || [],
                filter: { query: value, fields: ["name", "nickname"] }
            });
        }
        setInstancePages(newInstancePages);
        setFilterText(value);
    };

    const { pages = [], total: totalInstances = 0, totalPages } = instancePages;
    const showPagination = totalInstances > DEFAULT_PAGE_SIZE;

    if (!accessManagementEnabled) return <Unauthorized />;

    return (
        <div className={styles["product-instances"]}>
            <div className={styles["product-instances__search"]}>
                <SearchInput placeholder="Filter by instance..." onChange={filterInstances} value={filterText} />
            </div>
            <ProductInstancesTable
                instances={pages[currentPage - 1]}
                loading={isLoading}
                accessManagementEnabled={accessManagementEnabled}
                productId={productId}
            />
            {totalInstances === 0 && (
                <ResetFilterPrompt
                    onClick={() => filterInstances({ value: "" })}
                    prompt="No instances match your filter."
                />
            )}
            {showPagination && (
                <PaginationControls
                    className="anchor--bottom"
                    currentPage={currentPage}
                    goToPage={handlePagination}
                    totalPages={totalPages}
                />
            )}
        </div>
    );
};

AccessManagementProductInstances.displayName = "ProductInstancesPage";
