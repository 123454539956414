import React, { useCallback, useEffect } from "react";
import { useSWRConfig } from "swr";
import { updateGroup, updateUser, updateRole } from "../../../lib/sync-updaters";
import { Collection } from "../../../domain/Collection";
import { ADMINCENTER_ITEM_UPDATED_EVENT_NAME } from "../../../constants";

export const UpdateSynchronizer = () => {
    const { cache, mutate } = useSWRConfig();

    const handleUpdateSyncEvent = useCallback(
        (e: Event): void => {
            const eventTypeKeyMapping: { [key: string]: string[] } = {
                "update-user": ["list-of-users"],
                "update-group": ["list-of-groups"],
                "update-role": ["list-of-roles"]
                // "add-user-to-group": ["list-of-groups", "list-of-users"]
            };

            const keyUpdaterMapping: {
                [key: string]: ((
                    eventData: any,
                    list: Collection<any> | Collection<any>[]
                ) => Collection<any> | Collection<any>[])[];
            } = {
                "list-of-users": [updateUser],
                "list-of-groups": [updateGroup],
                "list-of-roles": [updateRole]
            };

            const { type, data: eventData } = (e as CustomEvent).detail;

            const cacheKeys = Array.from((cache as Map<string, any>).keys());

            cacheKeys
                .filter((key: string) => {
                    const [keyType] = key.split(":");
                    return eventTypeKeyMapping[type].some((k) => keyType.includes(k));
                })
                .forEach(async (key) => {
                    const [keyType] = key.split(":");
                    //handle the infinite list keys which have a prefix.
                    // prettier-ignore
                    const keyTypeLookup = keyType.replace("$inf$@\"", "");

                    keyUpdaterMapping[keyTypeLookup]?.forEach((updater) => {
                        mutate(key, (existingData: any) => updater(eventData, existingData), {
                            revalidate: false
                        });
                    });
                });
        },
        [cache, mutate]
    );

    useEffect(() => {
        window.addEventListener(
            ADMINCENTER_ITEM_UPDATED_EVENT_NAME,
            handleUpdateSyncEvent as EventListenerOrEventListenerObject
        );

        return () => {
            window.removeEventListener(
                ADMINCENTER_ITEM_UPDATED_EVENT_NAME,
                handleUpdateSyncEvent as EventListenerOrEventListenerObject
            );
        };
    }, [handleUpdateSyncEvent]);
    return <></>;
};
