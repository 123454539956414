import React, { useEffect, useState } from "react";
import {
    Attention,
    LayoutGrid,
    LayoutGridCell,
    LayoutGridContainer,
    ProgressDots,
    Table,
    Typography
} from "@optimizely/axiom";

import { useUserGroupContext } from "../UserGroupFormContext/UserGroupContext";
import { User } from "../../../../domain/User";
import { InfiniteScrollList } from "../../InfiniteScrollList/InfiniteScrollList";
import { useUsersByGroup } from "../../../../hooks/useUsersByGroup/useUsersByGroup";
import { TableSkeleton } from "../../Skeleton/TableSkeleton";

import styles from "./UserGroupFormUsers.module.scss";
import { UserSearchSelector } from "../../UserSearchSelector/UserSearchSelector";
import { GROUP_TYPES } from "../../../../constants";
import { UserTableRow } from "../../UserTableRow/UserTableRow";

type UserGroupFormUsersProps = {
    editing?: boolean;
};

export const UserGroupFormUsers = ({ editing }: UserGroupFormUsersProps) => {
    const { userGroupState, updateUserGroupState } = useUserGroupContext();
    const { users, userGroup, initialUsers } = userGroupState;

    const { loadNext, isLoadingMore, isLoadingInitialData } = useUsersByGroup({
        userGroupId: userGroup?.id
    });

    const [userInList, setUserInList] = useState(false);

    useEffect(() => {
        setUserInList(false);
        if (initialUsers && initialUsers.length !== users.length) {
            const lastUser = users[users.length - 1];
            const indexLastUser = initialUsers?.indexOf(lastUser);
            if (indexLastUser >= 0) {
                const updatedList = [...users, ...initialUsers.slice(initialUsers?.indexOf(lastUser) + 1)];
                updateUserGroupState({
                    users: updatedList
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserGroupState, initialUsers]);

    const addUser = (newUser?: User) => {
        if (newUser) {
            setUserInList(false);

            if (newUser) {
                const updatedUsers = [newUser, ...users];
                updateUserGroupState({ users: updatedUsers });
            }
        } else {
            setUserInList(true);
        }
    };

    const removeUser = ({ index }: { index: number }) => {
        setUserInList(false);
        const updatedUsers = [...users];
        updatedUsers.splice(index, 1);
        updateUserGroupState({ users: updatedUsers });
    };

    const isUserDisabled = (user: User) => {
        return users.map((u) => u.email).includes(user.email);
    };

    const internalTypeGroup = !!userGroup && userGroup.groupType === GROUP_TYPES.INTERNAL;

    return (
        <LayoutGridContainer>
            {editing && (
                <>
                    {!internalTypeGroup ? (
                        <LayoutGrid>
                            <LayoutGridCell large={12} medium={8} small={4} xlarge={12}>
                                <UserSearchSelector onSelectUser={addUser} isUserDisabled={isUserDisabled} />
                                {userInList && (
                                    <Attention type="warning">The user selected is already in the user list.</Attention>
                                )}
                            </LayoutGridCell>
                        </LayoutGrid>
                    ) : (
                        <Attention type="warning">
                            This is an internally generated group and users cannot be edited.
                        </Attention>
                    )}
                </>
            )}

            <LayoutGridCell large={12} medium={8} small={4}>
                {isLoadingInitialData ? (
                    <TableSkeleton />
                ) : !users?.length ? (
                    <Typography type="body" className="div">
                        No users added to group
                    </Typography>
                ) : (
                    <Table
                        className={`push--top ${styles["user-group-form-users__table"]}`}
                        // eslint-disable-next-line react/style-prop-object
                        style="rule"
                    >
                        <Table.THead>
                            <Table.TR>{editing && <Table.TH> </Table.TH>}</Table.TR>
                        </Table.THead>

                        <Table.TBody>
                            <InfiniteScrollList
                                isLoadingMore={isLoadingMore}
                                loadNext={loadNext}
                                loadingItem={
                                    <Table.TR>
                                        <Table.TD colSpan={3} textAlign="center">
                                            <ProgressDots />
                                        </Table.TD>
                                    </Table.TR>
                                }
                            >
                                {users.map((item, itemIndex) => (
                                    <UserTableRow
                                        user={item}
                                        key={itemIndex}
                                        removeTitle="Remove user from group"
                                        onRemove={
                                            editing && !internalTypeGroup
                                                ? () => removeUser({ index: itemIndex })
                                                : undefined
                                        }
                                    />
                                ))}
                            </InfiniteScrollList>
                        </Table.TBody>
                    </Table>
                )}
            </LayoutGridCell>
        </LayoutGridContainer>
    );
};

UserGroupFormUsers.displayName = "UserGroupFormUsers";
