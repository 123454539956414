import { Dropdown } from "@optimizely/axiom";
import React, { Ref } from "react";
import { SearchInput } from "../SearchInput/SearchInput";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import { DEFAULT_DEBOUNCE } from "../../../constants";
import { useCustomers } from "../../../hooks/useCustomers/useCustomers";
import classnames from "classnames";
import { Customer } from "../../../rest-model/Customer";
import { UseFormRegister } from "react-hook-form/dist/types/form";

type CustomerSelectionDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    onSelect: ({ customer }: { customer: Customer }) => void;
    placeholder?: string;
    register?: UseFormRegister<any>;
};
export const CustomerSelectionDropdown = ({
    className,
    disabled = false,
    fullWidth = false,
    label = "Search for an organization by name",
    onSelect,
    placeholder = "Search for an organization by name...",
    register
}: CustomerSelectionDropdownProps) => {
    const inputClasses = classnames(className);
    const {
        debouncedValue: debouncedCustomerQuery,
        value: customerQuery,
        setValue: setCustomerQuery
    } = useDebounce("", DEFAULT_DEBOUNCE);

    const { customers } = useCustomers({
        query: debouncedCustomerQuery?.length >= 3 ? debouncedCustomerQuery : ""
    });

    const searchOrganizations = (input: { value: string }) => {
        setCustomerQuery(input.value);
    };

    const handleCustomerSelection = ({ customer }: { customer: Customer }) => {
        setCustomerQuery(customer.name);
        onSelect({ customer });
    };

    return (
        <Dropdown
            className={inputClasses}
            renderActivator={({
                onBlur,
                onClick,
                ref
            }: {
                onBlur: (...args: any[]) => any;
                onClick: (...args: any[]) => any;
                ref: Ref<HTMLInputElement>;
            }) => {
                const registerProps = register
                    ? {
                          ...register("organization", {
                              required: {
                                  value: true,
                                  message: "Organization name is required."
                              }
                          })
                      }
                    : "";
                return (
                    <SearchInput
                        ariaLabel={label}
                        disabled={disabled}
                        placeholder={placeholder}
                        value={customerQuery}
                        {...registerProps}
                        onChange={searchOrganizations}
                        onClick={onClick}
                        onBlur={onBlur}
                        inputRef={ref}
                    />
                );
            }}
            fullWidth={fullWidth}
        >
            <Dropdown.Contents style={{ width: "100%" }}>
                {customers?.map((customer) => {
                    return (
                        <Dropdown.ListItem key={customer?.id}>
                            <Dropdown.BlockLink onClick={() => handleCustomerSelection({ customer })}>
                                {customer.name}
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

CustomerSelectionDropdown.displayName = "CustomerSelectionDropdown";
