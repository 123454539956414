import React from "react";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { Table } from "@optimizely/axiom";
import { Skeleton } from "../Skeleton/Skeleton";

type LoadingTableProps = {
    headers?: string[];
    rows?: number;
};

export const LoadingTable = ({ headers = [], rows = DEFAULT_PAGE_SIZE }: LoadingTableProps) => {
    const rowIndicatorCount = headers.length || 1;
    const indicatorArray = new Array(rowIndicatorCount).fill("");

    return (
        <Table
            className="push-double--bottom"
            density="loose"
            // eslint-disable-next-line react/style-prop-object
            style="rule-no-bottom-border"
        >
            {headers.length > 0 && (
                <Table.THead>
                    <Table.TR>
                        {headers.map((h, hIndex) => (
                            <Table.TH key={hIndex}>{h}</Table.TH>
                        ))}
                    </Table.TR>
                </Table.THead>
            )}
            <Table.TBody>
                {new Array(rows).fill("").map((_, index) => {
                    return (
                        <Table.TR key={index}>
                            {indicatorArray.map((_, indicatorIndex) => {
                                return (
                                    <Table.TD key={indicatorIndex}>
                                        <Skeleton key={indicatorIndex} height="30px" width="150px" />
                                    </Table.TD>
                                );
                            })}
                        </Table.TR>
                    );
                })}
            </Table.TBody>
        </Table>
    );
};

LoadingTable.displayName = "LoadingTable";
