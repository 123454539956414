import { Dropdown, Spinner } from "@optimizely/axiom";
import React from "react";
import { ProductAttribute } from "../../../domain/ProductAttribute";

type ProjectsDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    onChange: ({ value }: { value: ProductAttribute | ProductAttribute[] | null }) => void;
    placeholder?: string;
    projects: ProductAttribute[] | undefined;
    value?: string | string[];
    white?: boolean;
};

export const ProjectsDropdown = ({
    className,
    disabled = false,
    fullWidth = false,
    loading,
    onChange,
    placeholder = "Select Project...",
    projects = [],
    value,
    white = false
}: ProjectsDropdownProps) => {
    const selectedProject = projects?.find((p) => p.id === value);
    let buttonContent: string | React.ReactNode = selectedProject?.name || placeholder;
    const noOptions = !projects.length;
    if (loading) {
        buttonContent = (
            <>
                <Spinner size="small" /> Loading projects...
            </>
        );
    } else if (noOptions) {
        buttonContent = "No projects available.";
    }
    return (
        <Dropdown
            arrowIcon={loading ? null : "down"}
            buttonContent={buttonContent}
            className={className}
            fullWidth={fullWidth}
            isDisabled={disabled || noOptions || loading}
            style={white ? "outline" : undefined}
            whiteBackground={white}
        >
            <Dropdown.Contents style={{ width: "100%" }}>
                {projects?.map((project) => {
                    const { id, name } = project;
                    return (
                        <Dropdown.ListItem key={id} role="option">
                            <Dropdown.BlockLink isLink={value !== id} onClick={() => onChange({ value: project })}>
                                <Dropdown.BlockLinkText text={name} />
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

ProjectsDropdown.displayName = "ProjectsDropdown";
