import React, { SetStateAction, useEffect, useState } from "react";
import { Disclose, ProgressDots } from "@optimizely/axiom";

import { InfiniteScrollList } from "../InfiniteScrollList/InfiniteScrollList";
import { InstanceRolesTable } from "../InstanceRolesTable/InstanceRolesTable";
import { TableSkeleton } from "../Skeleton/TableSkeleton";

import { useUserContext } from "../../../providers/UserProvider";
import { useGroupsByUser } from "../../../hooks/useGroupsByUser/useGroupsByUser";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { UserGroup } from "../../../domain/UserGroup";
import { ProductInstance } from "../../../domain/ProductInstance";
import { AccessManagementUserFormIconButton } from "../Access/AccessManagementUserForm/AccessManagementUserFormIconButton";

import { ATTRIBUTE_ROLES, GROUP_TYPES } from "../../../constants";
import { useUserGroups } from "../../../hooks/useUserGroups/useUserGroups";
import { datadogRum } from "@datadog/browser-rum";
import LimitByRole from "../LimitByRole/LimitByRole";
import { emitToast } from "../../../lib/toaster-utils";

type UserGroupWithDetailedInstances = UserGroup & { instances: ProductInstance[] };

export const UserGroupAccessList = ({
    email,
    setError
}: {
    email: string;
    setError: React.Dispatch<SetStateAction<string | null>>;
}) => {
    const { organizationId } = useUserContext();
    const { groups, loadNext, isLoadingMore, isLoadingInitialData, revalidate } = useGroupsByUser({
        organizationId: organizationId || null,
        email
    });
    const { getUserGroupInstances } = useProducts({ organizationId });
    const { deleteUserGroupUsers } = useUserGroups({ organizationId });

    const [detailedGroups, setDetailedGroups] = useState<UserGroupWithDetailedInstances[]>([]);
    const [removingGroupAccess, setRemovingGroupAccess] = useState<string | null>(null);

    const updateDetailedGroups = async (detailed: any) => {
        setDetailedGroups(await Promise.all(detailed));
    };

    useEffect(() => {
        if (!!groups) {
            const detailedGroups = groups?.map((group) => {
                return getUserGroupInstances({ userGroupId: group.id })
                    .then((response) => {
                        return {
                            ...group,
                            instances: response?.items || []
                        };
                    })
                    .catch((error) => {
                        console.error("Failed getting group instances:", error);
                        return [];
                    });
            });
            updateDetailedGroups(detailedGroups);
        }
    }, [getUserGroupInstances, groups]);

    return (
        <div>
            {isLoadingInitialData ? (
                <TableSkeleton />
            ) : (
                <InfiniteScrollList isLoadingMore={isLoadingMore} loadNext={loadNext} loadingItem={<ProgressDots />}>
                    {detailedGroups.map((group) => {
                        return (
                            <div key={group.id}>
                                <Disclose
                                    title={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%"
                                            }}
                                        >
                                            <span>{group.name}</span>
                                            <LimitByRole
                                                action={[ATTRIBUTE_ROLES.GROUPS.MANAGE, ATTRIBUTE_ROLES.GROUPS.UPDATE]}
                                                mode="hide"
                                            >
                                                {group.groupType !== GROUP_TYPES.INTERNAL && (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <AccessManagementUserFormIconButton
                                                            title={`Remove ${group.name} group access`}
                                                            icon="trash-can"
                                                            loading={removingGroupAccess === group.id}
                                                            onClick={() => {
                                                                setRemovingGroupAccess(group.id);
                                                                deleteUserGroupUsers({
                                                                    userGroupId: group.id,
                                                                    userEmails: [email]
                                                                })
                                                                    .then(() => {
                                                                        setRemovingGroupAccess(null);
                                                                        emitToast({
                                                                            message:
                                                                                "Group access successfully removed."
                                                                        });
                                                                        revalidate();
                                                                    })
                                                                    .catch((e) => {
                                                                        setRemovingGroupAccess(null);
                                                                        setError(
                                                                            `Failed to remove group access: ${e.message}`
                                                                        );
                                                                        datadogRum.addError(e);
                                                                        console.error(
                                                                            "Failed to remove group access: ",
                                                                            e
                                                                        );
                                                                    });
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                            </LimitByRole>
                                        </div>
                                    }
                                >
                                    <div className="push-triple--left">
                                        <InstanceRolesTable userGroup={group} instances={group.instances} />
                                    </div>
                                </Disclose>
                            </div>
                        );
                    })}
                </InfiniteScrollList>
            )}
        </div>
    );
};

UserGroupAccessList.displayName = "UserGroupAccessList";
