import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "@optimizely/axiom";
import classNames from "classnames";

import styles from "./NavigationBar.module.scss";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { useUserContext } from "../../../providers/UserProvider";
import { ACCESS_TYPES, ATTRIBUTE_ROLES } from "../../../constants";
import classnames from "classnames";
import { getSecondaryNavigation, isChildPath } from "../../../lib/navigation-helpers";
import { NavigationBarMenuItem } from "./NavigationBarMenuItem";

interface MenuItem {
    title: string;
    icon: string;
    href: string;
    isActive: boolean;
}

export const NavigationBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { enabled: usageBillingEnabled } = useFeatureFlag(Flags.SHOW_USAGE_AND_BILLING);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const { enabled: supportPageEnabled } = useFeatureFlag(Flags.ENABLE_SUPPORT_FEATURES);
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);
    const { enabled: showRolesTab } = useFeatureFlag(Flags.SHOW_ROLES_SIDEBAR_NAVIGATION);
    const { enabled: usersPageEnabled } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { enabled: settingsPagesEnabled } = useFeatureFlag(Flags.ENABLE_BILLING_NOTIFICATION_SETTINGS);

    const { canUserDoAction } = useUserContext();
    const showUserManager = canUserDoAction({
        action: ATTRIBUTE_ROLES.USERS.UPDATE,
        context: {
            scopedAccess: ACCESS_TYPES.PRODUCT
        }
    });

    const showGroupsTab = canUserDoAction({
        action: ATTRIBUTE_ROLES.GROUPS.CREATE,
        context: {
            scopedAccess: ACCESS_TYPES.PRODUCT
        }
    });

    const showUsageBillingPage =
        usageBillingEnabled &&
        canUserDoAction({
            action: ATTRIBUTE_ROLES.BILLING.READ,
            context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
        });

    const showSupportPage =
        supportPageEnabled &&
        canUserDoAction({
            action: ATTRIBUTE_ROLES.SETTINGS.UPDATE,
            context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
        });

    const showProductsPage = canUserDoAction({ action: ATTRIBUTE_ROLES.PRODUCTS.READ });
    const hasRolesPermissions = canUserDoAction({
        action: ATTRIBUTE_ROLES.ROLES.CREATE
    });

    const showSettingsPage = canUserDoAction({
        action: ATTRIBUTE_ROLES.SETTINGS.UPDATE,
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    });

    useEffect(() => {
        const items: MenuItem[] = [];

        if (accessManagementEnabled) {
            items.push({
                title: "Product Access",
                icon: "grid-thin",
                href: "/access",
                isActive: pathname.startsWith("/access")
            });
        } else if (showProductsPage) {
            items.push({
                title: "Products & Instances",
                icon: "grid-thin",
                href: "/entitlements",
                isActive: pathname.startsWith("/entitlements")
            });
        }

        if (showUserManager) {
            if (usersPageEnabled) {
                items.unshift({
                    title: "Users",
                    icon: "user",
                    href: "user-management/users",
                    isActive: pathname.startsWith("/user-management")
                });
            } else {
                items.push({
                    title: "Group Access",
                    icon: "user-group",
                    href: "/user-management/user-groups",
                    isActive: pathname.startsWith("/user-management")
                });
            }
        }

        if (usersPageEnabled && showGroupsTab) {
            items.push({
                title: "Group Access",
                icon: "user-group",
                href: "/groups",
                isActive: pathname.startsWith("/groups")
            });
        }

        if (showRolesTab && hasRolesPermissions) {
            items.push({
                title: "Roles",
                icon: "shield-check",
                href: "/roles",
                isActive: pathname.startsWith("/roles")
            });
        }

        if (showSupportPage) {
            items.push({
                title: "Support",
                icon: "rocket",
                href: "/support",
                isActive: pathname.startsWith("/support")
            });
        }

        if (showUsageBillingPage) {
            items.push({
                title: "Usage & Billing",
                icon: "chart-mixed-thin",
                href: "/usage",
                isActive: pathname.startsWith("/usage")
            });
        }

        if (showSettingsPage) {
            const settingsFeatureFlagBasedValues = settingsPagesEnabled
                ? { href: "/settings/authentication/sso", isActive: isChildPath({ pathname, path: "/settings/" }) }
                : { href: "/account-management", isActive: isChildPath({ pathname, path: "/account-management" }) };
            items.push({
                title: "Settings",
                icon: "gear-thin",
                ...settingsFeatureFlagBasedValues
            });
        }

        setMenuItems(items);
    }, [
        showUsageBillingPage,
        pathname,
        navigate,
        showSupportPage,
        showUserManager,
        showProductsPage,
        accessManagementEnabled,
        hasRolesPermissions,
        settingsPagesEnabled,
        showRolesTab,
        showSettingsPage,
        usersPageEnabled,
        showGroupsTab
    ]);

    const hasSecondaryNavigation = settingsPagesEnabled && !!getSecondaryNavigation({ path: pathname });

    const menuItemClasses = classnames(styles["navigation-bar__menu-item"], {
        [styles["navigation-bar__menu-item--collapsed"]]: hasSecondaryNavigation
    });

    return (
        <Sidebar
            className={styles["navigation-bar"]}
            anchor="left"
            border
            borderColor="#E8E8E8"
            backgroundColor="smoke"
            isOpen={!hasSecondaryNavigation} //TODO: enable this as part of https://jira.sso.episerver.net/browse/CSX-1281 (missing the pop-tips)
            collapsedWidth={60}
            isFullHeight
            width={208}
            docked
            sticky
        >
            <div className={classNames("soft-half--bottom", styles["navigation-bar__main"])}>
                {menuItems.map((item) => (
                    <NavigationBarMenuItem
                        {...item}
                        className={menuItemClasses}
                        showTooltip={hasSecondaryNavigation}
                        onClick={() => {
                            // Prop doesn't support args, so cannot use e.preventDefault(), but this also works
                            window.event?.preventDefault();
                            navigate(item.href);
                        }}
                    />
                ))}
            </div>
        </Sidebar>
    );
};

NavigationBar.displayName = "NavigationBar";
