import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { ProductInstancesTable } from "../../components/ProductInstancesTable/ProductInstancesTable";
import { PaginationControls, Typography } from "@optimizely/axiom";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { InstanceForm } from "../../components/InstanceForm/InstanceForm";
import { paginateArray } from "../../../lib/utils";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { ResetFilterPrompt } from "../../components/ResetFilterPrompt/ResetFilterPrompt";
import styles from "./ProductInstances.module.scss";
import { ADMINCENTER_PRODUCT_ID, DEFAULT_PAGE_SIZE } from "../../../constants";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";

export const ProductInstances = () => {
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);
    const { productId = "" } = useParams();
    const { organizationId } = useUserContext();
    const [currentInstance, setCurrentInstance] = useState<IOrganizationProductInstance | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");
    const { isLoading, products, updateInstance, revalidate } = useProducts({ organizationId });
    const productDetails = products?.find((p) => p.id === productId);
    const [instancePages, setInstancePages] = useState<{
        totalPages: number;
        total: number;
        pages: any;
    }>(paginateArray({ list: productDetails?.instances || [] }));

    const handlePagination = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (productId) {
            const newInstancePages = paginateArray({ list: productDetails?.instances || [] });
            setInstancePages(newInstancePages);
        }
    }, [productId, products, productDetails?.instances]);

    const filterInstances = ({ value }: { value: string }) => {
        let newInstancePages;
        if (!value) {
            newInstancePages = paginateArray({ list: productDetails?.instances || [] });
        } else {
            newInstancePages = paginateArray({
                list: productDetails?.instances || [],
                filter: { query: value, fields: ["name", "nickname"] }
            });
        }
        setInstancePages(newInstancePages);
        setFilterText(value);
    };

    const header = `${productDetails?.name || ""} Instances`;
    const { pages = [], total: totalInstances = 0, totalPages } = instancePages;
    const showPagination = totalInstances > DEFAULT_PAGE_SIZE;

    const setSidebarInstance = ({ instance }: { instance: IOrganizationProductInstance }) => {
        setCurrentInstance(instance);
    };

    const handleInstanceUpdate = ({ instance }: { instance: IOrganizationProductInstance }) => {
        return updateInstance({ instance });
    };

    const handleInstanceFormClose = ({ updatedInstance }: { updatedInstance?: IOrganizationProductInstance }) => {
        if (updatedInstance) {
            revalidate().then(() => {
                const pageItems = pages[currentPage - 1];
                const updatedIndex = pageItems.findIndex(
                    (i: IOrganizationProductInstance) => i.id === updatedInstance.id
                );
                pageItems[updatedIndex] = updatedInstance;
                setCurrentInstance(null);
            });
        } else {
            setCurrentInstance(null);
        }
    };

    const disableEditingForInstances = productDetails?.id === ADMINCENTER_PRODUCT_ID;

    if (accessManagementEnabled) return <Unauthorized />;

    return (
        <div className={styles["product-instances"]}>
            <Typography className="layout-header" tag="h1" type="header1">
                {header}
            </Typography>
            <div className={styles["product-instances__search"]}>
                <SearchInput placeholder="Filter by instance..." onChange={filterInstances} value={filterText} />
            </div>
            <ProductInstancesTable
                instances={pages[currentPage - 1]}
                loading={isLoading}
                onInstanceClick={setSidebarInstance}
            />
            {totalInstances === 0 && (
                <ResetFilterPrompt
                    onClick={() => filterInstances({ value: "" })}
                    prompt="No instances match your filter."
                />
            )}
            {showPagination && (
                <PaginationControls
                    className="anchor--bottom"
                    currentPage={currentPage}
                    goToPage={handlePagination}
                    totalPages={totalPages}
                />
            )}
            {currentInstance && (
                <Sidebar heading="Instance Details" isOpen onCancel={() => setCurrentInstance(null)}>
                    <InstanceForm
                        disableEditing={disableEditingForInstances}
                        instance={currentInstance}
                        onClose={({ updatedInstance }: { updatedInstance?: IOrganizationProductInstance }) =>
                            handleInstanceFormClose({ updatedInstance })
                        }
                        onSubmit={handleInstanceUpdate}
                    />
                </Sidebar>
            )}
        </div>
    );
};

ProductInstances.displayName = "ProductInstancesPage";
