import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { useForm, useFormState } from "react-hook-form";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { ATTRIBUTE_ROLES } from "../../../constants";
import { Attention, Button, Typography } from "@optimizely/axiom";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { IInstanceFormValues } from "../InstanceForm/IInstanceFormValues";
import LimitByRole from "../LimitByRole/LimitByRole";
import { OptiIDEnabledCheckbox } from "../OptiIDEnabledCheckbox/OptiIDEnabledCheckbox";
import { emitToast } from "../../../lib/toaster-utils";

export const AccessManagementInstanceDetails = () => {
    const { organizationId } = useUserContext();
    const { products, isLoading, error, updateInstance } = useProducts({ organizationId });
    const { instanceId } = useParams();

    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [optiEnabled, setOptiEnabled] = useState(false);

    const instance = products?.flatMap((p) => p.instances).find((inst) => inst.id === instanceId);

    const { register, handleSubmit, getValues, setValue, control } = useForm<IInstanceFormValues>({
        mode: "onChange",
        defaultValues: {
            nickname: instance?.nickname
        }
    });

    const { isDirty } = useFormState({
        control
    });

    const [technicalContact, setTechnicalContact] = useState({
        email: instance?.technicalContactEmail,
        name: instance?.technicalContactName
    });

    if (isLoading) return <LoadingIndicator />;
    if (error) return <div>Error fetching instance details.</div>;
    if (!instance) return <div>Error: instance not found in organization.</div>;

    const toggleOptiEnabled = () => {
        setOptiEnabled((curr) => {
            return !curr;
        });
    };

    const handleFormSubmission = async () => {
        if (!editing) {
            setEditing(true);
            setValue("nickname", instance?.nickname || instance?.name || "");
            return;
        }

        if (!instance) return;

        setLoading(true);
        const { nickname } = getValues();
        const updatedInstance: IOrganizationProductInstance = {
            ...instance,
            nickname,
            technicalContactEmail: technicalContact.email,
            technicalContactName: technicalContact.name
        };

        if (optiIdEnabled || optiEnabled) {
            updatedInstance["optiIdEnabled"] = true;
        } else {
            updatedInstance["optiIdEnabled"] = false;
        }

        return updateInstance({ instance: updatedInstance })
            ?.then(() => {
                setEditing(false);
                emitToast({ message: "This instance has been successfully updated." });
            })
            .catch((e: any) => {
                console.error(e);
                setErrorMessage("An error occurred. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let actionText = editing ? "Save" : "Edit";

    const formDirty = isDirty || technicalContact.email !== instance?.technicalContactEmail || optiEnabled;

    const { optiIdEnabled, nickname, name, technicalContactName, technicalContactEmail, id } = instance || {};

    const bothTechnicalContactFields = technicalContactEmail && technicalContactName;
    const contact = technicalContactName || technicalContactEmail || "None";
    const disableEditing = false;

    return (
        <form onSubmit={handleSubmit(handleFormSubmission)}>
            <div className="push-double--sides ">
                {!!errorMessage && (
                    <Attention
                        alignment="left"
                        className="push-triple--top"
                        type="bad-news"
                        onDismiss={() => setErrorMessage("")}
                    >
                        {errorMessage}
                    </Attention>
                )}
                <label className="oui-label push-double--top" htmlFor="role-description">
                    Name
                </label>
                {editing ? (
                    <input
                        aria-describedby="display-name"
                        className="oui-text-input"
                        placeholder="Display Name"
                        id="display-name"
                        disabled={loading}
                        type="text"
                        {...register("nickname")}
                    />
                ) : (
                    <Typography type="body" className="label--disabled">
                        {nickname || name}
                    </Typography>
                )}

                <label className="oui-label push-double--top" htmlFor="technical-contact-name">
                    Technical Contact
                </label>
                {editing ? (
                    <UserSearchSelector
                        placeholder={technicalContact.email}
                        onSelectUser={(user) => {
                            if (user) {
                                setTechnicalContact({
                                    email: user.email,
                                    name: `${user.firstName} ${user.lastName}`
                                });
                            }
                        }}
                        isUserDisabled={(user) => {
                            return user.email === technicalContactEmail;
                        }}
                    />
                ) : (
                    <>
                        <Typography type="body" className="label--disabled">
                            {bothTechnicalContactFields ? (
                                <>
                                    {technicalContactName} <br />
                                    {technicalContactEmail}
                                </>
                            ) : (
                                <>{contact}</>
                            )}
                        </Typography>
                    </>
                )}
                <OptiIDEnabledCheckbox
                    checked={optiIdEnabled || optiEnabled}
                    disabled={optiIdEnabled}
                    editing={editing}
                    onChange={toggleOptiEnabled}
                    showWarning={!optiIdEnabled}
                />
                <label className="oui-label push-double--top" htmlFor="instance-id">
                    Id
                </label>
                <Typography type="body" className="label--disabled">
                    {id}
                </Typography>
            </div>
            <div className="push-double--sides push-double--top">
                <LimitByRole action={ATTRIBUTE_ROLES.USERS.UPDATE} mode="disable">
                    <Button
                        key="edit-instance"
                        className="push--right"
                        // eslint-disable-next-line react/style-prop-object
                        style="highlight"
                        isLoading={editing && loading}
                        isDisabled={disableEditing || loading || (editing && !formDirty)}
                        loadingText="Saving"
                        isSubmit
                    >
                        {actionText}
                    </Button>
                    {editing && (
                        // eslint-disable-next-line react/style-prop-object
                        <Button key={0} isDisabled={loading} style="outline-reverse" onClick={() => setEditing(false)}>
                            Cancel
                        </Button>
                    )}
                </LimitByRole>
            </div>
        </form>
    );
};

AccessManagementInstanceDetails.displayName = "AccessManagementInstanceDetails";
