import { IAccessContext } from "../../services/UserService";
import { GROUP_TYPES } from "../../constants";
import { useEffect, useState } from "react";
import { useAttributes } from "../useAttributes/useAttributes";
import { useProducts } from "../useProducts/useProducts";
import { OrganizationPermission, ProductPermission } from "../../domain/Permission.interface";
import {
    filterPermissionListsForContext,
    getOrgPermissionsFromGroupList,
    getProjects,
    orgPermissionListToAccessList
} from "../../services/AccessListTransforms";
import { useContextUserGroups } from "../useContextUserGroups/useContextUserGroups";

export const useInvitationGroupRoles = ({
    organizationId,
    context
}: {
    organizationId?: string;
    context?: IAccessContext;
}): { availableGroupRoles: ProductPermission[] | undefined; isLoading?: boolean; error: Error | undefined } => {
    const { products } = useProducts({ organizationId, includeServices: true });
    const { getExperimentationProjects } = useAttributes({});
    const [availableGroupRoles, setAvailableGroupRoles] = useState<ProductPermission[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean | undefined>();

    const { groups: userGroups, error } = useContextUserGroups({
        organizationId,
        context,
        groupTypes: [GROUP_TYPES.PRODUCT]
    });

    useEffect(() => {
        if (userGroups && context && products && !error) {
            setIsLoading(true);
            let orgPermissions: OrganizationPermission[] | undefined = getOrgPermissionsFromGroupList(
                userGroups,
                products
            );

            const promiseList = getProjects(orgPermissions, getExperimentationProjects);

            //wait until all projects are loaded (if any) before creating the access map.
            Promise.all(promiseList).then(() => {
                //filter by context scope and items
                orgPermissions = filterPermissionListsForContext(context, orgPermissions);

                //turn into nested product/instance/project list
                const list = orgPermissionListToAccessList(orgPermissions);

                if (list) setAvailableGroupRoles(list);
                setIsLoading(false);
            });
        }
    }, [context, userGroups, getExperimentationProjects, products, error]);

    return { availableGroupRoles, isLoading: (!userGroups && !error) || isLoading, error };
};
