import { useUserContext } from "../../providers/UserProvider";
import { useSession } from "../useSession/useSession";
import { useOrganization } from "../useOrganization/useOrganization";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";

type TrackingComponent =
    | "User Sidebar"
    | "Invitation Sidebar"
    | "Update Group Sidebar"
    | "Create Group Sidebar"
    | "Session";
type TrackingFlow = "User Page" | "Product Access" | "Group Access" | "Login";

type TrackingData = {
    productName: string;
    subProductName: string;
    userId: string;
    groupId: string;
    isOptiUser: boolean;
    component: TrackingComponent;
    flow: TrackingFlow;
    path: string;
};

export const useAnalyticsTracking = () => {
    const { organizationId, currentInstance, profile } = useUserContext();
    const { user } = useSession();
    const { organization } = useOrganization({ id: organizationId });
    const location = useLocation();

    const isOptiUser = useMemo(() => {
        return (
            user?.email?.toLowerCase().includes("@optimizely.com") ||
            user?.email?.toLowerCase().includes("@episerver.net") ||
            false
        );
    }, [user?.email]);

    const sendPageEvent = useCallback(() => {
        if (currentInstance?.productName && location?.pathname) {
            window.analytics.page(location?.pathname, {
                groupId: organization?.crmId,
                subProductName: currentInstance?.productName
            });
        }
    }, [organization?.crmId, location?.pathname, currentInstance?.productName]);

    const sendIdentifyEvent = useCallback(() => {
        if (user?.email && organization?.crmId && profile && currentInstance) {
            window.analytics.identify(user?.email, {
                userId: user?.email,
                groupId: organization?.crmId,
                organizationId,
                instanceId: currentInstance.groupId,
                instanceName: currentInstance.instanceName,
                name: `${profile?.firstName} ${profile?.lastName}`,
                isOptiUser
            });
        }
    }, [user?.email, currentInstance, isOptiUser, organization?.crmId, organizationId, profile]);

    const sendGroupEvent = useCallback(() => {
        if (organization?.crmId && currentInstance) {
            window.analytics.group(organization?.crmId, {
                groupId: organization?.crmId,
                organizationId,
                instanceId: currentInstance.groupId,
                instanceName: currentInstance.instanceName,
                name: currentInstance.instanceName,
                hasTurnstile: true
            });
        }
    }, [currentInstance, organization?.crmId, organizationId]);

    const sendTrackEvent = useCallback(
        ({
            name,
            component,
            flow,
            data
        }: {
            name: string;
            component: TrackingComponent;
            flow: TrackingFlow;
            data: any;
        }) => {
            const trackingData: TrackingData = {
                productName: "Admin Center",
                subProductName: "Admin Center",
                userId: user?.email || profile?.email,
                groupId: organization?.crmId,
                isOptiUser,
                component,
                flow,
                path: location.pathname,
                ...data
            };

            window.analytics.track(name, trackingData);
        },
        [isOptiUser, location.pathname, organization?.crmId, user?.email, profile?.email]
    );

    return {
        sendTrackEvent,
        sendGroupEvent,
        sendIdentifyEvent,
        sendPageEvent
    };
};
