import { useServicesContext } from "../../providers/ServicesProvider";
import { ProductAttribute } from "../../domain/ProductAttribute";
import { useInfiniteList } from "../useInfiniteList/useInfiniteList";
import { ALL_PAGES_SIZE, EXP_PROJECT_TYPES, EXP_ROLE_PERMISSION_MAPPING } from "../../constants";
import { useCallback } from "react";

export const useAttributes = ({
    isActive,
    key,
    productId,
    instanceId,
    size = 100,
    permissionType,
    nameQuery
}: {
    isActive?: boolean;
    instanceId?: string;
    key?: string;
    offset?: number;
    productId?: string;
    size?: number;
    permissionType?: string;
    nameQuery?: string;
}) => {
    const { attributeService } = useServicesContext();

    const {
        allData: data,
        isLoadingInitialData,
        isLoadingMore,
        error,
        loadNext
    } = useInfiniteList<ProductAttribute>(
        productId
            ? `/api/attributes?productId=${productId}&instanceId=${instanceId}&isActive=${isActive}&key=${key}&type=${permissionType}&name=${nameQuery}`
            : null,
        (fetcherKey, page: number) => {
            // Do not make the call for experimentation product if instanceId is not specified as that will return all the experimentation projects in the system
            // regardless of the organization.

            const isExperimentationProduct = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
            const shouldSkip = isExperimentationProduct && !instanceId;
            if (shouldSkip) {
                return Promise.resolve({ items: [], totalCount: 0 });
            }

            return attributeService!.getAttributes({
                instanceId,
                isActive,
                key,
                offset: size * (page - 1),
                productId,
                size,
                types: permissionType ? EXP_ROLE_PERMISSION_MAPPING[permissionType] : [],
                name: nameQuery
            });
        }
    );

    // GET request, not bound to SWR hook
    const getExperimentationProjects = useCallback(
        async ({
            instanceId,
            permissionType,
            projectKeys = []
        }: {
            instanceId: string;
            permissionType?: string;
            projectKeys?: string[];
        }) => {
            const { items: projects = [] } = await attributeService!.getAttributes({
                instanceId,
                productId: process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID,
                types: permissionType
                    ? EXP_ROLE_PERMISSION_MAPPING[permissionType]
                    : [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY, EXP_PROJECT_TYPES.FEATURE],
                size: ALL_PAGES_SIZE,
                offset: 0
            });

            return projectKeys.length
                ? projects?.filter((p: any) => {
                      return projectKeys.indexOf(p.key) > -1;
                  })
                : projects;
        },
        [attributeService]
    );

    // GET request, not bound to SWR hook
    const getAttribute = useCallback(
        async (attributeId: string) => {
            return await attributeService!.getAttribute(attributeId);
        },
        [attributeService]
    );

    return {
        attributes: data,
        getExperimentationProjects,
        getAttribute,
        isLoadingInitialData,
        isLoadingMore,
        loadNext,
        error
    };
};
