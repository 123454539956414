import React, { useEffect, useState } from "react";
import { Attention, Checkbox, Dropdown, ProgressDots, Spinner, Typography } from "@optimizely/axiom";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";
import { CheckboxList } from "../CheckboxList/CheckboxList";
import styles from "./SelectRoleProjects.module.scss";
import classNames from "classnames";
import { InfiniteScrollList } from "../InfiniteScrollList/InfiniteScrollList";
import { EXP_PERMISSIONS_KEY, EXP_PROJECT_OWNER_PERMISSION } from "../../../constants";
import { SearchInput } from "../SearchInput/SearchInput";
import { ProductAttribute } from "../../../domain/ProductAttribute";

type SelectRoleProjectsProps = {
    onChange: ({
        preMappedValues,
        id,
        key,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => void;
    productId?: string;
    instanceId?: string;
    selectedAttributes?: RoleAttribute[];
    rolePermissionValue?: string;
};
export const SelectRoleProjects = ({
    onChange,
    productId,
    instanceId,
    selectedAttributes = [],
    rolePermissionValue = ""
}: SelectRoleProjectsProps) => {
    const [permissionType, setPermissionType] = useState<string | undefined>();
    const [showArchivedProjects, setShowArchivedProjects] = useState(false);
    const [projectSearchQuery, setProjectSearchQuery] = useState<string>("");
    const [projectList, setProjectList] = useState<ProductAttribute[] | undefined>(undefined);

    const { attributes: expPermissions, isLoadingInitialData: isLoadingPermissions } = useAttributes({
        productId,
        isActive: true,
        key: EXP_PERMISSIONS_KEY
    });

    const {
        attributes: projectAttributes,
        isLoadingInitialData: isLoadingProjectAttributes,
        isLoadingMore,
        loadNext: loadNextProjects
    } = useAttributes({
        productId: permissionType && productId,
        instanceId,
        isActive: showArchivedProjects ? undefined : true,
        permissionType,
        nameQuery: projectSearchQuery
    });

    const listOfProjects = projectList?.map((p) => {
        return {
            text: p.isActive ? `${p.name}` : `${p.name} (Archived)`,
            value: p.key,
            checked: !!selectedAttributes.find((a) => a.id === p.id)
        };
    });

    useEffect(() => {
        if (!isLoadingProjectAttributes) {
            setProjectList(projectAttributes);
        }
    }, [projectAttributes, isLoadingProjectAttributes]);

    useEffect(() => {
        const existingPermissionSelected = selectedAttributes.find((a) => a.key === "permissions");
        if (existingPermissionSelected && existingPermissionSelected.values?.length) {
            setPermissionType(existingPermissionSelected.values[0]);
        }
    }, [selectedAttributes]);

    if (!productId || !instanceId) {
        return (
            <div className="push-triple--top">
                <Typography type="body">Select a product and instance to see available projects.</Typography>
            </div>
        );
    }

    if (isLoadingPermissions) {
        return (
            <div className="push-triple--top flex justify-content-center">
                <Spinner />
            </div>
        );
    }

    const disablePermissionDropdown = rolePermissionValue?.toLowerCase() === EXP_PROJECT_OWNER_PERMISSION.toLowerCase();

    return (
        <>
            <div className="border--bottom">Permissions</div>
            <Dropdown
                buttonContent={permissionType || "Select permission..."}
                arrowIcon="down"
                fullWidth
                className={styles["permission__dropdown"]}
                isDisabled={disablePermissionDropdown}
            >
                <Dropdown.Contents>
                    {expPermissions &&
                        expPermissions[0]?.values.map((value) => {
                            return (
                                <Dropdown.ListItem key={expPermissions[0].key + value}>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            setPermissionType(value);
                                            setProjectSearchQuery("");

                                            onChange({
                                                preMappedValues: true,
                                                values: [
                                                    {
                                                        id: expPermissions[0].id,
                                                        key: EXP_PERMISSIONS_KEY,
                                                        values: [value]
                                                    }
                                                ]
                                            });
                                        }}
                                    >
                                        {value}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                </Dropdown.Contents>
            </Dropdown>

            {permissionType && expPermissions ? (
                <>
                    <div className={classNames("border--bottom push--bottom", styles["permission__project-header"])}>
                        <div>Projects</div>
                        <div>
                            <Checkbox
                                checked={showArchivedProjects}
                                label="Show Archived"
                                onChange={() => setShowArchivedProjects(!showArchivedProjects)}
                            />
                        </div>
                    </div>
                    <SearchInput
                        placeholder="Search projects..."
                        type="text"
                        value={projectSearchQuery}
                        onChange={({ value }) => {
                            setProjectSearchQuery(value);
                        }}
                    />
                    {(listOfProjects?.length || isLoadingProjectAttributes) && projectAttributes ? (
                        <InfiniteScrollList
                            isLoadingMore={isLoadingMore}
                            loadNext={loadNextProjects}
                            loadingItem={<ProgressDots />}
                        >
                            <CheckboxList
                                items={listOfProjects}
                                onChange={({ values }) => {
                                    onChange({
                                        preMappedValues: true,
                                        values: [
                                            {
                                                id: expPermissions[0].id,
                                                key: EXP_PERMISSIONS_KEY,
                                                values: [permissionType || ""]
                                            },
                                            ...projectAttributes
                                                .filter((p) => values.find((v) => v === p.key))
                                                .map((pa) => {
                                                    return {
                                                        id: pa.id,
                                                        key: pa.key,
                                                        values: pa.values
                                                    };
                                                })
                                        ]
                                    });
                                }}
                            />
                        </InfiniteScrollList>
                    ) : (
                        <Attention alignment="left" className="push-triple--top" type="warning">
                            No projects match your search.
                        </Attention>
                    )}
                </>
            ) : (
                <Attention alignment="left" className="push-triple--top" type="warning">
                    There are no available projects for the permission selected.
                </Attention>
            )}
        </>
    );
};

SelectRoleProjects.displayName = "SelectRoleProjects";
