/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from "react";
import {
    Attention,
    Button,
    ButtonIcon,
    Checkbox,
    LayoutGrid,
    LayoutGridCell,
    LayoutGridContainer,
    Table,
    Typography
} from "@optimizely/axiom";
import { useForm, useFormState } from "react-hook-form";

import { Invitation } from "../../../domain/Invitation";
import {
    ATTRIBUTE_ROLES,
    EVERYONE_GROUP_NAME,
    GROUP_TYPES,
    INVITATION_ERROR_MESSAGE,
    INVITATION_STATUS,
    INVITATION_SUCCESS_MESSAGE
} from "../../../constants";
import { adaptApiErrors, IApiError } from "../../../services/ErrorMessageAdapter";
import styles from "./InvitationForm.module.scss";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useUserContext } from "../../../providers/UserProvider";
import { useFormContext } from "../UserForm/UserFormContext/UserFormContext";
import { getFormattedDate } from "../../../lib/date-formatter";
import { useOrgUserGroups } from "../../../hooks/useOrgUserGroups/useOrgUserGroups";
import { FilterDropdown } from "../FilterDropdown/FilterDropdown";
import { isEmail } from "../../../lib/validators";
import LimitByRole from "../LimitByRole/LimitByRole";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";
import { emitToast } from "../../../lib/toaster-utils";

interface IInvitationFormValues {
    firstName: string;
    lastName: string;
    email: string;
    apiError: { message: string };
}

interface IInvitationCreateProps {
    hideGroupSelection?: boolean;
    onCancel: () => void;
    onCreate: ({ newInvitation }: { newInvitation: Invitation | null }) => Promise<boolean>;
    onRevoke?: ({ invitationId }: { invitationId: string }) => void;
    onResend?: ({ invitationId }: { invitationId: string }) => void;
}

export const InvitationForm = ({
    hideGroupSelection,
    onCancel,
    onCreate,
    onRevoke,
    onResend
}: IInvitationCreateProps) => {
    const { organizationId, profile } = useUserContext();
    const { userGroups: orgUserGroups = [] } = useOrgUserGroups({
        organizationId
    });
    const { invitationState, updateInvitationState } = useFormContext();
    const { invitation, userGroups: selectedUserGroups } = invitationState;
    const { organization, loading: organizationLoading } = useOrganization({ id: organizationId });

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        control,
        formState: { errors }
    } = useForm<IInvitationFormValues>({
        mode: "onChange"
    });
    const { isSubmitting } = useFormState({
        control
    });

    const showReqAcceptanceChkBox = !organizationLoading && !organization?.ssoEnabled && !invitation;
    const [requireAcceptance, setRequireAcceptance] = useState<boolean>(true);
    const [saving, setSaving] = useState(false);
    const [selectedUserGroupId, setSelectedUserGroupId] = useState<string | undefined>();
    const everyoneGroup = orgUserGroups.find((ug) => ug.name === EVERYONE_GROUP_NAME);

    useEffect(() => {
        if (everyoneGroup && !invitation) {
            //automatically add 'Everyone' group to the state for a new user
            updateInvitationState({ userGroups: [everyoneGroup] });
        } else {
        }
    }, [updateInvitationState, everyoneGroup, invitation]);

    const addUserGroup = useCallback(
        ({ userGroupId }: { userGroupId: string }) => {
            const addedGroup = orgUserGroups?.find((ug) => ug.id === userGroupId);
            updateInvitationState({
                userGroups: [addedGroup, ...selectedUserGroups]
            });
            setSelectedUserGroupId(undefined);
        },
        [orgUserGroups, selectedUserGroups, updateInvitationState]
    );

    useEffect(() => {
        if (selectedUserGroupId) {
            addUserGroup({
                userGroupId: selectedUserGroupId
            });
        }
    }, [selectedUserGroupId, addUserGroup]);

    const removeUserGroup = ({ index }: { index: number }) => {
        const updatedGroups = [...selectedUserGroups];
        updatedGroups.splice(index, 1);
        updateInvitationState({ userGroups: updatedGroups });
    };

    const createNewInvitation = () => {
        const { firstName, lastName, email } = getValues();

        return new Invitation({
            firstName,
            lastName,
            email,
            requireAcceptance: organization?.ssoEnabled ? false : requireAcceptance,
            organizationId: organizationId!,
            userGroups: selectedUserGroups.map((ug) => {
                const { id, name } = ug;
                return { id, name };
            }),
            createdBy: profile?.email!,
            created: new Date(),
            modified: new Date()
        });
    };

    const handleError = (apiErrors: IApiError[] | Error) => {
        if (Array.isArray(apiErrors)) {
            const errors = adaptApiErrors(apiErrors);
            errors.forEach((error) => {
                setError(error.name as any, {
                    type: "individualFieldApiError",
                    message: error.message
                });
            });
        } else {
            setError("apiError", {
                type: "apiError",
                message: INVITATION_ERROR_MESSAGE
            });
        }
    };

    const handleFormSubmission = async () => {
        const newInvitation = createNewInvitation();
        setSaving(true);
        onCreate({ newInvitation })
            .then(async () => {
                emitToast({ message: INVITATION_SUCCESS_MESSAGE });
            })
            .catch(handleError)
            .finally(() => {
                setSaving(false);
            });
    };

    let action = "Send";
    let statusDate: Date | undefined;
    if (invitation) {
        if (invitation.status === INVITATION_STATUS.PENDING) {
            action = "Revoke";
        } else if (invitation.status === INVITATION_STATUS.EXPIRED) {
            action = "Resend";
            statusDate = invitation.expiredDate;
        } else if (invitation.status === INVITATION_STATUS.ACCEPTED) {
            action = "";
            statusDate = invitation.acceptedDate;
        } else {
            statusDate = invitation.revokedDate;
            action = "";
        }
    }

    if (errors.apiError && isSubmitting) {
        clearErrors("apiError");
    }

    const userGroupDropdownItems = orgUserGroups
        ?.filter(
            (ug) =>
                !selectedUserGroups?.find((selectedGroup) => selectedGroup.id === ug.id) &&
                ug.groupType !== GROUP_TYPES.INTERNAL
        )
        .map((ug) => {
            return {
                label: ug.name,
                description: ug.description,
                key: ug.id
            };
        });

    let statusType: "bad-news" | "good-news" | "warning" = "bad-news";
    if (invitation?.status === INVITATION_STATUS.PENDING) statusType = "warning";
    if (invitation?.status === INVITATION_STATUS.ACCEPTED) statusType = "good-news";

    return (
        <form className="flex flex--column user-form" onSubmit={handleSubmit(handleFormSubmission)}>
            {errors.apiError && (
                <Attention alignment="left" className="push--top push-quad--bottom push-quad--sides" type="bad-news">
                    {errors.apiError.message}
                </Attention>
            )}
            <div className={styles["invitation-form__content"]}>
                {/* since we have so many conditional renders dependent on existing invitation state, I'm planning on refactoring this out the new invitation form to its own component - AZ */}
                {invitation && (
                    <Attention
                        alignment="left"
                        className="push--top push-double--bottom push-quad--sides"
                        type={statusType}
                    >
                        <span>
                            <strong>Status:</strong> {invitation?.status}
                            {statusDate &&
                                ` - ${getFormattedDate({
                                    date: statusDate,
                                    hideTimestamp: true
                                })}`}
                        </span>
                    </Attention>
                )}

                <div className={`push-quad--bottom soft-quad--sides ${errors.firstName && "oui-form-bad-news"}`}>
                    {invitation ? (
                        <>
                            <label className="oui-label">First Name</label>
                            <Typography type="body" className="label--disabled">
                                {invitation.firstName}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <label className="oui-label" htmlFor="invitation-first-name">
                                First Name
                                <span aria-label="(required)" className="oui-label--required" />
                            </label>

                            <input
                                aria-describedby="invitation-first-name-error"
                                className="oui-text-input"
                                id="invitation-first-name"
                                type="text"
                                {...register("firstName", { required: true })}
                            />
                            {errors.firstName && (
                                <span className="oui-form-note" id="invitation-first-name-error">
                                    First name is required
                                </span>
                            )}
                        </>
                    )}
                </div>

                <div className={`push-quad--bottom soft-quad--sides ${errors.lastName && "oui-form-bad-news"}`}>
                    {invitation ? (
                        <>
                            <label className="oui-label">Last Name</label>
                            <Typography type="body" className="label--disabled">
                                {invitation.lastName}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <label className="oui-label" htmlFor="invitation-last-name">
                                Last Name
                                <span aria-label="(required)" className="oui-label--required" />
                            </label>

                            <input
                                aria-describedby="invitation-last-name-error"
                                className="oui-text-input"
                                id="invitation-last-name"
                                type="text"
                                {...register("lastName", { required: true })}
                            />
                            {errors.lastName && (
                                <span className="oui-form-note" id="invitation-last-name-error">
                                    Last name is required
                                </span>
                            )}
                        </>
                    )}
                </div>

                <div className={`push-quad--bottom soft-quad--sides ${errors.email && "oui-form-bad-news"}`}>
                    {invitation ? (
                        <>
                            <label className="oui-label">Email</label>
                            <Typography type="body" className="label--disabled">
                                {invitation.email}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <label className="oui-label" htmlFor="invitation-email">
                                Email
                                <span aria-label="(required)" className="oui-label--required" />
                            </label>

                            <input
                                aria-describedby="invitation-email-error"
                                className="oui-text-input"
                                id="invitation-email"
                                type="email"
                                {...register("email", {
                                    validate: { isEmail },
                                    required: { value: true, message: "Email is required" }
                                })}
                            />
                            {errors.email && (
                                <span className="oui-form-note" id="invitation-email-error">
                                    {errors.email.message}
                                </span>
                            )}
                        </>
                    )}
                </div>

                {showReqAcceptanceChkBox && (
                    <div className="push-quad--bottom soft-quad--sides">
                        <Checkbox
                            checked={requireAcceptance}
                            label="Require Acceptance"
                            description="The user must accept the invitation sent to their inbox"
                            onChange={() => setRequireAcceptance(!requireAcceptance)}
                        />
                    </div>
                )}

                {invitation && (
                    <div className="push-quad--bottom soft-quad--sides">
                        <span className="oui-label push--top">Groups</span>
                        {invitation.userGroups.map((userGroup) => {
                            return (
                                <div key={userGroup.id}>
                                    <Typography type="body" className="label--disabled">
                                        {userGroup.name}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                )}

                {!(invitation || hideGroupSelection) && (
                    <LayoutGridContainer className={`${styles["invitations-form__groups"]} soft-quad--sides`}>
                        <LayoutGrid className="push-quad--bottom">
                            <LayoutGridCell large={12} medium={8} small={4} xlarge={12}>
                                <Typography type="header3">Add Groups</Typography>
                            </LayoutGridCell>

                            <LayoutGridCell large={12} medium={8} small={4} xlarge={12}>
                                <FilterDropdown
                                    filterPlaceholder="Search User Groups"
                                    dropdownPlaceholder="Select a group..."
                                    items={userGroupDropdownItems || []}
                                    onItemSelected={({ item }) => {
                                        if (item) {
                                            setSelectedUserGroupId(item.key);
                                        }
                                    }}
                                />
                            </LayoutGridCell>

                            <LayoutGridCell large={12} medium={8} small={4}>
                                <Table
                                    className="push--top"
                                    // eslint-disable-next-line react/style-prop-object
                                    style="rule"
                                    tableLayoutAlgorithm="auto"
                                >
                                    <Table.THead>
                                        <Table.TR>
                                            <Table.TH> Name </Table.TH>
                                            <Table.TH> </Table.TH>
                                        </Table.TR>
                                    </Table.THead>
                                    <Table.TBody>
                                        {selectedUserGroups?.map((userGroup, userGroupIndex) => (
                                            <Table.TR key={userGroup.id}>
                                                <Table.TD> {userGroup.name} </Table.TD>
                                                <Table.TD textAlign="right">
                                                    {/* eslint-disable react/style-prop-object */}
                                                    {userGroup.name !== EVERYONE_GROUP_NAME && (
                                                        <ButtonIcon
                                                            iconName="trash-can"
                                                            size="small"
                                                            style="plain"
                                                            onClick={() =>
                                                                removeUserGroup({
                                                                    index: userGroupIndex
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </Table.TD>
                                            </Table.TR>
                                        ))}
                                    </Table.TBody>
                                </Table>
                            </LayoutGridCell>
                        </LayoutGrid>
                    </LayoutGridContainer>
                )}
            </div>

            <SidebarFooter onCancel={onCancel}>
                <>
                    {action === "Send" && (
                        <Button
                            key="save-user-button"
                            isLoading={saving}
                            loadingText="Saving"
                            style="highlight"
                            isSubmit
                        >
                            {action}
                        </Button>
                    )}

                    {action === "Revoke" && invitation?.id && (
                        <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.INVITATIONS.UPDATE}>
                            <Button
                                key="revoke-user-button"
                                style="danger"
                                onClick={async () => {
                                    onRevoke && onRevoke({ invitationId: invitation.id! });
                                }}
                            >
                                {action}
                            </Button>
                        </LimitByRole>
                    )}

                    {action === "Resend" && invitation?.id && (
                        <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.INVITATIONS.UPDATE}>
                            <Button
                                key="resend-user-button"
                                style="highlight"
                                onClick={async () => {
                                    onResend && onResend({ invitationId: invitation.id! });
                                }}
                            >
                                {action}
                            </Button>
                        </LimitByRole>
                    )}
                </>
            </SidebarFooter>
        </form>
    );
};

InvitationForm.displayName = "InvitationForm";
