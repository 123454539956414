import { Dropdown, Spinner } from "@optimizely/axiom";
import React from "react";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import classnames from "classnames";

type InstanceDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    instances: IOrganizationProductInstance[];
    loading?: boolean;
    multiselect?: boolean;
    onAllClick?: () => void;
    onChange: ({ value }: { value: IOrganizationProductInstance | IOrganizationProductInstance[] | null }) => void;
    placeholder?: string;
    value?: string | string[];
    white?: boolean;
};
export const InstanceDropdown = ({
    className,
    disabled,
    fullWidth,
    instances,
    loading,
    multiselect = false,
    onAllClick,
    onChange,
    placeholder = "Select Instance...",
    value,
    white
}: InstanceDropdownProps) => {
    const instancesFromValue = instances?.filter((i) =>
        Array.isArray(value) ? value.indexOf(i.id) > -1 : i.id === value
    );

    const handleInstanceSelection = ({ instance }: { instance: IOrganizationProductInstance }) => {
        if (multiselect) {
            const updated = [...instancesFromValue];
            const existingIndex = updated?.findIndex((i) => i.id === instance.id);
            if (existingIndex > -1) {
                updated.splice(existingIndex, 1);
            } else {
                updated.push(instance);
            }
            onChange({ value: updated });
        } else {
            onChange({ value: instance });
        }
    };

    const singleInstance = instancesFromValue[0];
    const noInstances = !instances?.length;
    let buttonContent: string | React.ReactNode = singleInstance?.nickname || singleInstance?.name || placeholder;
    if (loading) {
        buttonContent = (
            <>
                <Spinner size="small" /> Loading instances...
            </>
        );
    } else if (noInstances && !disabled) {
        buttonContent = "No instances available.";
    }

    return (
        <Dropdown
            arrowIcon="down"
            buttonContent={buttonContent}
            className={classnames(className, "text--left")}
            fullWidth={fullWidth}
            isDisabled={disabled || noInstances}
            style={white ? "outline" : undefined}
            whiteBackground={white}
        >
            <Dropdown.Contents style={{ width: "100%" }}>
                {!!onAllClick && (
                    <Dropdown.ListItem role="option">
                        <Dropdown.BlockLink isLink onClick={onAllClick}>
                            <Dropdown.BlockLinkText text="All" />
                        </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                )}
                {instances?.map((productInstance) => {
                    const { id: instanceId, name, nickname } = productInstance;
                    const inSelected = instancesFromValue?.find((i) => i.id === instanceId);
                    return (
                        <Dropdown.ListItem key={instanceId} role="option">
                            <Dropdown.BlockLink
                                isLink={!inSelected}
                                isMultiSelect={multiselect}
                                isItemSelected={!!inSelected}
                                onClick={() => handleInstanceSelection({ instance: productInstance })}
                            >
                                <Dropdown.BlockLinkText text={nickname || name} />
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

InstanceDropdown.displayName = "InstanceDropdown";
