import { useUserContext } from "../../../../providers/UserProvider";
import { useNotifications } from "../../../../hooks/useNotifications/useNotifications";
import { BillingNotification } from "../../../../rest-model/BillingNotification";
import { BillingNotificationToggle } from "../../../components/BillingNotificationToggle/BillingNotificationToggle";
import { useState } from "react";
import { emitToast } from "../../../../lib/toaster-utils";
import { Attention } from "@optimizely/axiom";
import { NotificationRecipientsSheet } from "../../../components/NotificationRecipientsSheet/NotificationRecipientsSheet";
import { getNotificationTitle } from "../../../../lib/notification-utils";
import { User } from "../../../../domain/User";

export const BillingNotificationsPage = () => {
    const { organizationId } = useUserContext();
    const { billingNotifications = [], loading, updateBillingNotification } = useNotifications({ organizationId });
    const [updating, setUpdating] = useState<number | null>(null);
    const [error, setError] = useState(null);
    const [recipientNotification, setRecipientNotification] = useState<null | BillingNotification>(null);

    const handleToggle = ({
        notification,
        notificationIndex
    }: {
        notification: BillingNotification;
        notificationIndex: number;
    }) => {
        setUpdating(notificationIndex);
        setError(null);
        updateBillingNotification({
            organizationId: organizationId!,
            notificationId: notification.id,
            updates: {
                enabled: !notification.enabled
            }
        })
            .then(() => {
                emitToast({ message: "Billing notification successfully updated." });
            })
            .catch((error) => {
                setError(error?.message || error);
            })

            .finally(() => setUpdating(null));
    };

    const updateNotificationRecipients = ({ recipients }: { recipients: User[] }) => {
        const emails = recipients?.map((user) => user.email);
        return updateBillingNotification({
            organizationId: organizationId!,
            notificationId: recipientNotification!.id,
            updates: {
                recipients: emails
            }
        }).then(() => {
            emitToast({ message: "Successfully saved billing recipients." });
            closeRecipientSheet();
        });
    };

    const closeRecipientSheet = () => {
        setRecipientNotification(null);
    };

    return (
        <>
            {!!error && (
                <Attention type="bad-news" className="push-double--top">
                    {error}
                </Attention>
            )}
            {billingNotifications?.map((notification: BillingNotification, notificationIndex) => {
                return (
                    <BillingNotificationToggle
                        loading={updating === notificationIndex}
                        disabled={loading || updating !== null}
                        key={notificationIndex}
                        notification={notification}
                        onManageClick={() => {
                            setRecipientNotification(notification);
                        }}
                        onToggle={() => {
                            handleToggle({ notification, notificationIndex });
                        }}
                    />
                );
            })}
            {!!recipientNotification && (
                <NotificationRecipientsSheet
                    onClose={closeRecipientSheet}
                    onSave={updateNotificationRecipients}
                    searchHeading="Select users to receive billing notifications"
                    subTitle={getNotificationTitle(recipientNotification)}
                    title="Billing Notification Recipients"
                    recipientEmails={recipientNotification.recipients}
                />
            )}
        </>
    );
};

BillingNotificationsPage.displayName = "BillingNotificationsPage";
