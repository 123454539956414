import React from "react";
import { Dropdown } from "@optimizely/axiom";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";

type ProductDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    onChange: ({ product }: { product: OrganizationProduct }) => void;
    placeholder?: string;
    products: OrganizationProduct[];
    value?: string;
    white?: boolean;
    width?: string | number; // dropdown component does not respect the className width helpers. DUMB.
};
export const ProductDropdown = ({
    className,
    disabled = false,
    fullWidth = false,
    onChange,
    placeholder = "Select product...",
    products = [],
    value,
    white = false // width <- this doesn't work, posted on the axiom channel...
}: ProductDropdownProps) => {
    const selectedProduct = products?.find((p) => p.id === value);
    return (
        <Dropdown
            className={className}
            isDisabled={disabled}
            buttonContent={selectedProduct?.name || placeholder}
            arrowIcon="down"
            fullWidth={fullWidth}
            style={white ? "outline" : undefined}
            whiteBackground={white}
        >
            <Dropdown.Contents>
                {products.map((product) => {
                    return (
                        <Dropdown.ListItem key={product.id} className={className}>
                            <Dropdown.BlockLink
                                isItemSelected={value === product.id}
                                onClick={() => {
                                    onChange({ product });
                                }}
                            >
                                {product.name}
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

ProductDropdown.displayName = "ProductDropdown";
