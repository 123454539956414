/* eslint-disable react/style-prop-object */
import { Attention, Button, Sheet } from "@optimizely/axiom";
import React, { useState } from "react";
import { emptyForm, SSOOIDCFormFields } from "./SSOOIDCFormFields/SSOOIDCFormFields";
import { OIDC, SAML, UNEXPECTED_ERROR } from "../../../../constants";
import { IOIDCIdp, OIDCIdpWithoutId } from "../../../../domain/IdentityProvider";
import { datadogRum } from "@datadog/browser-rum";
import { ISSOSamlFormFields, SSOSamlFormFields } from "../SSOSamlForm/SSOSamlFormFields/SSOSamlFormFields";
import { emitToast } from "../../../../lib/toaster-utils";

type SSOConnectionSetupFormProps = {
    connectionType: "OIDC" | "SAML";
    loading?: boolean;
    onClose: () => void;
    onSubmit: ({ idp }: { idp: OIDCIdpWithoutId | ISSOSamlFormFields }) => Promise<void>;
};
export const SSOConnectionSetupFormSheet = ({ connectionType, onClose, onSubmit }: SSOConnectionSetupFormProps) => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState<OIDCIdpWithoutId | ISSOSamlFormFields>(
        emptyForm as OIDCIdpWithoutId | ISSOSamlFormFields
    );
    const [error, setError] = useState(null);

    const handleSave = () => {
        setLoading(true);
        setError(null);
        onSubmit({ idp: formValues })
            .then(() => {
                emitToast({ message: "Connection successfully setup." });
                onClose();
            })
            .catch((e) => {
                console.error(e);
                datadogRum.addError(e);
                setError(e?.message || UNEXPECTED_ERROR);
                setLoading(false);
            });
    };

    const updateFormValues = (values: OIDCIdpWithoutId | ISSOSamlFormFields) => {
        setFormValues(values);
    };

    const incompleteOIDCForm = () => {
        const requiredFields = ["clientId", "clientSecret", "idpName", "provider"];
        const values = formValues as IOIDCIdp;
        const hasRequiredFields = requiredFields.every((i) => !!values[i as keyof IOIDCIdp]);
        const noWellKnown = !values["wellKnownUrl"];
        const incompleteManual = !["authorizationUrl", "issuerUrl", "jwksUrl", "tokenUrl", "userInfoUrl"].every(
            (item) => !!values[item as keyof IOIDCIdp]
        );
        return !hasRequiredFields || (noWellKnown && incompleteManual);
    };

    const incompleteSAMLForm = () => {
        const requiredValues = ["idpName", "issuerUrl", "ssoUrl", "signatureCertificate"];
        const values = formValues as ISSOSamlFormFields;
        return !requiredValues.every((field) => !!values[field as keyof ISSOSamlFormFields]);
    };

    const incompleteForm = () => {
        if (connectionType === OIDC) {
            return incompleteOIDCForm();
        } else if (connectionType === SAML) {
            return incompleteSAMLForm();
        }
        return true;
    };

    return (
        <Sheet
            title={"SSO Connection Setup"}
            subtitle="Provide the following information from your SSO provider."
            onClose={onClose}
            footerButtonList={[
                <Button isDisabled={loading} onClick={onClose} style="plain">
                    Cancel
                </Button>,
                <Button
                    isDisabled={loading || incompleteForm()}
                    isLoading={loading}
                    onClick={handleSave}
                    style="highlight"
                >
                    Save
                </Button>
            ]}
        >
            {!!error && <Attention type="bad-news">{error} </Attention>}
            {connectionType === OIDC && <SSOOIDCFormFields onChange={updateFormValues} />}
            {connectionType === SAML && <SSOSamlFormFields onChange={updateFormValues} />}
        </Sheet>
    );
};

SSOConnectionSetupFormSheet.displayName = "SSOConnectionSetupFormSheet";
