import { useMemo, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../domain/OrganizationProduct";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Icon, Typography } from "@optimizely/axiom";
import { ATTRIBUTE_ROLES } from "../../../constants";

export const Breadcrumbs = () => {
    const { enabled: breadcrumbsEnabled } = useFeatureFlag(Flags.ENABLE_BREADCRUMBS);
    const location = useLocation();
    const pathNames = location.pathname.split("/").filter((x) => x);
    const displayedPathNames = pathNames.slice(1); // remove access from breadcrumbs
    const displayBreadcrumbs = breadcrumbsEnabled && pathNames && pathNames[0]?.toLowerCase() === "access";

    const { productId, instanceId, projectId } = useParams();
    const { organizationId = "", canUserDoAction } = useUserContext();
    const { products = [] } = useProducts({ organizationId });
    const { getAttribute } = useAttributes({ productId: productId });
    const [product, setProduct] = useState<OrganizationProduct | undefined>(undefined);
    const [instance, setInstance] = useState<IOrganizationProductInstance | undefined>(undefined);
    const [projectName, setProjectName] = useState("");
    const canReadProducts = canUserDoAction({ action: ATTRIBUTE_ROLES.PRODUCTS.READ });

    useEffect(() => {
        setProduct(products.find((x) => x.id === productId));
        setInstance(
            (products as OrganizationProduct[])
                .find((x) => x.id === productId)
                ?.instances?.find((i) => i.id === instanceId)
        );
    }, [projectId, instanceId, productId, products]);

    useEffect(() => {
        const getProject = async () => {
            try {
                if (projectId) {
                    const attribute = await getAttribute(projectId);
                    setProjectName(attribute.name);
                }
            } catch (err) {
                console.error("error getting attribute ", err);
            }
        };

        getProject();
    }, [projectId, getAttribute]);

    const labelMapping: { [key: string]: string } = useMemo(() => {
        return {
            ...(productId ? { [productId]: product?.name ?? "" } : {}),
            ...(instanceId ? { [instanceId]: instance?.name ?? "" } : {}),
            ...(projectId ? { [projectId]: projectName } : {}),
            products: "Products",
            instances: "Instances",
            projects: "Projects"
        };
    }, [productId, instanceId, projectId, product, instance, projectName]);

    if (!displayBreadcrumbs) return null;

    return (
        <div className="breadcrumbs">
            <Typography type="body">
                {displayedPathNames.map((value, index) => {
                    const isFirst = index === 0;
                    const isLast = index === displayedPathNames.length - 1;
                    let to = `/${pathNames.slice(0, index + 1).join("/")}`;

                    to += productId && to.endsWith(productId) ? "/instances" : "";
                    to +=
                        instanceId && to.endsWith(instanceId)
                            ? process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID === productId
                                ? "/projects"
                                : "/users"
                            : "";
                    to += projectId && to.endsWith(projectId) ? "/users" : "";

                    const label = labelMapping[value] || value.replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
                    const productCrumb = value === product?.id;
                    const instanceCrumb = value === instance?.id;
                    const instancesCrumb = value === "instances";
                    const instancesCrumbWithNoProductReadPermissions = instancesCrumb && !canReadProducts;
                    const productsBreadcrumbButNoPermissions = value === "products" && !canReadProducts;
                    const shouldDisable =
                        isLast ||
                        productCrumb ||
                        instanceCrumb ||
                        instancesCrumbWithNoProductReadPermissions ||
                        productsBreadcrumbButNoPermissions;

                    return (
                        <span key={index}>
                            {!isFirst && (
                                <span>
                                    <Icon name="angle-right" size="small" />
                                </span>
                            )}
                            {!shouldDisable && (
                                <Link key={to} to={to}>
                                    {label}
                                </Link>
                            )}
                            {shouldDisable && <span>{label}</span>}
                        </span>
                    );
                })}
            </Typography>
        </div>
    );
};
