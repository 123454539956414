import { useAccessFlowUserFormContext } from "./AccessManagementUserFormProvider";
import { Attention, Input, Typography } from "@optimizely/axiom";
import { AccessManagementUserFormIconButton } from "./AccessManagementUserFormIconButton";
import styles from "./AccessManagementUserForm.module.scss";
import React, { useEffect, useState } from "react";
import { useUsers } from "../../../../hooks/useUsers/useUsers";

export const UserFormNameFields = ({
    disableEdit = false,
    user
}: {
    disableEdit?: boolean;
    user: {
        email: string;
        firstName: string;
        lastName: string;
    };
}) => {
    const { email, firstName: initialFirstName, lastName: initialLastName } = user;
    const { updateUserFields } = useUsers();
    const { activeField, setActiveField, loading, setLoadingState } = useAccessFlowUserFormContext();
    const [error, setError] = useState(null);
    const isEditing = (activeField?.name as string) === "name";
    const [firstName, updateFirstName] = useState(initialFirstName);
    const [lastName, updateLastName] = useState(initialLastName);
    const onEditClick = () => {
        setActiveField({ name: "name", hasChanged: false });
    };

    useEffect(() => {
        updateFirstName(initialFirstName);
        updateLastName(initialLastName);
    }, [email, initialFirstName, initialLastName]);

    const onSave = () => {
        setLoadingState(true);
        if (error) {
            setError(null);
        }
        updateUserFields({
            email,
            firstName,
            lastName
        })
            .then(() => {
                setActiveField({ name: null, hasChanged: false });
            })
            .catch(([e]) => {
                console.error("Failed updating user: ", e);
                setError(e.message);
            })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const revertChanges = () => {
        updateFirstName(initialFirstName);
        updateLastName(initialLastName);
    };

    useEffect(() => {
        window.document.addEventListener("confirmation-dialog:name:discard-changes", revertChanges);
        return () => {
            window.document.removeEventListener("confirmation-dialog:name:discard-changes", revertChanges);
        };
    });

    return (
        <div className={`${styles["access-management-user-form"]}`}>
            {error && (
                <Attention type="bad-news" className="push--right push--bottom">
                    {error}
                </Attention>
            )}
            <div className="flex justify-content-around">
                <div className="flex--1">
                    <label className={`${styles["user-form-field-label__top-line"]} oui-label`}>First Name</label>
                    {isEditing ? (
                        <Input
                            className="flex--1"
                            type="text"
                            onChange={(e) => {
                                updateFirstName(e.target.value);
                                setActiveField({ name: "name", hasChanged: true });
                            }}
                            isDisabled={loading}
                            value={firstName}
                            placeholder="First Name"
                        />
                    ) : (
                        <Typography type="body" className="label--disabled">
                            {firstName}
                        </Typography>
                    )}
                </div>
                <div className="flex--1 push--sides">
                    <label className={`${styles["user-form-field-label__top-line"]} oui-label`}>Last Name</label>
                    {isEditing ? (
                        <Input
                            className="flex--1"
                            type="text"
                            onChange={(e) => {
                                updateLastName(e.target.value);
                                setActiveField({ name: "name", hasChanged: true });
                            }}
                            isDisabled={loading}
                            value={lastName}
                            placeholder="Last Name"
                        />
                    ) : (
                        <Typography type="body" className="label--disabled">
                            {lastName}
                        </Typography>
                    )}
                </div>
                <div className="push-triple--top push--right">
                    {isEditing ? (
                        <AccessManagementUserFormIconButton
                            title="Save"
                            loading={loading}
                            icon="square-check"
                            onClick={onSave}
                        />
                    ) : disableEdit ? null : (
                        <AccessManagementUserFormIconButton title="Edit" icon="pen-to-square" onClick={onEditClick} />
                    )}
                </div>
            </div>
        </div>
    );
};

UserFormNameFields.displayName = "UserFormNameFields";
