import useSWR from "swr";

import { useServicesContext } from "../../providers/ServicesProvider";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Organization } from "../../domain/Organization";

export const useOrganization = ({ id }: { id?: string | undefined } = {}) => {
    const { organizationService } = useServicesContext();
    const {
        mutate: refetchOrganization,
        isValidating,
        data: organization,
        error
    } = useSWR<Organization | null, Error>(id ? `/api/organizations/${id}` : null, () => {
        if (!id) {
            return null;
        }

        return organizationService!.getOrganization({ id });
    });

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    const updateOrganization = ({ id, mfaEnabled }: { id: string; mfaEnabled?: boolean }) => {
        return organizationService?.updateOrganization({ id, mfaEnabled });
    };

    return {
        loading: isValidating || !(organization || error),
        error: error?.message || error,
        refetchOrganization,
        organization,
        updateOrganization
    };
};
