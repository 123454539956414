/* eslint-disable react/style-prop-object */
import React from "react";
import { ButtonIcon, Poptip, Table } from "@optimizely/axiom";
import { InstanceRole } from "../../../../domain/InstanceRole.interface";
import { AccessDetailsRow } from "./AccessDetailsTable";

const renderRoles = (roles: InstanceRole[]) => {
    if (roles?.length === 0) return "N/A";

    return roles.map((role, index) => {
        const roleName = role.name.split(" - ")[0];
        return <div key={role.id}>{index === roles.length - 1 ? roleName : `${roleName},`}</div>;
    });
};

export type AccessDetailsTableRowProps = {
    access: AccessDetailsRow;
    showProjectColumn: boolean;
    key: string;
    onRemove?: ({ accessDetailsRow }: { accessDetailsRow: AccessDetailsRow }) => void;
};

export const AccessDetailsTableRow = ({ access, showProjectColumn, key, onRemove }: AccessDetailsTableRowProps) => {
    const { instanceName, nickname, projectId, projectName, roles } = access;

    return (
        <Table.TR key={`${key} ${projectId}`}>
            <Table.TD>{nickname || instanceName}</Table.TD>
            {showProjectColumn && <Table.TD>{projectName || "All"}</Table.TD>}
            <Table.TD>{renderRoles(roles)}</Table.TD>
            {onRemove && (
                <Table.TD>
                    <Poptip trigger={"mouseenter"} content="Remove Access" isInline>
                        <ButtonIcon
                            iconName="trash-can"
                            size="small"
                            style="plain"
                            data-testid="remove-access"
                            onClick={() => onRemove({ accessDetailsRow: access })}
                        />
                    </Poptip>
                </Table.TD>
            )}
        </Table.TR>
    );
};

AccessDetailsTableRow.displayName = "AccessDetailsTableRow";
