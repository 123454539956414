import { IOrganizationProductInstance, OrganizationProduct } from "../domain/OrganizationProduct";
import { ProductAttribute } from "../domain/ProductAttribute";
import { ATTRIBUTE_SCOPES } from "../constants";
import { ProductInstance } from "../domain/ProductInstance";
import { Role } from "../domain/Role";
import {
    AccessDetailsByProductId,
    AccessDetailsByInstanceId,
    AccessDetailsRow
} from "../ui/components/Access/AccessDetailsTable/AccessDetailsTable";
import { ProductPermission } from "../domain/Permission.interface";

export const getProductFromInstanceId = ({
    instanceId,
    products
}: {
    instanceId?: string;
    products: OrganizationProduct[];
}) => {
    return products?.find((p) => p.instances?.find((i) => i.id === instanceId));
};

export const getInstanceFromProjectId = ({
    instances,
    projectId,
    projects = []
}: {
    instances?: IOrganizationProductInstance[];
    projectId: string;
    projects?: ProductAttribute[];
}) => {
    const project = projects?.find((p) => p.id === projectId);
    return instances?.find((i) => i.id === project?.instanceId);
};

export const findByField = ({ field, items, value }: { field: string; items: any[]; value: string }) => {
    return items?.find((i) => i[field] === value);
};

export const getEntitledProductRoles = ({
    products,
    instances,
    roles
}: {
    products: OrganizationProduct[];
    instances: ProductInstance[];
    roles: Role[];
}): Role[] => {
    // Step 1: Extract productIds from the products that the current Org is entitled to.
    const productIds = products?.map((pd) => pd.id);
    const instanceIds = instances?.map((i) => i.instanceId);

    // Step 2: Extract the roles that correspond to the Org's products
    return roles?.filter(
        (role) =>
            role.attributes.some(
                (attr) =>
                    attr.scopes?.some(
                        (sc) =>
                            (sc.name === ATTRIBUTE_SCOPES.PRODUCT && productIds?.includes(sc.value)) ||
                            (sc.name === ATTRIBUTE_SCOPES.INSTANCE && instanceIds?.includes(sc.value)) ||
                            sc.name === ATTRIBUTE_SCOPES.PROJECT
                        // If there is any role with scope = projectId, it means org is entitled to EXP, so no need to check for value.
                    )
            ) ||
            role.attributes.every(
                (attr) => (attr.scopes ?? []).length === 0 // Check if all scopes arrays are empty, these are admin center roles that are not scoped to any product.
            )
    );
};

export const getAccessByProduct = ({
    productInstances,
    groupId,
    groupName
}: {
    productInstances: ProductInstance[];
    groupId?: string;
    groupName?: string;
}) => {
    const accessDetails = productInstances.reduce(
        (accessDetails: AccessDetailsByProductId, instance: ProductInstance) => {
            const { productId, productName, instanceId, instanceName, nickname, roles } = instance;

            const instanceDetailsRow: AccessDetailsRow = {
                instanceName,
                nickname: nickname ?? undefined,
                roles
            };

            if (!!groupId) instanceDetailsRow.groupId = groupId;
            if (!!groupName) instanceDetailsRow.groupName = groupName;

            if (!accessDetails[productId]) {
                const instanceAccess = {} as AccessDetailsByInstanceId;
                instanceAccess[instanceId] = [instanceDetailsRow];

                accessDetails[productId] = { productName, instanceAccess };
            } else {
                if (!accessDetails[productId].instanceAccess[instanceId]) {
                    accessDetails[productId].instanceAccess[instanceId] = [instanceDetailsRow];
                } else {
                    accessDetails[productId].instanceAccess[instanceId].push(instanceDetailsRow);
                }
            }

            return accessDetails;
        },
        {} as AccessDetailsByProductId
    );
    return accessDetails;
};

export const updateAccessWithProjectDetails = ({
    productList,
    availableOrgAccess,
    groupId
}: {
    productList: AccessDetailsByProductId;
    availableOrgAccess: ProductPermission[] | undefined;
    groupId: string;
}) => {
    const matchingOrgExperimentationRoles = availableOrgAccess
        ?.find((g) => g.productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID)
        ?.instanceAccess.flatMap((ia) => ia.availableRoles)
        .filter((role) => role.group.id === groupId);

    const groupExperimentationAccess = productList[process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID!]?.instanceAccess;
    const updatedGroupExperimentationAccess = { ...groupExperimentationAccess } as AccessDetailsByInstanceId;

    const groupExperimentationInstanceIds = Object.keys(groupExperimentationAccess);

    groupExperimentationInstanceIds.forEach((groupExperimentationInstanceId) => {
        groupExperimentationAccess[groupExperimentationInstanceId].forEach(
            (groupExperimentationInstanceAccess, instanceIndex) => {
                const uniqueProjects: { [key: string]: string } = {};

                if (groupExperimentationInstanceAccess.roles.length > 0) {
                    groupExperimentationInstanceAccess.roles.forEach((role) => {
                        matchingOrgExperimentationRoles?.find((matchingRole) => {
                            if (matchingRole.role.id === role.id && !!matchingRole.project) {
                                return (uniqueProjects[matchingRole?.project?.id] = matchingRole?.project?.name);
                            }
                            return null;
                        });
                    });
                }

                Object.keys(uniqueProjects).forEach((projectId, projectIndex) => {
                    if (projectIndex === 0) {
                        updatedGroupExperimentationAccess[groupExperimentationInstanceId][instanceIndex].projectId =
                            projectId;
                        updatedGroupExperimentationAccess[groupExperimentationInstanceId][instanceIndex].projectName =
                            uniqueProjects[projectId];
                    } else {
                        updatedGroupExperimentationAccess[groupExperimentationInstanceId].push({
                            ...groupExperimentationInstanceAccess,
                            projectId,
                            projectName: uniqueProjects[projectId]
                        });
                    }
                });
            }
        );

        const updatedProductList = { ...productList } as AccessDetailsByProductId;
        updatedProductList[process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID!].instanceAccess =
            groupExperimentationAccess;
        return updatedProductList;
    });

    const updatedProductList = { ...productList } as AccessDetailsByProductId;
    updatedProductList[process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID!].instanceAccess =
        updatedGroupExperimentationAccess;
    return updatedProductList;
};
