import { ADMINCENTER_ITEM_UPDATED_EVENT_NAME } from "../../constants";
import { Role } from "../../domain/Role";
import { ProductInstance } from "../../domain/ProductInstance";
import { UserGroup } from "../../domain/UserGroup";
import { User } from "../../domain/User";

export const useUpdateSync = () => {
    const fireChangeEvent = ({
        type,
        data
    }: {
        type: string;
        data: {
            user?: Pick<User, "email" | "firstName" | "lastName">;
            group?: Pick<UserGroup, "id" | "name" | "description">;
            instance?: Pick<
                ProductInstance,
                "instanceId" | "nickname" | "technicalContactEmail" | "technicalContactName"
            >;
            role?: Pick<Role, "id" | "name" | "description">;
        };
    }) => {
        window.dispatchEvent(
            new CustomEvent(ADMINCENTER_ITEM_UPDATED_EVENT_NAME, {
                detail: {
                    type,
                    data
                }
            })
        );
    };

    return {
        fireChangeEvent
    };
};
