import { Role } from "../../../domain/Role";
import { Badge, Dropdown, Table } from "@optimizely/axiom";
import { MoreMenu } from "../MoreMenu/MoreMenu";
import { ATTRIBUTE_ROLES, SYSTEM_ROLE_TYPE } from "../../../constants";
import LimitByRole from "../../components/LimitByRole/LimitByRole";

type RolesTableRowProps = {
    onDeleteClick: ({ role }: { role: Role }) => void;
    onRoleClick: ({ role }: { role: Role }) => void;
    productName: string;
    role: Role;
    roleInstances: string;
};
export const RolesTableRow = ({ onDeleteClick, onRoleClick, productName, role, roleInstances }: RolesTableRowProps) => {
    const { modified, name, roleType, isSystemGeneratedRole } = role;
    const isProductRole = roleType === SYSTEM_ROLE_TYPE;
    const interpretAsSystemRole = roleType === "ProductDefined" || isSystemGeneratedRole;

    const handleRoleClick = () => {
        onRoleClick({ role });
    };

    return (
        <Table.TR>
            <Table.TD>
                <button className="link button-as-link" type="button" onClick={handleRoleClick}>
                    {name}
                </button>
            </Table.TD>
            <Table.TD>
                <Badge color={interpretAsSystemRole ? "default" : "new"}>
                    {interpretAsSystemRole ? "System" : "Custom"}
                </Badge>
            </Table.TD>
            <Table.TD>{productName}</Table.TD>
            <Table.TD>{roleType === "CustomerDefined_Instance" ? roleInstances : "All"}</Table.TD>
            <Table.TD>
                {modified.toLocaleString("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "short"
                })}
            </Table.TD>
            <LimitByRole action={ATTRIBUTE_ROLES.ROLES.UPDATE} mode="hide">
                <Table.TD className="user-manager__table--more-menu">
                    {!isProductRole && !isSystemGeneratedRole && (
                        <MoreMenu>
                            <Dropdown.ListItem>
                                <Dropdown.BlockLink onClick={handleRoleClick}>View Custom Role...</Dropdown.BlockLink>
                            </Dropdown.ListItem>
                            <Dropdown.ListItem>
                                <Dropdown.BlockLink onClick={() => onDeleteClick({ role })}>
                                    <span className="danger">Delete Custom Role...</span>
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        </MoreMenu>
                    )}
                </Table.TD>
            </LimitByRole>
        </Table.TR>
    );
};

RolesTableRow.displayName = "RolesTableRow";
