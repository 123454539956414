import { useServicesContext } from "../../providers/ServicesProvider";
import { useCallback, useEffect, useState } from "react";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { Collection } from "../../domain/Collection";
import { DEFAULT_PAGE_SIZE } from "../../constants";
import { Customer } from "../../domain/Customer";
import { datadogRum } from "@datadog/browser-rum";

export const useCustomers = ({ query }: { query: string }) => {
    const { customerService } = useServicesContext();
    const [currentData, setCurrentData] = useState<Customer[] | undefined>(undefined);

    const { pageData, error, currentPage, setCurrentPage } = usePaginatedList<Customer>({
        key: query ? `/api/customers?query=${query}` : null,
        fetcher: (): Promise<Collection<Customer>> => {
            return customerService!.searchCustomers({
                query
                // size: DEFAULT_PAGE_SIZE,
                // page: currentPage,
            });
        }
    });

    useEffect(() => {
        if (pageData) setCurrentData(pageData?.items);
    }, [pageData]);

    useEffect(() => {
        if (error) {
            console.error({ error });
            datadogRum.addError(error);
        }
    }, [error]);

    const searchCustomers = useCallback(
        async ({ query }: { query: string }) => {
            return customerService!.searchCustomers({ query });
        },
        [customerService]
    );

    return {
        customers: currentData,
        currentPage,
        error,
        setCurrentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        searchCustomers
    };
};
