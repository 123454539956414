import { IAccessContext } from "../../services/UserService";
import { useAttributes } from "../useAttributes/useAttributes";
import useSWR from "swr";
import { ProductAttribute } from "../../domain/ProductAttribute";
import { CONTEXT_SCOPES } from "../../constants";

// The useContextProject gets projects for the instance provided in the context.
// Right now this assumes a particular context (INSTANCE)
// so renaming is still on the table I think unless we
// start actually using the context in future iterations of this hook
export const useContextProjects = ({ context }: { context?: IAccessContext }) => {
    const [instanceId] = context?.items.map((i) => i.id) || [];

    const { getExperimentationProjects } = useAttributes({
        instanceId
    });

    const { data: projects, error } = useSWR<ProductAttribute[], Error>(
        context && context.scope === CONTEXT_SCOPES.INSTANCE ? `/api/projects/?instanceId=${instanceId}` : null,
        () => {
            return getExperimentationProjects({ instanceId });
        }
    );

    return {
        projects,
        isLoading: !projects && !error,
        error
    };
};
