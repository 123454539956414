import { UserGroup } from "../domain/UserGroup";
import { OrganizationProduct } from "../domain/OrganizationProduct";
import { IAccessContext } from "./UserService";
import { OrganizationPermission, ProductPermission } from "../domain/Permission.interface";
import { CONTEXT_SCOPES } from "../constants";
import { Role } from "../domain/Role";
import { IProductAttribute } from "../domain/ProductAttribute.interface";

export const getOrgPermissionsFromGroupList = (userGroups: UserGroup[], products: OrganizationProduct[]) => {
    return userGroups?.flatMap((g) => {
        return g.instancePermissions.map((permission) => {
            const product = products?.find((p) => p.instances.some((i) => i.id === permission.instanceId));
            const instance = product?.instances.find((i) => i.id === permission.instanceId);
            return {
                product,
                instance,
                projects: [],
                group: g,
                roles: permission.roles
            };
        });
    });
};

export const getProjects = (
    orgPermissions: OrganizationPermission[],
    getExperimentationProjects: ({
        instanceId,
        permissionType,
        projectKeys
    }: {
        instanceId: string;
        permissionType?: string | undefined;
        projectKeys?: string[] | undefined;
    }) => Promise<IProductAttribute[]>
) => {
    //get projects for EXP instances
    const expInstanceIds = Array.from(
        new Set([
            ...orgPermissions
                ?.filter((pp) => pp.product?.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID && pp.instance)
                .map((permission) => permission.instance!.id)
        ])
    );

    const promiseList: any[] = [];
    expInstanceIds.forEach((instanceId) => {
        promiseList.push(
            getExperimentationProjects({
                instanceId: instanceId!,
                projectKeys: []
            }).then((projectList) => {
                // Find all permissions for this instance and set the projects
                orgPermissions
                    ?.filter((permission) => permission.instance?.id === instanceId)
                    .forEach((perm) => {
                        perm.projects = projectList;
                    });
            })
        );
    });

    return promiseList;
};

export const filterPermissionListsForContext = (
    context: IAccessContext,
    orgPermissions: OrganizationPermission[] | undefined
) => {
    if (context && orgPermissions?.length) {
        if (context.scope === CONTEXT_SCOPES.PRODUCT) {
            orgPermissions = orgPermissions.filter((permission) => {
                return context.items.some((item) => item.id === permission.product?.id);
            });
        } else if (context.scope === CONTEXT_SCOPES.INSTANCE) {
            orgPermissions = orgPermissions.filter((permission) => {
                return context.items.some((item) => item.id === permission.instance?.id);
            });
        } else if (context.scope === CONTEXT_SCOPES.PROJECT) {
            orgPermissions = orgPermissions.filter((permission) => {
                permission.projects = permission.projects.filter((p) => {
                    return context.items.some((item) => item.id === p.id);
                });
                return context.items.some((item) => permission.projects.some((p) => p.id === item.id));
            });
        }
    }

    return orgPermissions;
};

const associateGroupRoles = (isExp: boolean, permission: OrganizationPermission, role: Role) => {
    if (isExp && permission.projects.length) {
        return permission.roles?.flatMap((role) => role.attributes).length
            ? permission.projects
                  .filter((project) => {
                      return permission.roles
                          ?.flatMap((role) => role.attributes)
                          .map((attr) => attr.key)
                          .includes(project.key);
                  })
                  .flatMap((proj) => {
                      return {
                          group: permission.group,
                          role: role,
                          project: proj
                      };
                  })
            : { group: permission.group, role: role };
    } else {
        return {
            group: permission.group,
            role: role
        };
    }
};
export const orgPermissionListToAccessList = (orgPermissions: OrganizationPermission[] | undefined) => {
    return orgPermissions?.reduce((list: ProductPermission[], permission) => {
        if (!permission.instance || !permission.product) return list;

        const prod = list.find((item) => item.productId === permission.product?.id);
        const isExp = permission.product.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
        if (!prod) {
            list.push({
                productName: permission.product.name,
                productId: permission.product.id,
                instanceAccess: [
                    {
                        instance: permission.instance,
                        availableRoles:
                            permission?.roles?.flatMap((role) => {
                                return associateGroupRoles(isExp, permission, role);
                            }) || []
                    }
                ]
            });
        } else {
            const inst = prod.instanceAccess.find((i: any) => i.instance.id === permission.instance?.id);
            if (inst) {
                inst.availableRoles = [
                    ...inst.availableRoles,
                    ...(permission?.roles?.flatMap((role) => {
                        return associateGroupRoles(isExp, permission, role);
                    }) || [])
                ];
            } else {
                prod.instanceAccess = [
                    ...prod.instanceAccess,
                    {
                        instance: permission.instance,
                        availableRoles:
                            permission?.roles?.flatMap((role) => {
                                return associateGroupRoles(isExp, permission, role);
                            }) || []
                    }
                ];
            }
        }
        return list;
    }, []);
};
