import { useServicesContext } from "../../providers/ServicesProvider";
import { IAccessContext } from "../../services/UserService";
import useSWR from "swr";
import { UserGroup } from "../../domain/UserGroup";
import { SWR_KEY_PREFIX } from "../../constants";

export const useContextUserGroups = ({
    organizationId,
    context,
    groupTypes = []
}: {
    organizationId?: string;
    context?: IAccessContext;
    groupTypes?: string[];
}) => {
    const { userService } = useServicesContext();
    const key =
        organizationId && context
            ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups/?organizationId=${organizationId}&scope=${
                  context.scope
              }&items=${context?.items?.map((i) => i.id).join(",")}&groupTypes=${groupTypes.join(",")}`
            : null;

    const { data: groups, error } = useSWR<UserGroup[], Error>(key, () => {
        return userService.getUserGroupsByContext(organizationId!, context!, groupTypes);
    });

    return {
        groups,
        isLoading: !groups && !error,
        error
    };
};
