import { Organization } from "../../domain/Organization";
import { IIdentityRepository } from "../../repositories/IdentityRepository";
import { OrganizationAdapter } from "../OrganizationAdapter";

export interface IOrganizationService {
    getOrganization({ id }: { id: string }): Promise<Organization>;
    updateOrganization({ id, mfaEnabled }: { id: string; mfaEnabled?: boolean }): Promise<Organization>;
}

export class OrganizationService implements IOrganizationService {
    identityRepository: IIdentityRepository;
    organizationAdapter: OrganizationAdapter;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.organizationAdapter = new OrganizationAdapter();
    }

    public async getOrganization({ id }: { id: string }): Promise<Organization> {
        return this.identityRepository.getOrganization({ id });
    }

    public updateOrganization({ id, mfaEnabled }: { id: string; mfaEnabled?: boolean }): Promise<Organization> {
        return this.identityRepository.updateOrganization({ organizationId: id, mfaEnabled });
    }
}
