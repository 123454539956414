import { IdentityProvider, IOIDCIdp, ISAMLIdp } from "../../../../domain/IdentityProvider";
import { Attention, Disclose } from "@optimizely/axiom";
import { SSOConnectionDetails } from "../SSOConnectionDetails/SSOConnectionDetails";
import React, { useState } from "react";
import { OIDC, SAML, UNEXPECTED_ERROR } from "../../../../constants";
import { SSOOIDCFormFields } from "../SSOOIDCForm/SSOOIDCFormFields/SSOOIDCFormFields";
import { SSOSamlFormFields } from "../SSOSamlForm/SSOSamlFormFields/SSOSamlFormFields";

export const IdentityProviderDisclosure = ({
    identityProvider,
    onRemove
}: {
    identityProvider: IdentityProvider;
    onRemove: () => Promise<void>;
}) => {
    const { idpName = "Connection", type } = identityProvider;
    const [error, setError] = useState(null);

    const handleConnectionRemoval = async () => {
        return onRemove().catch((e) => {
            setError(e?.message || UNEXPECTED_ERROR);
        });
    };

    return (
        <Disclose title={idpName}>
            {error && <Attention type="bad-news">{error}</Attention>}
            {type === SAML && <SSOSamlFormFields disabled idp={identityProvider as ISAMLIdp} />}
            {type === OIDC && <SSOOIDCFormFields disabled idp={identityProvider as IOIDCIdp} />}
            <SSOConnectionDetails
                className="push-quad--top"
                identityProvider={identityProvider}
                onRemove={handleConnectionRemoval}
            />
        </Disclose>
    );
};

IdentityProviderDisclosure.displayName = "IdentityProviderDisclosure";
