export class InstanceRole {
    constructor(role: any) {
        this.id = role.Id;
        this.name = role.Name;
    }

    id: string;
    name: string;
}

export class ProductInstancePermission {
    constructor(permission: any) {
        this.roles = permission.Roles.map((role: any) => new InstanceRole(role));
    }

    roles: InstanceRole[];
}
