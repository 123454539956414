import { Collection } from "../../domain/Collection";

import { UserGroup } from "../../domain/UserGroup";
import { useServicesContext } from "../../providers/ServicesProvider";
import { ALL_PAGES_SIZE, SWR_KEY_PREFIX } from "../../constants";
import useSWR from "swr";

export const useOrgUserGroups = ({ organizationId }: { organizationId?: string }) => {
    const { userService } = useServicesContext();

    const { data, error, mutate } = useSWR<Collection<UserGroup>, Error>(
        organizationId ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups/${organizationId}?size=${ALL_PAGES_SIZE}` : null,
        () => {
            return userService.getUserGroups({
                organizationId: organizationId as string,
                size: ALL_PAGES_SIZE
            });
        }
    );

    return {
        userGroups: data?.items,
        isLoading: !error && !data,
        error,
        revalidate: mutate
    };
};
