import { Attention, Button, Sheet, Table, Typography } from "@optimizely/axiom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { useUsers } from "../../../hooks/useUsers/useUsers";
import { useUserContext } from "../../../providers/UserProvider";
import { User } from "../../../domain/User";
import { UserTableRow } from "../UserTableRow/UserTableRow";
import { TableSkeleton } from "../Skeleton/TableSkeleton";
import { datadogRum } from "@datadog/browser-rum";
import { UNEXPECTED_ERROR } from "../../../constants";

type NotificationRecipientsSheetProps = {
    onClose: () => void;
    onSave: ({ recipients }: { recipients: User[] }) => Promise<void>;
    recipientEmails: string[];
    searchHeading?: string;
    subTitle?: string;
    title?: string;
};
export const NotificationRecipientsSheet = ({
    onClose,
    onSave,
    recipientEmails,
    searchHeading,
    subTitle,
    title = "Notification Recipients"
}: NotificationRecipientsSheetProps) => {
    const [recipients, setRecipients] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [disabled, setDisabled] = useState(false);
    const { organizationId } = useUserContext();
    const { getUser } = useUsers({ organizationId });
    const fetched = useRef(false);

    const getUserDetailsFromEmails = useCallback(
        async (emails: string[]) => {
            setLoading(true);
            try {
                // Map each email to a promise that fetches the user details
                const userPromises = emails.map((email) => getUser({ email }));
                // Wait for all promises to resolve
                const users = await Promise.all(userPromises);
                // Update state with the fetched user details
                setRecipients(users as User[]);
            } catch (error) {
                console.error("Failed to fetch user details:", error);
                datadogRum.addError(error);
                setRecipients([]);
            } finally {
                setLoading(false);
            }
        },
        [getUser]
    );

    const addUser = (user: User | undefined) => {
        if (!user) return;
        setRecipients((currentValue) => {
            return [...currentValue, user];
        });
    };

    const userInRecipients = (user: User) => {
        return !!recipients.find((r) => r.email === user?.email);
    };

    const removeAtIndex = (index: number) => {
        setRecipients((currentValue) => {
            return currentValue.filter((_, i) => i !== index);
        });
    };

    useEffect(() => {
        if (recipientEmails && recipientEmails.length > 0 && !fetched.current) {
            fetched.current = true;
            getUserDetailsFromEmails(recipientEmails);
        }
    }, [getUserDetailsFromEmails, recipientEmails]);

    const handleSave = () => {
        setDisabled(true);
        setError(null);
        onSave({ recipients }).catch((error) => {
            console.error("failed saving: ", error);
            datadogRum.addError(error);
            let errorMessage = UNEXPECTED_ERROR;
            if (Array.isArray(error)) {
                errorMessage = error[0]?.message || errorMessage;
            } else {
                errorMessage = error?.message || errorMessage;
            }

            setError(errorMessage);
            setDisabled(false);
        });
    };

    return (
        <Sheet
            subtitle={subTitle}
            footerButtonList={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                // eslint-disable-next-line react/style-prop-object
                <Button key="save" isDisabled={disabled} onClick={handleSave} style="highlight">
                    Save
                </Button>
            ]}
            onClose={onClose}
            title={title}
        >
            {error && <Attention type="bad-news">{error}</Attention>}
            {searchHeading && (
                <Typography type="body" className="push-double--bottom">
                    {searchHeading}
                </Typography>
            )}
            <UserSearchSelector
                disabled={loading || disabled}
                onSelectUser={addUser}
                isUserDisabled={userInRecipients}
            />
            {loading && <TableSkeleton fullWidth className="push-double--top" />}
            {!loading && (
                <Typography className={`push-triple--top push--bottom`} type="subhead">
                    {recipients?.length ? "Selected Users" : "No Users Selected"}
                </Typography>
            )}

            <Table
                // eslint-disable-next-line react/style-prop-object
                style="rule"
            >
                <Table.TBody>
                    {recipients?.map((recipient, recipientIndex) => {
                        return (
                            <UserTableRow
                                user={recipient}
                                onRemove={() => removeAtIndex(recipientIndex)}
                                key={recipient.id}
                            />
                        );
                    })}
                </Table.TBody>
            </Table>
        </Sheet>
    );
};

NotificationRecipientsSheet.displayName = "NotificationRecipientsSheet";
