import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useUserContext } from "../../../providers/UserProvider";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { ProductList } from "../../components/Products/ProductList";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";
import { ATTRIBUTE_ROLES } from "../../../constants";

export const Products = () => {
    const { organizationId } = useUserContext();
    const { products = [], contactDetails, isLoading, error } = useProducts({ organizationId });
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);

    if (accessManagementEnabled) return <Unauthorized />;

    if (isLoading) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching product list.</div>;

    return (
        <LimitByRole action={ATTRIBUTE_ROLES.PRODUCTS.READ} mode="unauthorized">
            <ProductList products={products} contactDetails={contactDetails} />
        </LimitByRole>
    );
};

Products.displayName = "ProductsPage";
