/* eslint-disable react/style-prop-object */
import React from "react";
import { Dropdown, Table } from "@optimizely/axiom";

import styles from "../../pages/UserList/UserList.module.scss";
import { User } from "../../../domain/User";
import { ATTRIBUTE_ROLES } from "../../../constants";
import classnames from "classnames";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import LimitByRole from "../LimitByRole/LimitByRole";
import { useFormContext } from "../UserForm/UserFormContext/UserFormContext";
import { MoreMenu } from "../MoreMenu/MoreMenu";

const UserTable = ({
    userList,
    onViewDetails,
    onRemoveAccess,
    onDeleteUser
}: {
    userList?: User[];
    onViewDetails?: (user: User) => void;
    onRemoveAccess?: (user: User) => void;
    onDeleteUser?: (user: User) => void;
}) => {
    const { enabled: showLoginStatus } = useFeatureFlag(Flags.SHOW_LOGIN_STATUS);
    const { updateAccessManagementUserState } = useFormContext();

    const openViewUser = (user: User, editMode?: boolean) => {
        updateAccessManagementUserState({ user, editing: editMode });
    };

    const displayRecord = (user: User) => {
        const { firstName, email, id, lastName, properties = [] } = user;
        const activationStatus =
            properties.find((p) => p.name?.toLowerCase() === "activateuser")?.value === "true" ? "Enabled" : "Disabled";
        const activationStatusClasses = classnames(styles["user-list__activation-status"], {
            [styles["user-list__activation-status--active"]]: activationStatus === "Enabled"
        });
        return (
            <Table.TR key={id}>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {firstName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {lastName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {email}
                    </button>
                </Table.TD>
                {showLoginStatus && (
                    <Table.TD>
                        <span className={activationStatusClasses}>{activationStatus}</span>
                    </Table.TD>
                )}
                <Table.TD>
                    {user.created.toLocaleString("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "short"
                    })}
                </Table.TD>
                <LimitByRole action={ATTRIBUTE_ROLES.USERS.UPDATE} mode="hide">
                    <Table.TD colSpan={1} className="user-manager__table--more-menu">
                        <MoreMenu>
                            <Dropdown.ListItem>
                                <Dropdown.BlockLink
                                    onClick={() => {
                                        if (onViewDetails) {
                                            onViewDetails(user);
                                        }
                                    }}
                                >
                                    <span>View Details...</span>
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                            <Dropdown.ListItem isDisabled>
                                <Dropdown.BlockLink
                                    onClick={() => {
                                        if (onRemoveAccess) {
                                            onRemoveAccess(user);
                                        }
                                    }}
                                >
                                    <span className="danger">Remove access...</span>
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                            <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.USERS.UPDATE}>
                                <Dropdown.ListItem isDisabled>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            if (onDeleteUser) {
                                                onDeleteUser(user);
                                            }
                                        }}
                                    >
                                        <span className="danger">Delete user...</span>
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            </LimitByRole>
                        </MoreMenu>
                    </Table.TD>
                </LimitByRole>
            </Table.TR>
        );
    };

    const tableClasses = classnames("list-table flex flex--column", styles["user-list__table"]);
    return (
        <LimitByRole action={ATTRIBUTE_ROLES.USERS.READ} mode="unauthorized">
            <div className={tableClasses}>
                {userList && userList.length > 0 && (
                    <>
                        <div className="user-manager__table">
                            <Table className="push-double--bottom" density="loose" style="rule-no-bottom-border">
                                <Table.THead>
                                    <Table.TR>
                                        <Table.TH width="25%">First Name</Table.TH>
                                        <Table.TH width="25%">Last Name</Table.TH>
                                        <Table.TH width="40%">Email</Table.TH>
                                        {showLoginStatus && <Table.TH width="15%">Status</Table.TH>}
                                        <Table.TH width="15%">Created</Table.TH>
                                        <Table.TH width="60px"> </Table.TH>
                                    </Table.TR>
                                </Table.THead>
                                <Table.TBody>{userList?.map((user) => displayRecord(user))}</Table.TBody>
                            </Table>
                        </div>
                    </>
                )}
            </div>
        </LimitByRole>
    );
};

UserTable.displayName = "UserTable";

export default UserTable;
