import { TOASTER_EVENT_NAME } from "../constants";

export const emitToast = ({ message, type = "good-news" }: { message: string; type?: string }) => {
    const toast = new CustomEvent(TOASTER_EVENT_NAME, {
        detail: {
            message,
            type
        }
    });
    document.dispatchEvent(toast);
};
