/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Dropdown, PaginationControls, Table } from "@optimizely/axiom";

import styles from "../../pages/UserList/UserList.module.scss";
import { ATTRIBUTE_ROLES, DEFAULT_PAGE_SIZE } from "../../../constants";
import classnames from "classnames";
import LimitByRole from "../LimitByRole/LimitByRole";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { MoreMenu } from "../MoreMenu/MoreMenu";
import { Link, useNavigate, useParams } from "react-router-dom";

const ProjectsTable = ({ projectsList }: { projectsList?: ProductAttribute[] }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const { instanceId } = useParams();

    const goToPage = (page = 1) => {
        setCurrentPage(page);
    };

    const displayRecord = (project: ProductAttribute) => {
        const { name, type, isActive, id } = project;

        return (
            <Table.TR key={id}>
                <Table.TD>
                    <Link
                        to={`/access/products/${process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID}/instances/${instanceId}/projects/${id}/users`}
                    >
                        {name}
                    </Link>
                </Table.TD>
                <Table.TD>{type}</Table.TD>
                <Table.TD>{isActive ? "Active" : "Archived"}</Table.TD>
                <Table.TD colSpan={1}>
                    <MoreMenu>
                        <Dropdown.ListItem>
                            <Dropdown.BlockLink
                                onClick={() =>
                                    navigate(
                                        `/access/products/${process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID}/instances/${instanceId}/projects/${id}/users`
                                    )
                                }
                            >
                                View details...
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    </MoreMenu>
                </Table.TD>
            </Table.TR>
        );
    };

    const totalPages = Math.ceil((projectsList?.length || 0) / DEFAULT_PAGE_SIZE);
    const showPagination = totalPages >= 2;

    const filteredProjects = projectsList?.slice(
        (currentPage - 1) * DEFAULT_PAGE_SIZE,
        (currentPage - 1) * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE
    );

    const tableClasses = classnames("list-table flex flex--column", styles["user-list__table"]);
    return (
        <LimitByRole action={ATTRIBUTE_ROLES.USERS.READ} mode="unauthorized">
            <div className={tableClasses}>
                {filteredProjects && filteredProjects.length > 0 && (
                    <>
                        <Table
                            className="push-double--bottom push-quad--top"
                            density="loose"
                            style="rule-no-bottom-border"
                        >
                            <Table.THead>
                                <Table.TR>
                                    <Table.TH width="30%">Name</Table.TH>
                                    <Table.TH width="25%">Type</Table.TH>
                                    <Table.TH width="40%">Archived</Table.TH>
                                    <Table.TH width="60px"> </Table.TH>
                                </Table.TR>
                            </Table.THead>
                            <Table.TBody>{filteredProjects.map((project) => displayRecord(project))}</Table.TBody>
                        </Table>
                        {showPagination && (
                            <PaginationControls
                                currentPage={currentPage}
                                goToPage={(page: number) => goToPage(page)}
                                totalPages={totalPages}
                            />
                        )}
                    </>
                )}
            </div>
        </LimitByRole>
    );
};

ProjectsTable.displayName = "ProjectsTable";

export default ProjectsTable;
