import React, { useCallback, useEffect, useRef, useState } from "react";
import { Attention, Button, Typography } from "@optimizely/axiom";

import { UserFormNameFields } from "../Access/AccessManagementUserForm/UserFormNameFields";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useAccessFlowUserFormContext } from "../Access/AccessManagementUserForm/AccessManagementUserFormProvider";

import { useUserContext } from "../../../providers/UserProvider";
import { User } from "../../../domain/User";
import { ATTRIBUTE_ROLES, ADMINCENTER_PRODUCT_ID } from "../../../constants";
import { UserGroupAccessList } from "./UserGroupAccessList";
import LimitByRole from "../LimitByRole/LimitByRole";

import styles from "./GroupAccessUserForm.module.scss";

import { UserFormActivationStatus } from "../Access/AccessManagementUserForm/UserFormActivationStatus";
import { AddGroupAccessForm } from "../Access/AddGroupAccess/AddGroupAccessForm";

export const GroupAccessUserForm = ({
    onCancel,
    showModal,
    user
}: {
    onCancel: () => void;
    showModal: React.Dispatch<React.SetStateAction<boolean>>;
    user: User;
}) => {
    const { setActiveField } = useAccessFlowUserFormContext();
    const { canUserDoAction, organizationId, instancePermissions } = useUserContext();

    const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false);

    const [error, setError] = useState<string | null>(null);
    const { email, homeOrganizationId: targetUserHomeOrgId } = user;
    const userEmail = useRef<string | undefined>(user?.email);

    const clearFormFields = useCallback(() => {
        setActiveField({ name: null, hasChanged: false });
        setIsAddingGroup(false);
        setError(null);
    }, [setActiveField]);

    const onCancelGroupAccessForm = () => {
        clearFormFields();
        onCancel();
    };

    const userPermissionsInTargetHomeOrg = instancePermissions?.find((instancePermission) => {
        return (
            instancePermission.organizationId === targetUserHomeOrgId &&
            instancePermission.productId === ADMINCENTER_PRODUCT_ID
        );
    });

    const canEditUserName =
        organizationId === targetUserHomeOrgId
            ? canUserDoAction({ action: ATTRIBUTE_ROLES.USERS.UPDATE })
            : !!userPermissionsInTargetHomeOrg &&
              canUserDoAction({ action: ATTRIBUTE_ROLES.USERS.UPDATE, roles: userPermissionsInTargetHomeOrg?.roles });

    useEffect(() => {
        if (email !== userEmail.current) {
            userEmail.current = email;
            clearFormFields();
        }
    }, [clearFormFields, email]);

    const onGroupAccessClose = () => {
        setIsAddingGroup(false);
        setError(null);
    };

    return (
        <form>
            <div className="push-quad--left push-quad--right">
                {error && (
                    <Attention type="bad-news" className="push--right push-double--bottom">
                        {error}
                    </Attention>
                )}
                <UserFormNameFields user={user} disableEdit={!canEditUserName} />
                <div className="flex justify-content-around">
                    <div className="flex--1">
                        <label className={`${styles["user-form-field-label"]} oui-label`}>Email</label>
                        <Typography type="body" tag="div">
                            {email}
                        </Typography>
                    </div>
                </div>
                <UserFormActivationStatus
                    user={{ email: user?.email, externalStatus: user?.externalStatus }}
                    setError={setError}
                    showModal={showModal}
                />
                <Typography
                    type="header3"
                    tag="div"
                    className={`push-double--top push--bottom ${styles["group-access-header"]}`}
                >
                    Group Access
                    <LimitByRole action={[ATTRIBUTE_ROLES.GROUPS.MANAGE, ATTRIBUTE_ROLES.GROUPS.UPDATE]} mode="hide">
                        <span>
                            <Button onClick={() => setIsAddingGroup(true)}>Add Group Access</Button>
                        </span>
                    </LimitByRole>
                </Typography>
                {isAddingGroup && (
                    <AddGroupAccessForm
                        onClose={onGroupAccessClose}
                        organizationId={organizationId}
                        email={user.email}
                        onError={setError}
                    />
                )}
                <UserGroupAccessList email={email} setError={setError} />
            </div>
            <SidebarFooter onCancel={onCancelGroupAccessForm} cancelStyle="plain"></SidebarFooter>
        </form>
    );
};

GroupAccessUserForm.displayName = "GroupAccessUserForm";
