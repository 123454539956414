import React from "react";
import { ButtonIcon, Table, Typography } from "@optimizely/axiom";
import { ADMINCENTER_GROUP_NAME, ADMINCENTER_PRODUCT_ID } from "../../../constants";
import styles from "./InstanceRolesTable.module.scss";
import { UserGroup } from "../../../domain/UserGroup";
import { getRoleNames } from "../../../lib/utils";
import { TableSkeleton } from "../Skeleton/TableSkeleton";

type InstanceRolesTableProps = {
    editing?: boolean;
    hideDelete?: boolean;
    instances?: any[];
    loading?: boolean;
    onInstanceRemoval?: ({ instanceIndex }: { instanceIndex: number }) => void;
    userGroup: UserGroup | null;
};
export const InstanceRolesTable = ({
    editing,
    hideDelete: disableDelete,
    instances,
    loading,
    onInstanceRemoval,
    userGroup
}: InstanceRolesTableProps) => {
    const handleInstanceRemoval = ({ instanceIndex }: { instanceIndex: number }) => {
        if (onInstanceRemoval) {
            onInstanceRemoval!({ instanceIndex });
        }
    };

    if (loading) {
        return <TableSkeleton />;
    }

    return (
        <>
            {!!instances?.length ? (
                <Table
                    className="push--top"
                    // eslint-disable-next-line react/style-prop-object
                    style="rule"
                    tableLayoutAlgorithm="auto"
                >
                    <Table.THead>
                        <Table.TR>
                            <Table.TH> Product (Instance) </Table.TH>
                            <Table.TH width="30%"> Roles </Table.TH>
                            <Table.TH> </Table.TH>
                        </Table.TR>
                    </Table.THead>
                    <Table.TBody>
                        {instances?.map((instance, instanceIndex) => {
                            const { instanceId, productName, instanceName, productId, nickname, roles = [] } = instance;
                            const hideDelete =
                                disableDelete ||
                                (editing &&
                                    productId === ADMINCENTER_PRODUCT_ID &&
                                    userGroup?.name === ADMINCENTER_GROUP_NAME);

                            return (
                                <Table.TR key={instanceId}>
                                    <Table.TD> {`${productName} (${nickname || instanceName})`} </Table.TD>
                                    <Table.TD> {getRoleNames(roles)} </Table.TD>
                                    <Table.TD textAlign="right">
                                        {/* eslint-disable react/style-prop-object */}
                                        {editing && !hideDelete && (
                                            <ButtonIcon
                                                className={styles["instance-roles-table__delete"]}
                                                iconName="trash-can"
                                                size="small"
                                                style="plain"
                                                onClick={() => handleInstanceRemoval({ instanceIndex })}
                                            />
                                        )}
                                    </Table.TD>
                                </Table.TR>
                            );
                        })}
                    </Table.TBody>
                </Table>
            ) : (
                <Typography type="body" className="div">
                    No products added to group
                </Typography>
            )}
        </>
    );
};

InstanceRolesTable.displayName = "InstanceRolesTable";
