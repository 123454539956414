/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { Button, Dropdown, PaginationControls, Table } from "@optimizely/axiom";

import styles from "./UserList.module.scss";
import { UserForm } from "../../components/UserForm/UserForm";
import { GroupAccessUserForm } from "../../components/UserForm/GroupAccessUserForm";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { useUsers } from "../../../hooks/useUsers/useUsers";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import { User } from "../../../domain/User";
import { ATTRIBUTE_ROLES, DEFAULT_DEBOUNCE, REFETCH_PERMISSIONS_EVENT_NAME } from "../../../constants";
import { MoreMenu } from "../../components/MoreMenu/MoreMenu";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { useFormContext } from "../../components/UserForm/UserFormContext/UserFormContext";
import { useUserContext } from "../../../providers/UserProvider";
import classnames from "classnames";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { ResetFilterPrompt } from "../../components/ResetFilterPrompt/ResetFilterPrompt";
import { datadogRum } from "@datadog/browser-rum";
import { InvitationForm } from "../../components/InvitationForm/InvitationForm";
import { Invitation } from "../../../domain/Invitation";
import { useInvitations } from "../../../hooks/useInvitations/useInvitations";
import { listIsDirty } from "../../../lib/utils";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { useUsersByGroup } from "../../../hooks/useUsersByGroup/useUsersByGroup";
import { ActivationStatusesModal } from "../../components/ActivationStatusesTable/ActivationStatusesModal";
import { emitToast } from "../../../lib/toaster-utils";

const UserList = () => {
    const { profile, organizationId } = useUserContext();
    const { userState, updateUserState } = useFormContext();
    const { createInvitation } = useInvitations({ organizationId });
    const { user: selectedUser } = userState;
    const [userToBeDeleted, setUserToBeDeleted] = useState<User | null>(null);
    const [deletingUser, setDeletingUser] = useState(false);
    const [showUserForm, setShowUserForm] = useState(false);
    const [showActivationStatusesModal, setShowActivationStatusesModal] = useState<boolean>(false);

    const {
        debouncedValue: debouncedSearchQuery,
        value: searchQuery,
        setValue: setSearchQuery
    } = useDebounce<string>("", DEFAULT_DEBOUNCE);
    const { id: currentUserId } = profile || {};
    const { enabled: showLoginStatus } = useFeatureFlag(Flags.SHOW_LOGIN_STATUS);
    const { enabled: invitationFlowEnabled } = useFeatureFlag(Flags.ENABLE_INVITATION_FLOW);
    const { enabled: enableGroupAccessFlow } = useFeatureFlag(Flags.ENABLE_GROUP_ACCESS_FLOW);

    const {
        userList,
        isLoading,
        isSearching,
        error,
        addUser,
        removeUser,
        // can remove if deprecating old user form
        updateUser,
        currentPage,
        setCurrentPage,
        pageSize,
        totalCount
    } = useUsers({
        organizationId: organizationId,
        query: debouncedSearchQuery.length > 2 ? debouncedSearchQuery : "",
        includeExternalStatus: true
    });

    const [groupsToUpdate, setGroupsToUpdate] = useState<string[]>([]);
    const { revalidate: revalidateGroup } = useUsersByGroup({ userGroupId: groupsToUpdate[0] });

    useEffect(() => {
        const [firstGroupToUpdate, ...remainingGroupsToUpdate] = groupsToUpdate;
        if (!firstGroupToUpdate) return;

        revalidateGroup();
        setGroupsToUpdate(remainingGroupsToUpdate);
    }, [groupsToUpdate, revalidateGroup]);

    useEffect(() => {
        setShowUserForm(!!selectedUser);
    }, [selectedUser]);

    useEffect(() => {
        return () => updateUserState({ user: null, userGroups: [] });
    }, [updateUserState]);

    useEffect(() => {
        setCurrentPage(1);
    }, [debouncedSearchQuery, setCurrentPage]);

    const showForm = () => {
        setShowUserForm(true);
    };

    const openViewUser = (userToView: User, editMode?: boolean) => {
        updateUserState({ user: userToView, editing: editMode });
    };

    const handleFormCancel = () => {
        if (selectedUser) {
            updateUserState({ user: null, userGroups: [] });
        } else {
            setShowUserForm(false);
        }
    };

    const goToPage = (page = 1) => {
        handleFormCancel();
        setCurrentPage(page);
    };

    const handleFormCompletion = async ({
        isAdminToUserOrg,
        previousUser,
        updatedUser
    }: {
        isAdminToUserOrg: boolean;
        previousUser: User | null;
        updatedUser: User | null;
    }) => {
        if (!updatedUser) {
            updateUserState({ user: null, userGroups: [] });

            return false;
        }

        if (previousUser) {
            const sendEvent =
                listIsDirty(previousUser.userGroupIds, updatedUser.userGroupIds) && updatedUser.id === profile?.id;

            if (isAdminToUserOrg) {
                await updateUser({ updatedUser });
            }

            const dedupedGroupsToUpdate = Array.from(
                new Set([...previousUser.userGroupIds, ...updatedUser.userGroupIds])
            );
            setGroupsToUpdate(dedupedGroupsToUpdate);

            if (sendEvent) {
                window.dispatchEvent(new CustomEvent(REFETCH_PERMISSIONS_EVENT_NAME));
            }

            updateUserState({ user: null, userGroups: [] });
        } else {
            await addUser(updatedUser);

            setShowUserForm(false);
            updateUserState({ user: null, userGroups: [] });
        }

        return true;
    };

    const handleInviteFormCompletion = async ({ newInvitation }: { newInvitation: Invitation | null }) => {
        if (!newInvitation) {
            return false;
        }

        await createInvitation(newInvitation);
        setShowUserForm(false);
        return true;
    };

    const handleDeletion = () => {
        if (userToBeDeleted) {
            setDeletingUser(true);
            removeUser({ user: userToBeDeleted })
                .then(() => {
                    const removedIndex = userList!.findIndex((user) => user.email === userToBeDeleted?.email);
                    userList!.splice(removedIndex, 1);
                    setDeletingUser(false);
                    setUserToBeDeleted(null);
                    emitToast({ message: "User successfully deleted." });
                })
                .catch((error) => {
                    setDeletingUser(false);
                    datadogRum.addError(error);
                });
        }
    };

    const displayRecord = (user: User) => {
        const { firstName, email, id, lastName, properties = [] } = user;
        const activationStatus =
            properties.find((p) => p.name?.toLowerCase() === "activateuser")?.value === "true" ? "Enabled" : "Disabled";
        const activationStatusClasses = classnames(styles["user-list__activation-status"], {
            [styles["user-list__activation-status--active"]]: activationStatus === "Enabled"
        });
        return (
            <Table.TR key={id} isHighlighted={selectedUser?.id === id && showUserForm}>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {firstName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {lastName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button className="link button-as-link" type="button" onClick={() => openViewUser(user)}>
                        {email}
                    </button>
                </Table.TD>
                {showLoginStatus && (
                    <Table.TD>
                        <span className={activationStatusClasses}>{activationStatus}</span>
                    </Table.TD>
                )}
                <Table.TD>
                    {user.created.toLocaleString("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "short"
                    })}
                </Table.TD>
                <LimitByRole action={ATTRIBUTE_ROLES.USERS.UPDATE} mode="hide">
                    <Table.TD colSpan={1} className="user-manager__table--more-menu">
                        <MoreMenu>
                            <Dropdown.ListItem>
                                <Dropdown.BlockLink onClick={() => openViewUser(user, true)}>
                                    {enableGroupAccessFlow ? "View user..." : "Edit user..."}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>

                            {user.id !== currentUserId && (
                                <Dropdown.ListItem isDisabled>
                                    <Dropdown.BlockLink onClick={() => setUserToBeDeleted(user)}>
                                        <span className="danger">Delete user...</span>
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            )}
                        </MoreMenu>
                    </Table.TD>
                </LimitByRole>
            </Table.TR>
        );
    };

    const totalPages = Math.ceil(totalCount / pageSize);
    const showPagination = totalPages >= 2;

    if (isLoading && !isSearching) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching user.</div>;

    const tableClasses = classnames("list-table flex flex--column", styles["user-list__table"]);
    return (
        <LimitByRole mode="unauthorized" action={ATTRIBUTE_ROLES.USERS.READ}>
            <div className={`list-page ${styles["user-list"]}`}>
                <div className={styles["user-list__bar"]}>
                    <SearchInput
                        className={styles["user-list__search"]}
                        placeholder="Search users by name or email..."
                        type="text"
                        value={searchQuery}
                        onChange={({ value }) => {
                            setSearchQuery(value);
                        }}
                    />
                    {invitationFlowEnabled && (
                        <LimitByRole action={ATTRIBUTE_ROLES.INVITATIONS.CREATE} mode="hide">
                            <div className={styles["user-list__invite-user"]}>
                                <Button style="highlight" onClick={showForm}>
                                    Invite User...
                                </Button>
                            </div>
                        </LimitByRole>
                    )}
                </div>

                <Sidebar
                    heading={selectedUser ? "User" : "Invite User"}
                    isOpen={showUserForm || !!selectedUser}
                    onCancel={handleFormCancel}
                >
                    <>
                        {!selectedUser ? (
                            <InvitationForm onCancel={handleFormCancel} onCreate={handleInviteFormCompletion} />
                        ) : enableGroupAccessFlow ? (
                            <GroupAccessUserForm
                                onCancel={handleFormCancel}
                                user={selectedUser}
                                showModal={setShowActivationStatusesModal}
                            />
                        ) : (
                            <UserForm onCancel={handleFormCancel} onSubmit={handleFormCompletion} />
                        )}
                    </>
                </Sidebar>

                <div className={tableClasses}>
                    {isSearching && <LoadingIndicator height="100%" type="spinner" overlay />}
                    {debouncedSearchQuery.length > 2 && !userList?.length && (
                        <ResetFilterPrompt onClick={() => setSearchQuery("")} prompt="No users match your filter." />
                    )}
                    {userList && userList.length > 0 && (
                        <>
                            <div className="user-manager__table">
                                <Table className="push-double--bottom" density="loose" style="rule-no-bottom-border">
                                    <Table.THead>
                                        <Table.TR>
                                            <Table.TH width="25%">First Name</Table.TH>
                                            <Table.TH width="25%">Last Name</Table.TH>
                                            <Table.TH width="40%">Email</Table.TH>
                                            {showLoginStatus && <Table.TH width="15%">Status</Table.TH>}
                                            <Table.TH width="15%">Created</Table.TH>
                                            <Table.TH width="60px"> </Table.TH>
                                        </Table.TR>
                                    </Table.THead>
                                    <Table.TBody>{userList.map((user) => displayRecord(user))}</Table.TBody>
                                </Table>
                            </div>
                            {showPagination && (
                                <PaginationControls
                                    className="anchor--bottom"
                                    currentPage={currentPage}
                                    goToPage={(page: number) => goToPage(page)}
                                    totalPages={totalPages}
                                />
                            )}
                        </>
                    )}
                </div>

                {showActivationStatusesModal && <ActivationStatusesModal showModal={setShowActivationStatusesModal} />}

                {!!userToBeDeleted && (
                    <ConfirmationDialog
                        callToActionText="Delete"
                        loading={deletingUser}
                        onCancel={() => setUserToBeDeleted(null)}
                        onConfirmation={handleDeletion}
                        title="Delete User"
                    >
                        Are you sure you want to delete this user?
                    </ConfirmationDialog>
                )}
            </div>
        </LimitByRole>
    );
};

export default UserList;
