import { useEffect, useMemo, useReducer, useRef } from "react";
import { useProducts } from "../../../../hooks/useProducts/useProducts";
import { useUserContext } from "../../../../providers/UserProvider";
import { ProductDropdown } from "../../ProductDropdown/ProductDropdown";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../../domain/OrganizationProduct";
import styles from "./AddProductAccessForm.module.scss";
import { InstanceDropdown } from "../../InstanceDropdown/InstanceDropdown";
import classnames from "classnames";
import { AccessManagementUserFormIconButton } from "../AccessManagementUserForm/AccessManagementUserFormIconButton";
import { RoleSelector } from "../../RoleSelector/RoleSelector";
import { Role } from "../../../../domain/Role";
import { ATTRIBUTE_ROLES, CONTEXT_SCOPES, GROUP_TYPES } from "../../../../constants";
import { ProductAttribute } from "../../../../domain/ProductAttribute";
import { useAttributes } from "../../../../hooks/useAttributes/useAttributes";
import { ProjectsDropdown } from "../../ProjectsDropdown/ProjectsDropdown";
import { User } from "../../../../domain/User";
import { useAccessList } from "../../../../hooks/useAccessList/useAccessList";
import { findByField, getEntitledProductRoles, getProductFromInstanceId } from "../../../../lib/access-helpers";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../../feature-flags/flags";
import { UserGroup } from "../../../../domain/UserGroup";

export type AddProductAccessPermission = {
    product?: OrganizationProduct;
    instance?: IOrganizationProductInstance;
    group: UserGroup;
    role: Role;
    project?: ProductAttribute;
};

type AddProductAccessFormProps = {
    className?: string;
    instance?: IOrganizationProductInstance | undefined;
    onSubmit: ({ permission }: { permission: AddProductAccessPermission }) => void;
    onClose: ({ refetchAccess }: { refetchAccess?: boolean }) => void;
    product?: OrganizationProduct | undefined;
    project?: ProductAttribute | undefined;
    role?: Role | undefined;
    user?: User;
    isSaving?: boolean;
};

type AccessFieldsState = {
    product: OrganizationProduct | undefined;
    instance: IOrganizationProductInstance | undefined;
    project?: ProductAttribute;
    role: Role | undefined;
};

type AccessFieldsAction = {
    field: "product" | "instance" | "reset" | "role" | "project" | "multi";
    value?:
        | OrganizationProduct
        | IOrganizationProductInstance
        | Role
        | ProductAttribute
        | {
              product?: OrganizationProduct;
              instance?: IOrganizationProductInstance;
              role?: Role;
              project?: ProductAttribute;
          };
};

const accessFieldsReducer = (state: AccessFieldsState, action: AccessFieldsAction): AccessFieldsState => {
    switch (action.field) {
        case "instance":
            return {
                ...state,
                instance: action.value as IOrganizationProductInstance,
                project: undefined,
                role: undefined
            };
        case "product":
            return { ...state, product: action.value as OrganizationProduct };
        case "project":
            return { ...state, project: action.value as ProductAttribute };

        case "reset":
            return {
                product: action.value ? (action.value as OrganizationProduct) : undefined,
                instance: undefined,
                role: undefined
            };
        case "role":
            return { ...state, role: action.value as Role };
        case "multi":
            return { ...state, ...action.value };
        default:
            return state;
    }
};

export const AddProductAccessForm = ({
    className,
    instance,
    onSubmit,
    onClose,
    product,
    role,
    user,
    isSaving
}: AddProductAccessFormProps) => {
    const { accessContext, organizationId, profile, canUserDoAction } = useUserContext();
    const { email: actingUserEmail } = profile || {};
    const { products = [], instances = [] } = useProducts({ organizationId });
    const { enabled: filterRolesPerEntitlements } = useFeatureFlag(Flags.FILTER_GROUPS_AND_ROLES_PER_ENTITLEMENTS);

    const {
        accessList,
        organizationGroups: organizationProductPermissions,
        isLoading: accessListLoading,
        userAccessLoading
    } = useAccessList({
        organizationId,
        email: actingUserEmail
    });

    const initialState: AccessFieldsState = {
        product: product,
        instance: instance,
        role: role
    };

    const [accessFields, updateFieldValue] = useReducer(accessFieldsReducer, initialState);
    const { product: productField, instance: instanceField, role: roleField, project: projectField } = accessFields;
    const loadedContextAwareState = useRef(false);

    const {
        attributes: projects,
        isLoadingInitialData: projectsLoading,
        getAttribute,
        loadNext
    } = useAttributes({
        productId: productField?.id,
        instanceId: instanceField?.id
    });
    const userAccessibleInstances = useMemo(() => {
        return (
            accessList?.find((item) => item.productId === productField?.id)?.instanceAccess?.map((ia) => ia.instance) ||
            []
        );
    }, [accessList, productField?.id]);
    const instanceAccessForSelectedProduct = organizationProductPermissions?.find(
        (perm) => perm.productId === productField?.id
    );
    const instanceAccessForSelectedInstance = instanceAccessForSelectedProduct?.instanceAccess
        ?.map((ia) => {
            ia.availableRoles = ia.availableRoles.filter((role) => {
                return role.group.groupType === GROUP_TYPES.PRODUCT;
            });
            return ia;
        })
        .find((ia) => ia.instance.id === instanceField?.id);

    const uniqueRoleIds = new Set(); // Create a Set to track unique role ids
    const roles =
        instanceAccessForSelectedInstance?.availableRoles
            ?.map((ar) => ar.role)
            ?.filter((role) => {
                if (uniqueRoleIds.has(role.id)) {
                    return false;
                }

                // if a project is selected, filter out any role that is not tied to the project
                const roleForProject =
                    !projectField?.id || role.attributes?.some((attr) => attr.id === projectField.id);
                return roleForProject;
            }) || [];

    let adjustedRoles = roles;
    if (filterRolesPerEntitlements) {
        adjustedRoles = getEntitledProductRoles({
            products,
            instances,
            roles
        });
    }

    useEffect(() => {
        if (!accessContext?.scope) {
            updateFieldValue({ field: "reset" });
        }
    }, [user, accessContext?.scope]);

    // auto select the instance if there is only one
    useEffect(() => {
        if (
            productField?.id &&
            userAccessibleInstances?.length === 1 &&
            instanceField?.id !== userAccessibleInstances[0].id
        ) {
            updateFieldValue({
                field: "instance",
                value: userAccessibleInstances[0] as IOrganizationProductInstance
            });
        }
    }, [instanceField?.id, productField?.id, userAccessibleInstances]);

    // auto select the role if there is only one
    useEffect(() => {
        if (
            instanceField?.id &&
            productField?.id &&
            adjustedRoles?.length === 1 &&
            roleField?.id !== adjustedRoles[0].id
        ) {
            updateFieldValue({
                field: "role",
                value: adjustedRoles[0] as Role
            });
        }
    }, [roleField?.id, adjustedRoles, productField?.id, instanceField?.id]);

    useEffect(() => {
        const { scope, items = [] } = accessContext || {};

        const productFromScope = products?.find((p) => p.id === items[0]?.id);
        if (
            scope === CONTEXT_SCOPES.PRODUCT &&
            items?.length &&
            productField?.id !== items[0]?.id &&
            productFromScope &&
            !loadedContextAwareState.current
        ) {
            // handle product context
            updateFieldValue({
                field: "reset",
                value: productFromScope
            });
            loadedContextAwareState.current = true;
        } else if (scope === CONTEXT_SCOPES.INSTANCE && items?.length && !loadedContextAwareState.current) {
            // look up the product from the instance id
            const instanceId = items[0].id;
            const productFromInstance = getProductFromInstanceId({ instanceId, products });
            const instanceDetails = findByField({ value: instanceId, items: userAccessibleInstances, field: "id" });
            updateFieldValue({
                field: "reset",
                value: productFromInstance
            });

            // if the user does not have access to the instance they are viewing for some reason,
            // we shouldn't allow them to do the preselection with the instance. This may result in a
            // "No instances" state but should be addressed (ie why is the user allowed to view this instance?)
            if (!instanceDetails) return;
            updateFieldValue({
                field: "instance",
                value: instanceDetails
            });
            loadedContextAwareState.current = true;
        } else if (scope === CONTEXT_SCOPES.PROJECT && items?.length && !loadedContextAwareState.current) {
            // look up the experimentation product and instance from the project id
            const projectId = items[0].id;
            const experimentationProduct = products?.find(
                (p) => p.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID
            );
            // call this if the exp product hasn't been selected
            if (experimentationProduct && productField?.id !== experimentationProduct?.id) {
                updateFieldValue({ field: "reset", value: experimentationProduct });
            }

            // we need to get the project currently in context as the project list returned
            // by the useAttributes hook is empty (due to instanceId being undefined at initial mount)
            getAttribute(projectId)
                .then((project) => {
                    // we should now have the updated user accessible instances and the project
                    const projectInstance = userAccessibleInstances?.find((i) => i.id === project?.instanceId);
                    if (projectInstance) {
                        updateFieldValue({
                            field: "multi",
                            value: {
                                instance: projectInstance,
                                project: project as ProductAttribute
                            }
                        });
                        loadedContextAwareState.current = true;
                    } else {
                        loadNext(); // load the next page of instances to see if there's potentially more pages
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [accessContext, loadNext, productField?.id, products, projects, user, userAccessibleInstances, getAttribute]);

    const handleProductChange = ({ product: selectedProduct }: { product: OrganizationProduct }) => {
        updateFieldValue({ field: "reset", value: selectedProduct });
    };

    const handleInstanceChange = ({
        value: instance
    }: {
        value: IOrganizationProductInstance | IOrganizationProductInstance[] | null;
    }) => {
        updateFieldValue({
            field: "instance",
            value: instance as IOrganizationProductInstance
        });
    };

    const handleRoleChange = (value: Role) => {
        updateFieldValue({
            field: "role",
            value: value as Role
        });
    };

    const handleProjectChange = ({ value }: { value: ProductAttribute | ProductAttribute[] | null }) => {
        updateFieldValue({
            field: "project",
            value: value as ProductAttribute
        });
    };

    const handleOnSubmit = () => {
        const { instance, availableRoles } = instanceAccessForSelectedInstance || {};
        const selectedRole = availableRoles?.find((ar) => ar.role.id === roleField!.id);
        if (selectedRole && instance) {
            onSubmit({
                permission: {
                    product: productField!,
                    instance,
                    ...selectedRole
                }
            });
        }
    };

    const isExp = accessFields?.product?.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    const allowedProjects = projects?.filter(
        (p) =>
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.GROUPS.MANAGE, ATTRIBUTE_ROLES.GROUPS.UPDATE],
                context: { AttributeId: p.id }
            }) ||
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.GROUPS.MANAGE, ATTRIBUTE_ROLES.GROUPS.UPDATE],
                context: { InstanceId: accessFields.instance?.id }
            }) ||
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.GROUPS.MANAGE, ATTRIBUTE_ROLES.GROUPS.UPDATE],
                context: { ProductId: accessFields.product?.id }
            })
    );

    const productDropdownClasses = classnames(
        "dropdown-inherit-width",
        styles["add-user-access-form__product-dropdown"]
    );
    const dropdownClasses = classnames(
        "dropdown-inherit-width",
        styles["add-user-access-form__selection-row-dropdown"],
        {
            [styles["add-user-access-form__selection-row-dropdown--small"]]: isExp
        }
    );

    const shouldDisableSave = !(productField && instanceField && roleField);
    const instancesLoading = !!productField?.id && (accessListLoading || userAccessLoading);

    return (
        <div className={classnames(className, styles["add-user-access-form"])}>
            <ProductDropdown
                disabled={isSaving}
                className={productDropdownClasses}
                onChange={handleProductChange}
                products={products}
                white
                value={productField?.id}
            />
            <div className={styles["add-user-access-form__selection-row"]}>
                <InstanceDropdown
                    className={dropdownClasses}
                    disabled={!productField || isSaving || userAccessLoading || accessListLoading}
                    loading={instancesLoading || !!projectsLoading}
                    instances={userAccessibleInstances}
                    onChange={handleInstanceChange}
                    value={instanceField?.id}
                    white
                />
                {isExp && (
                    <ProjectsDropdown
                        className={dropdownClasses}
                        disabled={!instanceField?.id || isSaving}
                        loading={!!projectsLoading || instancesLoading}
                        onChange={handleProjectChange}
                        projects={allowedProjects}
                        value={projectField?.id}
                        white
                    />
                )}
                <RoleSelector
                    isDisabled={
                        !instanceField?.id ||
                        isSaving ||
                        (isExp && !projectField?.id) ||
                        instancesLoading ||
                        !!projectsLoading
                    }
                    loading={
                        !instanceField?.id ||
                        (!!userAccessibleInstances?.length && (instancesLoading || !!projectsLoading))
                    }
                    className={dropdownClasses}
                    selectedRole={roleField}
                    fullWidth={false}
                    onRoleChanged={handleRoleChange}
                    dropdownOptions={adjustedRoles}
                />
                <div className={`${styles["add-user-access-form__actions"]}`}>
                    <AccessManagementUserFormIconButton
                        disabled={shouldDisableSave}
                        white={!isSaving}
                        icon="check"
                        onClick={handleOnSubmit}
                        loading={isSaving}
                    />
                    <AccessManagementUserFormIconButton
                        className="push--left"
                        disabled={isSaving}
                        icon="close"
                        onClick={() => onClose({})}
                    />
                </div>
            </div>
        </div>
    );
};

AddProductAccessForm.displayName = "AddProductAccessForm";

AddProductAccessForm.whyDidYouRender = true;
