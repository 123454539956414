import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Attention, Button, Dropdown, Icon, Poptip, Typography } from "@optimizely/axiom";
import { useForm } from "react-hook-form";
import styles from "./RoleForm.module.scss";
import classnames from "classnames";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../domain/OrganizationProduct";
import { SearchInput } from "../SearchInput/SearchInput";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import {
    CUSTOMER_DEFINED_PRODUCT_ROLE,
    EXP_PERMISSIONS_KEY,
    SYSTEM_ROLE_TYPE,
    ADMINCENTER_PRODUCT_ID,
    ATTRIBUTE_ROLES,
    ALL_INSTANCES_OPTION,
    TOGGLE_ALL_INSTANCES_OPTION,
    CUSTOMER_DEFINED_INSTANCE_ROLE
} from "../../../constants";
import { Role } from "../../../domain/Role";
import { getFormattedDate } from "../../../lib/date-formatter";
import { arrayObjectsDeepEquals, listIsDirty } from "../../../lib/utils";
import { useUserContext } from "../../../providers/UserProvider";
import { RoleAttributeSelections } from "./RoleAttributeSelections";
import { IProductAttribute } from "../../../domain/ProductAttribute.interface";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";
import { datadogRum } from "@datadog/browser-rum";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { ExperimentationProjectList } from "../ExperimentationProjectList/ExperimentationProjectList";
import { AdminCenterScopeDropdowns } from "./AdminCenterScopeDropdowns";
import { ProjectDisplayName } from "../ProjectDisplayName/ProjectDisplayName";
import { ProductDropdown } from "../ProductDropdown/ProductDropdown";
import { RoleType, RoleTypeSelection } from "./RoleTypeSelection";
import { emitToast } from "../../../lib/toaster-utils";

export interface IRoleFormValues {
    attributes?: IProductAttribute[];
    description?: string;
    instances: IOrganizationProductInstance[];
    name: string;
    product: OrganizationProduct;
    roleType?: RoleType;
}

type RoleFormProps = {
    allRoles?: Role[];
    onClose: () => void;
    onSubmit: (roleDetails: IRoleFormValues) => Promise<any>;
    onSuccess: ({ role }: { role: Role }) => void;
    products: OrganizationProduct[];
    role?: Role;
};

export const RoleForm = ({ allRoles: roles, onClose, onSubmit, onSuccess, products, role }: RoleFormProps) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [adminCenterScopes, setAdminCenterScopes] = useState<{ name: string; value: string }[]>();
    const [selectedAttributes, setSelectedAttributes] = useState<any[]>([]);
    const { organizationId } = useUserContext();
    const [instanceSearchText, setInstanceSearchText] = useState("");
    const [selectedProduct, setSelectedProduct] = useState<OrganizationProduct | null>(null);
    const [selectedInstances, setSelectedInstances] = useState<IOrganizationProductInstance[]>([]);
    const [filteredInstances, setFilteredInstances] = useState(selectedProduct?.instances || []);
    const { enabled: attributePermissionsEnabled } = useFeatureFlag(Flags.USE_ATTRIBUTE_PERMISSIONS);
    const { enabled: disableCustomRoleCreation, variables: disabledProductsRoleCreation } = useFeatureFlag(
        Flags.DISABLE_CUSTOM_ROLE_CREATION
    );
    const { enabled: explicitRoleTypeSelection } = useFeatureFlag(Flags.ENABLE_EXPLICIT_ROLE_TYPE_SELECTION);

    const productsThatAllowCustomRoles = useMemo(
        () =>
            products.filter((p) => {
                const disabledProduct =
                    disableCustomRoleCreation &&
                    (disabledProductsRoleCreation as any)["disabled_products"].products
                        .map((i: any) => i.id)
                        .includes(p.id);

                return p.allowCustomerDefinedRoles && !disabledProduct;
            }),
        [products, disabledProductsRoleCreation, disableCustomRoleCreation]
    );

    const currentRole = useRef(role);

    const isExp = selectedProduct?.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
    const isAdminCenter = selectedProduct?.id === ADMINCENTER_PRODUCT_ID;
    const { attributes } = useAttributes({
        isActive: true,
        key: isExp ? EXP_PERMISSIONS_KEY : undefined,
        productId: selectedProduct?.id
    });

    const {
        register,
        handleSubmit,
        setError,
        getValues,
        clearErrors,
        setValue,
        formState: { isDirty, isValid, errors },
        watch
    } = useForm<IRoleFormValues>({
        mode: "onChange",
        defaultValues: {
            name: role?.name,
            description: role?.description
        }
    });
    const roleType = watch("roleType");

    const appliesToAllInstances = ({ role }: { role?: Role }) => {
        return [CUSTOMER_DEFINED_PRODUCT_ROLE, SYSTEM_ROLE_TYPE].indexOf(role?.roleType || "") > -1;
    };

    const setInstanceToAll = () => {
        if (explicitRoleTypeSelection) {
            // behave as a "toggle all on" when the explicit role type selection is on
            setSelectedInstances(filteredInstances);
        } else if (selectedInstances.length === 1 && selectedInstances[0].name === ALL_INSTANCES_OPTION.name) {
            // legacy behavior
            setSelectedInstances([]);
        } else {
            // legacy behavior
            setSelectedInstances([ALL_INSTANCES_OPTION]);
        }
    };

    useEffect(() => {
        if (!role) {
            return;
        }
        setValue("name", role.name);
        setValue("description", role.description);
        const product = products.find((p) => p.id === role.productId)!;
        setSelectedProduct(product);
    }, [products, role, setValue]);

    useEffect(() => {
        if (role !== currentRole.current) {
            currentRole.current = role;
            setEditing(false);
        }
    }, [role]);

    useEffect(() => {
        if (editing && role) {
            setSelectedAttributes(role.attributes);
        } else {
            setSelectedAttributes([]);
        }
    }, [editing, role]);

    // TODO: Handle searching when not all instances have been fetched?
    useEffect(() => {
        const filtered = (selectedProduct?.instances || []).filter((i) => {
            return (
                i.nickname?.toLocaleLowerCase().includes(instanceSearchText) ||
                i.name?.toLowerCase().includes(instanceSearchText)
            );
        });
        setFilteredInstances(filtered);
    }, [instanceSearchText, selectedProduct?.instances]);

    useEffect(() => {
        if (role && selectedProduct && editing) {
            if (appliesToAllInstances({ role })) {
                setSelectedInstances([ALL_INSTANCES_OPTION]);
            } else {
                const instances = selectedProduct.instances.filter((i) => (role.targetIds || []).indexOf(i.id) > -1);
                setSelectedInstances(instances);
            }
        } else {
            setFilteredInstances(selectedProduct?.instances || []);
            // reset instances when the product is changed.
            if ((isExp || isAdminCenter) && selectedProduct?.instances.length === 1) {
                setSelectedInstances(selectedProduct?.instances);
            } else {
                setSelectedInstances([]);
            }
        }
    }, [role, selectedProduct, isExp, isAdminCenter, editing]);

    useEffect(() => {
        clearErrors("name");
    }, [clearErrors, role?.id]);

    // Filter products down based on the allowCustomerDefinedRoles property on the product
    products = products.filter((product) => product.allowCustomerDefinedRoles);

    const adminCenterProductIndex = products.findIndex((p) => p.id === ADMINCENTER_PRODUCT_ID);
    if (!attributePermissionsEnabled && adminCenterProductIndex !== -1) {
        // Remove admin center product from the dropdown if  attribute based permissions feature flag is turned off.
        products.splice(adminCenterProductIndex, 1);
    }

    const adaptDuplicateRoleError = ({ field, message }: { field: any; message: any }) => {
        if (field === "Name, ProductId") {
            return {
                field: "name",
                message: "A role with this name already exists for this product."
            };
        }
        return { field, message };
    };

    const handleFormSubmission = () => {
        setLoading(true);
        const { name, description, roleType } = getValues();
        const roleDetails: IRoleFormValues = {
            name,
            description,
            product: selectedProduct!,
            instances: selectedInstances,
            roleType
        };

        if (selectedAttributes.length !== 1 || selectedAttributes[0] !== "N/A") {
            /*
             * When duplicating a role - the role is the GET which only has `values` but the post requires
             * `selectedValues`. Additionally, the `key` value which is required might be missing so here
             * we try to find the corresponding key so the call doesn't fail for the invalid payloads.
             */
            if (selectedAttributes?.some((attr) => !!attr.values)) {
                // @ts-ignore
                roleDetails.attributes = selectedAttributes
                    .filter((a) => !!a.values)
                    .map((attr) => {
                        const correspondingKey = attributes?.find((attribute) => {
                            return attr.values?.every((value: string) => attribute.values.indexOf(value) > -1);
                        })?.key;
                        const { id, key, values: selectedValues } = attr;

                        return {
                            id,
                            key: key || correspondingKey,
                            selectedValues,
                            scopes: adminCenterScopes || []
                        };
                    });
            } else {
                roleDetails.attributes = selectedAttributes;
            }
        }

        onSubmit(roleDetails)
            .then((newRole: Role) => {
                emitToast({ message: `Custom role ${editing ? "saved" : "created"} successfully` });
                setLoading(false);
                onSuccess({ role: newRole });
            })
            .catch((e) => {
                const errors = Array.isArray(e) ? e : [e];
                errors.forEach((error: any) => {
                    const { field, message } = error || {};
                    if (!field) {
                        datadogRum.addError(e);
                        setFormError(message);
                    } else {
                        const { field: adaptedField, message: adaptedMessage } = adaptDuplicateRoleError({
                            field,
                            message
                        });
                        setError(adaptedField.toLowerCase(), {
                            type: `invalid${adaptedField}`,
                            message: adaptedMessage
                        });
                    }
                });
                setLoading(false);
            });
    };

    const descriptionFieldClasses = classnames("oui-text-input", styles["role-form__description"], {
        "oui-form-bad-news": !!errors.description
    });

    const onScopeSelectionsChanged = useCallback((scopes: { name: string; value: string }[]) => {
        setAdminCenterScopes(scopes);
    }, []);

    const toggleInstanceSelection = ({ instance }: { instance: IOrganizationProductInstance }) => {
        if (isExp) {
            setSelectedInstances([instance]);
        } else {
            const updatedInstances = [...selectedInstances];
            const existingIndex = updatedInstances.findIndex((i) => i.id === instance.id);
            const allIndex = updatedInstances.findIndex((i) => i.name === ALL_INSTANCES_OPTION.name);

            // remove the "All" selection if it is selected
            if (allIndex > -1) {
                updatedInstances.splice(allIndex, 1);
            }
            if (existingIndex > -1) {
                updatedInstances.splice(existingIndex, 1);
            } else {
                updatedInstances.push(instance);
            }

            setSelectedInstances(updatedInstances);
        }
    };

    const disableSubmit = () => {
        const instancesChanged = listIsDirty(
            role?.targetIds || [],
            selectedInstances.map((i) => {
                if (i.name === ALL_INSTANCES_OPTION.name) {
                    return organizationId || "";
                }
                return i.id;
            })
        );
        const attributesChanged =
            role && selectedAttributes
                ? !arrayObjectsDeepEquals({
                      list1: role?.attributes || [],
                      list2: selectedAttributes || [],
                      keysToCheck: ["values"]
                  })
                : false;

        const scopesChanged =
            role && isAdminCenter && adminCenterScopes
                ? !arrayObjectsDeepEquals({
                      list1: adminCenterScopes,
                      list2: roleScopes || [],
                      key: "value",
                      keysToCheck: ["name", "value"]
                  })
                : false;

        const nonExplicitRoleSelectionInvalidForm =
            !explicitRoleTypeSelection &&
            (!selectedInstances.length || !(isDirty || instancesChanged || attributesChanged || scopesChanged));
        const explicitRoleSelectionInvalidForm =
            !roleType || (roleType !== CUSTOMER_DEFINED_PRODUCT_ROLE && !selectedInstances.length);
        const invalidFormSelections = explicitRoleTypeSelection
            ? explicitRoleSelectionInvalidForm
            : nonExplicitRoleSelectionInvalidForm;

        return loading || invalidFormSelections || !isValid;
    };

    const nameFieldClasses = classnames("oui-text-input", {
        "oui-form-bad-news": !!errors.name
    });

    const nameLabelClasses = classnames("oui-label push--top", {
        "oui-form-bad-news": !!errors.name
    });

    const handleAttributeChange = ({
        id,
        key,
        preMappedValues,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => {
        if (preMappedValues) {
            setSelectedAttributes(values);
            return;
        }
        if (!id) {
            setSelectedAttributes(values);
            return;
        }
        const updated = [...selectedAttributes];
        const keyIndex = updated.findIndex((i) => i.id === id);
        const attributeValue = { id, key, selectedValues: values, values };
        if (keyIndex === -1) {
            updated.push(attributeValue);
        } else if (!values?.length) {
            // attribute completely removed
            updated.splice(keyIndex, 1);
        } else {
            updated[keyIndex] = attributeValue;
        }
        setSelectedAttributes(updated);
    };

    const getRoleInstanceDetails = ({ role }: { role: Role }) => {
        const instances = products.flatMap((p) => p.instances);
        return role.targetIds.map((id) => {
            return instances.find((i) => i.id === id);
        });
    };

    const selectedInstanceTarget = editing || !role ? selectedInstances : getRoleInstanceDetails({ role: role! });
    const instanceSelectionsText = appliesToAllInstances({ role })
        ? ALL_INSTANCES_OPTION.nickname
        : selectedInstanceTarget?.map((i) => i?.nickname || i?.name).join(", ");
    const productDetails = role ? products?.find((p) => p.id === role.productId) : null;

    const [expRolePermissionValue] = isExp
        ? role?.attributes?.find((attr) => attr.key === "permissions")?.values || []
        : [];

    const roleScopes = useMemo(() => (role?.attributes.length ? role.attributes[0].scopes : []), [role]);
    const scopes = Array.from(
        new Map(
            role?.attributes
                .flatMap((att) => att.scopes)
                .filter((i) => !!i)
                .map((item) => [item!["value"], item])
        ).values()
    );

    const getProductName = (id: string) => {
        return products.find((p) => p.id === id)?.name || "Not found.";
    };

    const getInstanceName = (id: string) => {
        return products.flatMap((prod) => prod.instances).find((i) => i.id === id)?.name || "Not found.";
    };

    const handleRoleTypeChange = ({ roleType: selectedType }: { roleType: RoleType }) => {
        if (roleType !== selectedType) {
            setSelectedInstances([]);
            setValue("instances", []);
            setValue("roleType", selectedType);
        }
    };

    const editButtonHoverTipMessage =
        role?.roleType === SYSTEM_ROLE_TYPE
            ? "System roles cannot be edited"
            : role?.isSystemGeneratedRole
            ? "System generated roles cannot be edited"
            : "Edit";

    const isInstanceRole = roleType === CUSTOMER_DEFINED_INSTANCE_ROLE;

    return (
        <form onSubmit={handleSubmit(handleFormSubmission)} className={styles["role-form"]}>
            <div className="soft-quad--sides" id={styles["role-form-content"]}>
                {!role && editing && (
                    <Typography type="header4">
                        <span style={{ fontWeight: 300 }}>Role Details</span>
                    </Typography>
                )}
                {formError && (
                    <Attention alignment="left" className="push--top push--bottom" type="bad-news">
                        {formError}
                    </Attention>
                )}
                <label className={nameLabelClasses} htmlFor="user-group-name">
                    Role Name
                    {!role && editing && <span aria-label="(required)" className="oui-label--required" />}
                </label>
                {role && !editing ? (
                    <Typography type="body" className="label--disabled">
                        {role?.name}
                    </Typography>
                ) : (
                    <>
                        <input
                            aria-describedby="role-name"
                            id="role-name"
                            className={nameFieldClasses}
                            placeholder="Role Name"
                            type="text"
                            {...register("name")}
                        />
                        {errors.name && (
                            <span className="oui-form-note form-note--bad-news" id="role-name-error">
                                {errors.name.message}
                            </span>
                        )}
                    </>
                )}

                <label className="oui-label push-double--top" htmlFor="role-description">
                    Description
                </label>
                {role && !editing ? (
                    <Typography type="body" className="label--disabled">
                        {role?.description}
                    </Typography>
                ) : (
                    <>
                        <textarea
                            rows={5}
                            aria-describedby="role-description"
                            className={descriptionFieldClasses}
                            placeholder="Add a description to help admins/users understand what the role is for"
                            id="role-description"
                            {...register("description")}
                        />
                        {errors.description && (
                            <span className="oui-form-note form-note--bad-news" id="role-name-error">
                                {errors.description.message}
                            </span>
                        )}
                    </>
                )}

                {!explicitRoleTypeSelection && (!role || editing) && (
                    <div className="push-triple--top">
                        <Typography type="header4">
                            <span style={{ fontWeight: 300 }}>
                                Select Product and Instances{" "}
                                <span aria-label="(required)" className="oui-label--required" />
                            </span>
                        </Typography>
                    </div>
                )}
                <div className="flex flex-justified--between flex-align--center">
                    <span className="oui-label push-double--top">Product</span>
                    <Poptip trigger="mouseenter" content="Not all products allow custom roles" className="">
                        <Icon name="circle-info" size="small" color="var(--axiom-brand-primary-color)" />
                    </Poptip>
                </div>
                {role && !editing ? (
                    <Typography type="body" className="label--disabled">
                        {productDetails?.name}
                    </Typography>
                ) : (
                    <ProductDropdown
                        fullWidth
                        onChange={({ product }) => setSelectedProduct(product)}
                        products={productsThatAllowCustomRoles}
                        white
                        value={selectedProduct?.id || "Select product..."}
                    />
                )}

                {explicitRoleTypeSelection && (!role || editing) && (
                    <RoleTypeSelection
                        disabled={!!role || !selectedProduct}
                        className="push-triple--top"
                        onChange={handleRoleTypeChange}
                        value={role?.roleType as RoleType}
                    />
                )}

                {role && !editing ? (
                    <>
                        <span className="oui-label push-double--top">Instance</span>
                        <Typography type="body" className="label--disabled">
                            {instanceSelectionsText}
                        </Typography>
                        {role?.attributes.length > 0 && (
                            <div>
                                <span className="oui-label push-double--top">Attributes</span>
                                <Typography type="body" className="label--disabled">
                                    {role.attributes.map((attribute) => {
                                        return !!attribute.values?.length ? (
                                            <div key={attribute.id}>
                                                {attribute.key && attribute.values?.length ? (
                                                    <strong>{attribute.key}:&nbsp;</strong>
                                                ) : (
                                                    <span>{attribute.key}</span>
                                                )}
                                                {attribute.values.join(", ")}
                                            </div>
                                        ) : (
                                            <div key={attribute.id}>
                                                <strong>{attribute.key}</strong>
                                            </div>
                                        );
                                    })}
                                </Typography>
                                {scopes.length > 0 && (
                                    <>
                                        <span className="oui-label push-double--top">Restrictions</span>
                                        <Typography type="body" className="label--disabled">
                                            {scopes.map((scope) => {
                                                return (
                                                    <>
                                                        {scope?.name === "ProductId" && (
                                                            <div>Product: {getProductName(scope?.value)}</div>
                                                        )}
                                                        {scope?.name === "InstanceId" && (
                                                            <div>Instance: {getInstanceName(scope?.value)}</div>
                                                        )}
                                                        {scope?.name === "AttributeId" && scope?.value && (
                                                            <div>
                                                                <span>Project: </span>
                                                                <ProjectDisplayName attributeId={scope?.value} />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        )}
                        {isExp && !editing && <ExperimentationProjectList role={role} />}
                        <span className="oui-label push-double--top">Created</span>
                        <Typography type="body" className="label--disabled">
                            {role?.modified ? getFormattedDate({ date: role?.modified, hideTimestamp: true }) : ""}
                        </Typography>

                        <span className="oui-label push-double--top">Modified</span>
                        <Typography type="body" className="label--disabled">
                            {role?.modified ? getFormattedDate({ date: role?.modified, hideTimestamp: true }) : ""}
                        </Typography>
                    </>
                ) : (
                    <>
                        {!explicitRoleTypeSelection && (
                            <span className="oui-label push--top">
                                Instances this role will be assigned to.{" "}
                                {!isExp && (
                                    <>
                                        Can be one or many. Select "All" to indicate that this role should apply to all
                                        current and future instances (similar to a System role).
                                    </>
                                )}
                            </span>
                        )}
                        <Dropdown
                            arrowIcon="down"
                            isDisabled={
                                isAdminCenter || !selectedProduct || (explicitRoleTypeSelection && !isInstanceRole)
                            }
                            fullWidth
                            buttonContent={
                                isExp || isAdminCenter
                                    ? selectedInstances.map((i) => i.name).join(", ") || "Select product instance..."
                                    : explicitRoleTypeSelection
                                    ? "Select Instances..."
                                    : "Select product instances..."
                            }
                            className={styles["role-form__dropdown"]}
                            shouldHideChildrenOnClick={isExp}
                        >
                            <Dropdown.Contents>
                                {(selectedProduct?.instances || []).length > 10 && (
                                    <SearchInput
                                        placeholder="Search product instances..."
                                        type="text"
                                        className="push"
                                        value={instanceSearchText}
                                        onChange={({ value }) => {
                                            setInstanceSearchText(value?.toLowerCase());
                                        }}
                                    />
                                )}
                                {!instanceSearchText && !isExp && !isAdminCenter && (
                                    <Dropdown.ListItem key="ALL">
                                        <Dropdown.BlockLink
                                            onClick={setInstanceToAll}
                                            isMultiSelect
                                            isItemSelected={
                                                (explicitRoleTypeSelection &&
                                                    filteredInstances?.length === selectedInstances?.length) ||
                                                !!selectedInstances.find((i) => i.name === ALL_INSTANCES_OPTION.name)
                                            }
                                        >
                                            {explicitRoleTypeSelection
                                                ? TOGGLE_ALL_INSTANCES_OPTION.name
                                                : ALL_INSTANCES_OPTION.name}
                                        </Dropdown.BlockLink>
                                    </Dropdown.ListItem>
                                )}
                                {!filteredInstances.length && <div className="push">No Results</div>}
                                {filteredInstances.map((instance: IOrganizationProductInstance) => {
                                    const { id, name, nickname } = instance;
                                    return (
                                        <Dropdown.ListItem key={id}>
                                            <Dropdown.BlockLink
                                                onClick={() => toggleInstanceSelection({ instance })}
                                                isMultiSelect={!isExp}
                                                isItemSelected={!!selectedInstances.find((i) => i.id === id)}
                                            >
                                                {nickname || name}
                                            </Dropdown.BlockLink>
                                        </Dropdown.ListItem>
                                    );
                                })}
                            </Dropdown.Contents>
                        </Dropdown>
                        {!isExp && !!selectedInstances?.length && (
                            <span
                                title={instanceSelectionsText}
                                className={classnames("oui-label push--top", styles["role-form__instance-selections"])}
                                data-testid="instance-selections"
                            >
                                {instanceSelectionsText}
                            </span>
                        )}
                    </>
                )}

                {(editing || !role) && (
                    <>
                        {isAdminCenter && attributePermissionsEnabled && (
                            <AdminCenterScopeDropdowns
                                products={products}
                                onSelectionsChanged={onScopeSelectionsChanged}
                                scopes={roleScopes}
                            />
                        )}
                        <RoleAttributeSelections
                            productId={selectedProduct?.id}
                            instanceId={selectedInstances?.length ? selectedInstances[0].id : undefined}
                            onChange={handleAttributeChange}
                            roles={roles || []}
                            hideDuplicateRole={editing || (isAdminCenter && attributePermissionsEnabled)}
                            selectedAttributes={selectedAttributes}
                            rolePermissionValue={expRolePermissionValue}
                        />
                    </>
                )}
            </div>
            <LimitByRole action={ATTRIBUTE_ROLES.ROLES.UPDATE} mode="hide">
                <SidebarFooter onCancel={onClose}>
                    {role && !editing ? (
                        <Button
                            key="delete-role"
                            isDisabled={role?.roleType === SYSTEM_ROLE_TYPE || !!role?.isSystemGeneratedRole}
                            onClick={() => setEditing(true)}
                            // eslint-disable-next-line react/style-prop-object
                            style="highlight"
                            title={editButtonHoverTipMessage}
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button
                            key="save-role-button"
                            isDisabled={disableSubmit()}
                            // eslint-disable-next-line react/style-prop-object
                            style="highlight"
                            isSubmit
                            loadingText="Saving"
                            isLoading={loading}
                        >
                            {editing ? "Save" : "Save"}
                        </Button>
                    )}
                </SidebarFooter>
            </LimitByRole>
        </form>
    );
};

RoleForm.displayName = "RoleForm";
