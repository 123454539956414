/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Attention,
    Button,
    ButtonIcon,
    Checkbox,
    Disclose,
    LayoutGrid,
    LayoutGridCell,
    LayoutGridContainer,
    ProgressDots,
    Table,
    Typography
} from "@optimizely/axiom";
import { useForm, useFormState } from "react-hook-form";

import { User } from "../../../domain/User";
import { ATTRIBUTE_ROLES, EVERYONE_GROUP_NAME, GROUP_TYPES } from "../../../constants";
import { adaptApiErrors, IApiError } from "../../../services/ErrorMessageAdapter";
import { useFormContext } from "./UserFormContext/UserFormContext";
import userGroupStyles from "./UserForm.module.scss";
import { useGroupsByUser } from "../../../hooks/useGroupsByUser/useGroupsByUser";
import { InfiniteScrollList } from "../InfiniteScrollList/InfiniteScrollList";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useUserContext } from "../../../providers/UserProvider";
import { listIsDirty } from "../../../lib/utils";
import { Flags } from "../../../feature-flags/flags";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { useOrgUserGroups } from "../../../hooks/useOrgUserGroups/useOrgUserGroups";
import classnames from "classnames";
import { datadogRum } from "@datadog/browser-rum";
import { UserGroup } from "../../../domain/UserGroup";
import { FilterDropdown } from "../FilterDropdown/FilterDropdown";
import { InstanceRolesTable } from "../InstanceRolesTable/InstanceRolesTable";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { ProductInstance } from "../../../domain/ProductInstance";
import LimitByRole from "../LimitByRole/LimitByRole";
import { emitToast } from "../../../lib/toaster-utils";

interface IUserFormValues {
    firstName: string;
    lastName: string;
    email: string;
    apiError: { message: string };
}

interface IUserCreateProps {
    onCancel: () => void;
    onSubmit: ({
        isAdminToUserOrg,
        previousUser,
        updatedUser
    }: {
        isAdminToUserOrg: boolean;
        previousUser: User | null;
        updatedUser: User | null;
    }) => Promise<boolean>;
    selectedUser?: User;
}

type UserGroupWithDetailedInstances = UserGroup & { instances: ProductInstance[] };

export const UserForm = ({ onCancel, onSubmit }: IUserCreateProps) => {
    const { organizationId, profile, isAdminToUserOrg } = useUserContext();
    const { userGroups: orgUserGroups } = useOrgUserGroups({
        organizationId
    });
    const { getUserGroupInstances } = useProducts({ organizationId });
    const { userState, updateUserState } = useFormContext();
    const { editing: openInEditMode, user: selectedUser, userGroups: selectedUserGroups } = userState;
    const { enabled: showLoginStatus } = useFeatureFlag(Flags.SHOW_LOGIN_STATUS);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        control,
        formState: { errors }
    } = useForm<IUserFormValues>({
        mode: "onChange",
        defaultValues: {
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            email: selectedUser?.email
        }
    });
    const { isSubmitting, isDirty } = useFormState({
        control
    });

    const [editing, setEditing] = useState(openInEditMode);

    const activatedStatusValue = selectedUser?.properties?.find((p) => p.name?.toLowerCase() === "activateuser")?.value;

    const activatedStatusBoolean = activatedStatusValue?.toLowerCase() === "true";
    const activatedStatus = activatedStatusValue ? activatedStatusBoolean : undefined;

    const [activateUser, setActivateUser] = useState<boolean | undefined>(selectedUser ? activatedStatus : true);

    const [saving, setSaving] = useState(false);
    const [initialGroupList, setInitialGroupList] = useState<string[]>([]);
    const currentUserId = useRef<string | undefined>("");
    const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>();
    const { groups, loadNext, isLoadingMore, isLoadingInitialData, revalidate } = useGroupsByUser({
        organizationId: organizationId || null,
        email: selectedUser?.email
    });
    const [detailedGroups, setDetailedGroups] = useState<UserGroupWithDetailedInstances[]>([]);

    const everyoneGroup = orgUserGroups?.find((ug) => ug.name === EVERYONE_GROUP_NAME);

    const updateDetailedGroups = async (detailed: any) => {
        setDetailedGroups(await Promise.all(detailed));
    };

    useEffect(() => {
        if (groups && groups.length !== selectedUserGroups.length) {
            const lastUserGroup = selectedUserGroups[selectedUserGroups.length - 1];
            const indexLastUserGroup = groups?.indexOf(lastUserGroup);
            if (indexLastUserGroup >= 0) {
                const updatedList = [...selectedUserGroups, ...groups.slice(groups?.indexOf(lastUserGroup) + 1)];

                updateUserState({
                    userGroups: updatedList
                });
            } else {
                updateUserState({ userGroups: groups });
            }
        }

        if (groups && initialGroupList.length !== groups.length) {
            setInitialGroupList(groups.map((g) => g.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, updateUserState]);

    useEffect(() => {
        selectedUser?.firstName && setValue("firstName", selectedUser.firstName);
        selectedUser?.lastName && setValue("lastName", selectedUser.lastName);
        selectedUser?.email && setValue("email", selectedUser.email);

        if (selectedUser?.id !== currentUserId.current) {
            setEditing(false);
            setActivateUser(activatedStatus);
            clearErrors();
        }
        currentUserId.current = selectedUser?.id;
        // when removing flag, the remaining dependencies are [selectedUser, setValue, activatedStatus]
    }, [clearErrors, selectedUser, updateUserState, everyoneGroup, setValue, activatedStatus]);

    useEffect(() => {
        setEditing(openInEditMode);
    }, [openInEditMode]);

    useEffect(() => {
        const detailedGroups = selectedUserGroups?.map((group) => {
            return getUserGroupInstances({ userGroupId: group.id })
                .then((response) => {
                    return {
                        ...group,
                        instances: response?.items || []
                    };
                })
                .catch((error) => {
                    console.error("failed getting group instances:", error);
                    return [];
                });
        });
        updateDetailedGroups(detailedGroups);
    }, [getUserGroupInstances, selectedUserGroups]);

    const addUserGroup = useCallback(
        ({ userGroupId }: { userGroupId: string }) => {
            const addedGroup = orgUserGroups?.find((ug) => ug.id === userGroupId);
            updateUserState({
                userGroups: [addedGroup, ...selectedUserGroups]
            });
            setSelectedUserGroup(undefined);
        },
        [orgUserGroups, selectedUserGroups, updateUserState]
    );

    useEffect(() => {
        if (selectedUserGroup) {
            addUserGroup({
                userGroupId: selectedUserGroup.id
            });
        }
    }, [selectedUserGroup, addUserGroup]);

    const removeUserGroup = ({ index }: { index: number }) => {
        const updatedGroups = [...selectedUserGroups];
        updatedGroups.splice(index, 1);
        updateUserState({ userGroups: updatedGroups });
    };

    const handleUpdate = () => {
        if (!isUserDirty) {
            return null;
        }
        const { firstName, lastName, email } = getValues();
        const originalUser = selectedUser;

        let updatedUserGroups = selectedUser ? [...selectedUser.userGroupIds] : [];

        initialGroupList.forEach((id) => {
            if (!selectedUserGroups.map((g) => g.id).includes(id)) {
                updatedUserGroups = updatedUserGroups.filter((ugid) => ugid !== id);
            }
        });

        selectedUserGroups
            .map((g) => g.id)
            .forEach((id) => {
                if (!initialGroupList.includes(id)) {
                    updatedUserGroups.push(id);
                }
            });

        if (originalUser) {
            const updatedUser = new User({
                id: originalUser?.id || "",
                firstName,
                lastName,
                email,
                externalStatus: originalUser.externalStatus,
                userGroupIds: updatedUserGroups,
                homeOrganizationId: originalUser?.homeOrganizationId || "",
                created: originalUser?.created,
                lastLoggedIn: originalUser?.lastLoggedIn
            });
            if (activateUser !== undefined) {
                updatedUser.properties = [{ name: "ActivateUser", value: activateUser.toString() }];
            }
            return new User(updatedUser);
        } else {
            return null;
        }
    };

    const handleError = (apiErrors: IApiError[] | Error) => {
        datadogRum.addError(apiErrors);
        if (Array.isArray(apiErrors)) {
            const errors = adaptApiErrors(apiErrors);
            errors.forEach((error) => {
                setError(error.name as any, {
                    type: "individualFieldApiError",
                    message: error.message
                });
            });
        } else {
            setError("apiError", {
                type: "apiError",
                message: "An unexpected error occurred. Please try your request again."
            });
        }
    };

    const handleFormSubmission = async () => {
        if (editing && selectedUser) {
            const updatedUser = handleUpdate();
            if (updatedUser) {
                setSaving(true);
                onSubmit({
                    isAdminToUserOrg,
                    previousUser: new User({ ...selectedUser, userGroupIds: initialGroupList }),
                    updatedUser
                })
                    .then(async () => {
                        emitToast({ message: "User successfully updated." });
                        await revalidate();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSaving(false);
                    });
            }
        } else {
            setEditing(true);
        }
    };

    let actionText = selectedUser ? "Edit" : "Save";

    if (editing) {
        actionText = "Save";
    }

    if (errors.apiError && isSubmitting) {
        clearErrors("apiError");
    }

    const showInputs = editing || !selectedUser;

    const isUserDirty =
        isDirty ||
        activatedStatus !== activateUser ||
        listIsDirty(
            initialGroupList,
            userState.userGroups.map((u) => u.id)
        );

    const userGroupDropdownItems = orgUserGroups?.filter(
        (ug) =>
            !selectedUserGroups?.find((selectedGroup) => selectedGroup.id === ug.id) &&
            ug.groupType !== GROUP_TYPES.INTERNAL
    );

    const fieldLabelClasses = classnames("oui-label", "push-double--top");

    const isInSelectedUserHomeOrg = organizationId === selectedUser?.homeOrganizationId;

    const buttonHoverTipMessage = showInputs
        ? isUserDirty
            ? "Save"
            : "No changes made to user"
        : isAdminToUserOrg && isInSelectedUserHomeOrg
        ? "Edit"
        : "You cannot edit this user's details. Please use the Groups tab to modify their permissions.";

    return (
        <>
            {errors.apiError && (
                <Attention alignment="left" className="push--top push--bottom push-quad--sides" type="bad-news">
                    {errors.apiError.message}
                </Attention>
            )}
            <form className="flex flex--column user-form" onSubmit={handleSubmit(handleFormSubmission)}>
                <div className="soft-quad--sides">
                    <div className={userGroupStyles["user-form__content"]}>
                        {showInputs && !!selectedUser && !isAdminToUserOrg && (
                            <Attention alignment="left" className="push--bottom" type="warning">
                                You are not an admin of this user's organization. You may only update their user groups
                                in this organization.
                            </Attention>
                        )}
                        {showInputs ? (
                            <>
                                <label className="oui-label" htmlFor="user-first-name">
                                    First Name
                                    <span aria-label="(required)" className="oui-label--required" />
                                </label>

                                <input
                                    aria-describedby="user-first-name-error"
                                    className={classnames("oui-text-input", {
                                        "oui-form-bad-news": !!errors.firstName
                                    })}
                                    id="user-first-name"
                                    type="text"
                                    {...register("firstName", {
                                        required: {
                                            value: true,
                                            message: "First name is required"
                                        }
                                    })}
                                    disabled={!!selectedUser && !isAdminToUserOrg}
                                />
                                {errors.firstName && (
                                    <span className="oui-form-note form-note--bad-news" id="user-first-name-error">
                                        {errors.firstName.message}
                                    </span>
                                )}
                            </>
                        ) : (
                            <>
                                <label className="oui-label">First Name</label>
                                <Typography type="body" className="label--disabled">
                                    {selectedUser.firstName}
                                </Typography>
                            </>
                        )}

                        {showInputs ? (
                            <>
                                <label className={fieldLabelClasses} htmlFor="user-last-name">
                                    Last Name
                                    <span aria-label="(required)" className="oui-label--required" />
                                </label>

                                <input
                                    aria-describedby="user-last-name-error"
                                    className={classnames("oui-text-input", {
                                        "oui-form-bad-news": !!errors.lastName
                                    })}
                                    id="user-last-name"
                                    type="text"
                                    {...register("lastName", {
                                        required: { value: true, message: "Last name is required" }
                                    })}
                                    disabled={!!selectedUser && !isAdminToUserOrg}
                                />
                                {errors.lastName && (
                                    <span className="oui-form-note form-note--bad-news" id="user-first-name-error">
                                        {errors.lastName.message}
                                    </span>
                                )}
                            </>
                        ) : (
                            <>
                                <label className={fieldLabelClasses}>Last Name</label>
                                <Typography type="body" className="label--disabled">
                                    {selectedUser?.lastName}
                                </Typography>
                            </>
                        )}

                        {showInputs ? (
                            <>
                                <label className={fieldLabelClasses} htmlFor="user-email">
                                    Email
                                    <span aria-label="(required)" className="oui-label--required" />
                                </label>

                                <input
                                    aria-describedby="user-email-error"
                                    className={classnames("oui-text-input", {
                                        "oui-form-bad-news": !!errors.email
                                    })}
                                    id="user-email"
                                    type="email"
                                    {...register("email", {
                                        required: { value: true, message: "Email is required" }
                                    })}
                                    disabled={!!selectedUser}
                                />
                                <div className={`${errors.email && "oui-form-bad-news"}`}>
                                    {errors.email && (
                                        <span className="oui-form-note" id="user-email-error">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <label className={fieldLabelClasses}>Email</label>
                                <Typography type="body" className="label--disabled">
                                    {selectedUser.email}
                                </Typography>
                            </>
                        )}

                        {showInputs && showLoginStatus && (
                            <div className="push-quad--bottom">
                                <Checkbox
                                    checked={activateUser}
                                    label="Enable user"
                                    description="Only enabled users can log into their account"
                                    onChange={() => setActivateUser(!activateUser)}
                                    isDisabled={
                                        selectedUser?.id === profile?.id || (!!selectedUser && !isAdminToUserOrg)
                                    }
                                />
                            </div>
                        )}

                        <LayoutGridContainer className={`${userGroupStyles["user-form-groups"]}`}>
                            {showInputs ? (
                                <LayoutGrid className="push-quad--ends">
                                    <LayoutGridCell
                                        large={12}
                                        medium={8}
                                        small={4}
                                        xlarge={12}
                                        className="push--bottom"
                                    >
                                        <Typography type="header4">Add Groups</Typography>
                                    </LayoutGridCell>

                                    <LayoutGridCell large={10} medium={8} small={4} xlarge={12}>
                                        <FilterDropdown
                                            filterPlaceholder="Search User Groups"
                                            dropdownPlaceholder="Select a group..."
                                            onItemSelected={({ item }) => {
                                                setSelectedUserGroup(
                                                    (userGroupDropdownItems || []).find((i) => i.id === item.key)
                                                );
                                            }}
                                            items={
                                                userGroupDropdownItems
                                                    ? userGroupDropdownItems.map((g) => {
                                                          return {
                                                              key: g.id,
                                                              label: g.name,
                                                              description: g.description
                                                          };
                                                      })
                                                    : []
                                            }
                                        />
                                    </LayoutGridCell>

                                    <LayoutGridCell large={12} medium={8} small={4}>
                                        <Table
                                            className="push--top"
                                            // eslint-disable-next-line react/style-prop-object
                                            style="rule"
                                            tableLayoutAlgorithm="auto"
                                        >
                                            <Table.THead>
                                                <Table.TR>
                                                    <Table.TH> Name </Table.TH>
                                                    <Table.TH> </Table.TH>
                                                </Table.TR>
                                            </Table.THead>
                                            <Table.TBody>
                                                <InfiniteScrollList
                                                    isLoadingMore={isLoadingMore}
                                                    loadNext={loadNext}
                                                    loadingItem={
                                                        <Table.TR>
                                                            <Table.TD colSpan={3} textAlign="center">
                                                                <ProgressDots />
                                                            </Table.TD>
                                                        </Table.TR>
                                                    }
                                                >
                                                    {selectedUserGroups?.map((userGroup, userGroupIndex) => (
                                                        <Table.TR key={userGroup.id}>
                                                            <Table.TD> {userGroup.name} </Table.TD>
                                                            <Table.TD textAlign="right">
                                                                {/* eslint-disable react/style-prop-object */}
                                                                {showInputs &&
                                                                    userGroup.name !== EVERYONE_GROUP_NAME && (
                                                                        <ButtonIcon
                                                                            iconName="trash-can"
                                                                            size="small"
                                                                            style="plain"
                                                                            onClick={() =>
                                                                                removeUserGroup({
                                                                                    index: userGroupIndex
                                                                                })
                                                                            }
                                                                        />
                                                                    )}
                                                            </Table.TD>
                                                        </Table.TR>
                                                    ))}
                                                </InfiniteScrollList>
                                            </Table.TBody>
                                        </Table>
                                    </LayoutGridCell>
                                </LayoutGrid>
                            ) : (
                                <>
                                    <span className="oui-label push--top">Groups</span>
                                    {isLoadingInitialData ? (
                                        <ProgressDots />
                                    ) : (
                                        <InfiniteScrollList
                                            isLoadingMore={isLoadingMore}
                                            loadNext={loadNext}
                                            loadingItem={<ProgressDots />}
                                        >
                                            {detailedGroups.map((group) => {
                                                return (
                                                    <div key={group.id}>
                                                        <Disclose title={group.name}>
                                                            <div className="push-triple--left">
                                                                <InstanceRolesTable
                                                                    userGroup={group}
                                                                    instances={group.instances}
                                                                />
                                                            </div>
                                                        </Disclose>
                                                    </div>
                                                );
                                            })}
                                        </InfiniteScrollList>
                                    )}
                                </>
                            )}
                        </LayoutGridContainer>
                    </div>
                </div>
                <LimitByRole action={ATTRIBUTE_ROLES.USERS.UPDATE} mode="hide">
                    <SidebarFooter onCancel={onCancel}>
                        <Button
                            key="save-user-button"
                            isLoading={saving}
                            isDisabled={
                                (!!selectedUser && !isInSelectedUserHomeOrg) ||
                                !isAdminToUserOrg ||
                                (showInputs && !isUserDirty)
                            }
                            loadingText="Saving"
                            style="highlight"
                            title={buttonHoverTipMessage}
                            isSubmit
                        >
                            {actionText}
                        </Button>
                    </SidebarFooter>
                </LimitByRole>
            </form>
        </>
    );
};

UserForm.displayName = "UserForm";
